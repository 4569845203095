import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Controller } from 'react-hook-form';
import clsx from 'clsx';
import { Button } from '@/components/ui/Button';
import { TextInput } from '@/components/ui/TextInput';
import { BaseImage } from '@/components/ui/BaseImage';
import { Typography } from '@/components/ui/Typography';
import { Chip } from '@/components/ui/Chip';
import { Collapse } from '@/components/ui/Collapse';
import { ParallaxStars } from '@/components/ui/ParallaxStars';
import { Colors } from '@/constants/Colors';
import magicWandBlueSrc from 'public/assets/images/shared/magic-wand-blue.svg';
import magicWandWhiteSrc from 'public/assets/images/shared/magic-wand-white.svg';
import { useAnimatedPlaceholder } from '../../hooks/useAnimatedPlaceholder';
import { useAIForm } from '../../hooks/useAIForm';
import {
  AI_GENERATE_COLLECTION_PLACEHOLDERS,
  AI_GENERATE_COLLECTION_TAG_COLORS,
  AI_GENERATE_COLLECTION_TAGS,
} from '../../constants';

export const AI_FORM_TEST_ID = 'ai-form';
export const AI_FORM_PROMPT_TEST_ID = 'ai-form-prompt';
export const AI_FORM_PROMPT_INPUT_TEST_ID = 'ai-form-prompt-input';
export const AI_FORM_SUBMIT_BUTTON_TEST_ID = 'ai-form-submit-button';

export type AIGenerateCollectionFormValues = {
  prompt: string;
  tags: string[];
};

export interface AIFormProps {
  value?: AIGenerateCollectionFormValues;
  isLoading?: boolean;
  isExpanded?: boolean;
  onSubmit(values: AIGenerateCollectionFormValues): void;
  onFocus?(): void;
}

export type AIGenerateCollectionFormRef = {
  focusInput(): void;
};

export const AIForm = forwardRef<AIGenerateCollectionFormRef, AIFormProps>(
  ({ value, isExpanded, isLoading, onFocus, onSubmit }, ref) => {
    const { getValues, control, clearErrors, handleSubmit } = useAIForm({
      defaultValues: value,
    });

    const inputRef = useRef<HTMLInputElement>(null);

    const onFieldFocus = () => {
      clearErrors();
      onFocus?.();
    };

    const onSubmitClick = () => {
      if (!getValues().prompt) inputRef.current?.focus();
      onFocus?.();
    };

    const toggleTag = (tag: string, onChange: (tags: string[]) => void) => {
      const tags = getValues().tags ?? [];
      const isSelected = tags.includes(tag);
      const newTags = isSelected ? tags.filter((t) => t !== tag) : [...tags, tag];

      onChange(newTags);
    };

    const getTagColor = (tag: string, i: number, tags: string[] | undefined) =>
      tags?.includes(tag)
        ? AI_GENERATE_COLLECTION_TAG_COLORS[i % AI_GENERATE_COLLECTION_TAG_COLORS.length]
        : Colors.white;

    useAnimatedPlaceholder(inputRef.current, AI_GENERATE_COLLECTION_PLACEHOLDERS);

    useImperativeHandle(ref, () => ({
      focusInput() {
        inputRef.current?.focus();
        inputRef.current?.select();
      },
    }));

    return (
      <form
        className={clsx('ai-form', { 'is-expanded': isExpanded })}
        onSubmit={handleSubmit((data) => onSubmit(data))}
        data-testid={AI_FORM_TEST_ID}
      >
        <div className="ai-form__top-container">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="ai-form__prompt-section" data-testid={AI_FORM_PROMPT_TEST_ID}>
            <div className="ai-form__left-icon-container">
              <BaseImage
                src={magicWandBlueSrc}
                layout="fixed"
                width={24}
                height={24}
                alt="Magic wand"
              />
            </div>

            <Controller
              control={control}
              name="prompt"
              render={({ field: { value: promptValue, onChange }, fieldState: { error } }) => (
                <TextInput
                  ref={inputRef}
                  value={promptValue}
                  maxLength={100}
                  size="md"
                  testId={AI_FORM_PROMPT_INPUT_TEST_ID}
                  className="w-full"
                  inputClassName="ai-form__prompt-input"
                  isError={!!error}
                  onChange={onChange}
                  onFocus={onFieldFocus}
                />
              )}
            />
          </label>

          <div className="ai-form__submit-section">
            <Button
              type="submit"
              size="compact"
              variant="primary"
              className="ai-form__submit-button"
              isLoading={isLoading}
              testId={AI_FORM_SUBMIT_BUTTON_TEST_ID}
              onFocus={onFocus}
              onClick={onSubmitClick}
            >
              <ParallaxStars
                className="z-1"
                sizes={[1, 2]}
                glowBlur={5}
                colors={[Colors.gold[400], Colors.purple[400]]}
                speed={0.3}
                intensity={0.1}
              />
              <span className="max-md:hidden relative z-1">Generate a collection</span>
              <span className="md:hidden relative z-1">
                <BaseImage
                  src={magicWandWhiteSrc}
                  layout="fixed"
                  width={24}
                  height={24}
                  alt="Magic wand"
                />
              </span>
            </Button>
          </div>
        </div>

        <Collapse in={isExpanded} unmountOnExit delay={0}>
          <div className="ai-form__tags-inner">
            <div className="px-4 md:px-8">
              <hr className="border-primary-300" />

              <Typography
                tag="p"
                size={16}
                weight={500}
                fontFamily="dm-sans"
                className="py-4"
                color={Colors.primary[600]}
              >
                <span className="max-md:hidden">
                  Want to save time and effort? Try our suggested shortcuts for an easier
                  experience!
                </span>
                <span className="md:hidden">Try our suggested shortcuts</span>
              </Typography>
            </div>

            <Controller
              control={control}
              name="tags"
              render={({ field: { value: tagsValue, onChange } }) => (
                <ul className="ai-form__tags-list">
                  {AI_GENERATE_COLLECTION_TAGS.map((tag, i) => (
                    <li key={tag} className="ai-form__tags-list-item">
                      <Chip
                        bgColor={getTagColor(tag, i, tagsValue)}
                        isSelected={tagsValue?.includes(tag)}
                        onClick={() => toggleTag(tag, onChange)}
                      >
                        {tag}
                      </Chip>
                    </li>
                  ))}
                </ul>
              )}
            />
          </div>
        </Collapse>

        {/* Styles */}

        <style jsx>{`
          .ai-form {
            &.is-expanded {
              .ai-form__top-container {
                @apply md:px-8;
              }
            }

            &__top-container {
              @apply flex items-center px-6 max-md:pl-0 max-md:pr-4;
              @apply transition-all duration-300;
            }

            &__prompt-section {
              @apply flex-auto flex items-center self-stretch;

              :global(.ai-form__prompt-input) {
                @apply w-full border-none bg-transparent outline-none p-4 h-20;
                @apply font-dm-sans font-medium text-base text-primary-900;
                @apply placeholder:text-primary-600 placeholder:font-medium placeholder:text-base;

                /* Keep the placeholder text visible gray for the error state */
                &.error {
                  @apply placeholder:text-[rgba(0,0,0,0.4)];
                }
              }
            }

            &__left-icon-container {
              @apply max-md:hidden flex-none;
            }

            &__submit-section {
              @apply flex-none;

              :global(.ai-form__submit-button) {
                @apply relative h-[50px] min-w-[50px] p-2 md:px-6 overflow-hidden;
                @apply font-dm-sans font-bold text-base;
                @apply bg-indigo-700;

                &:after {
                  @apply content-[''] absolute inset-0 rounded-full;
                  @apply transition-all duration-150;
                  background: linear-gradient(96deg, #3077c9 9.43%, rgba(54, 212, 255, 0.6) 92.68%),
                    #3077c9;
                }

                &:hover,
                &:focus-visible {
                  &:after {
                    @apply opacity-0;
                  }
                }
              }
            }

            &__tags-inner {
              @apply flex flex-col overflow-hidden max-w-full;
            }

            &__tags-list {
              @apply flex flex-nowrap gap-2 px-4 md:px-8 pb-4 md:pb-7 overflow-x-auto max-w-full;
              @apply snap-x;
            }

            &__tags-list-item {
              @apply snap-center;
            }
          }
        `}</style>
      </form>
    );
  }
);
