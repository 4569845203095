import { useRouter } from 'next/router';
import { useEffect } from 'react';
import omit from 'lodash/omit';
import { ToastTypeOptions } from '@/components/ui/Toast/types';
import { ToastTypes } from '@/components/ui/Toast/constants';
import {
  showErrorMessageToast,
  showInfoMessageToast,
  showSuccessMessageToast,
  showWarningMessageToast,
} from '@/components/ui/Toast';

export const useToastFromQueryParams = () => {
  const router = useRouter();

  useEffect(() => {
    if (!router.isReady) return;

    // TOAST MESSAGE HANDLING FROM SSR REDIRECT
    // Show message toast if there is a redirect from SSR with message query param
    const message = router.query.message as string;
    const toastType = router.query.toastType as ToastTypeOptions;

    if (message) {
      const toastFnByToastType = {
        [ToastTypes.INFO]: () => showInfoMessageToast({ description: message }),
        [ToastTypes.WARNING]: () => showWarningMessageToast({ description: message }),
        [ToastTypes.ERROR]: () => showErrorMessageToast({ description: message }),
        [ToastTypes.SUCCESS]: () => showSuccessMessageToast({ description: message }),
      };
      const showToastFn = toastFnByToastType[toastType] ?? toastFnByToastType[ToastTypes.INFO];
      showToastFn();

      router.replace({ query: omit(router.query, 'message', 'toastType') }, undefined, {
        shallow: true,
      });
    }
  }, [router.isReady, router.query]);
};
