/* eslint react/jsx-no-useless-fragment: 0 */
import React, { useEffect } from 'react';
import posthog from 'posthog-js';
import fromPairs from 'lodash/fromPairs';
import * as Sentry from '@sentry/nextjs';
import { selectUser, setIsMarketingCTAsShown, setUserFlags, setUserFlagsLoading } from '@/redux';
import { useAppDispatch, useAppSelector } from '@/hooks/useStore';
import { UserFlags } from '@/types/user';

interface UserFlagsProviderProps {
  children: React.ReactNode;
}

export const UserFlagsProvider: React.FC<UserFlagsProviderProps> = ({ children }) => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser);

  // PostHog -> Redux

  useEffect(() => {
    try {
      if (user) {
        const { id, ...userProperties } = user;
        posthog.identify(id, { ...userProperties });
      }
      posthog.onFeatureFlags((flagKeys, variants) => {
        const getFlagPayload = (flag: string) =>
          posthog.isFeatureEnabled(flag)
            ? posthog.getFeatureFlagPayload(flag) ?? variants[flag] ?? true
            : false;

        const flags = fromPairs(
          flagKeys.map((key) => [key, getFlagPayload(key)])
        ) as unknown as UserFlags;

        dispatch(setUserFlags({ flags, loading: false }));

        if (flags.hideCTAsGuestMode) {
          dispatch(setIsMarketingCTAsShown(false));
        }
      });
    } catch (error) {
      Sentry.captureException(error);
      dispatch(setUserFlagsLoading(false));
    }
  }, [user]);

  return <>{children}</>;
};
