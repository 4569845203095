/* eslint no-use-before-define: 0 */
import cloneDeep from 'lodash/cloneDeep';
import keys from 'lodash/keys';
import sortBy from 'lodash/sortBy';
import values from 'lodash/values';
import entries from 'lodash/entries';
import { getCollectionShopifyId, transformFieldName } from '@/utils/shopifyHelpers';
import { parseJSON } from '@/utils/formatters';
import { Product, ProductResponse } from '@/types/entities/product';
import { Persona, SinglePersona } from '@/types/entities/persona';
import { Collection } from '@/types/entities/collection';
import { DEFAULT_PRODUCTS_BY_TAGS } from '@/redux/queries/products';
import { deterministicShuffle } from '@/utils/misc';

export const SORTING_PERSONAS_ARRAY = [
  'Him',
  'Her',
  'Dad',
  'Mom',
  'Friends',
  'Teens',
  'Kids',
  'Babies',
  'Teachers',
];
export interface ProductsResponseWithCollectionId {
  id: string;
  products: Shopify.WrappedQueryEntity<
    Shopify.Product & {
      images: Shopify.WrappedQueryEntity<Shopify.Image>;
    }
  >;
}

const getBooleanFromString = (booleanString: string | undefined): boolean =>
  String(booleanString).toLowerCase() === 'true';

export const mapCollectionsFromResponse = (
  collections: Shopify.CollectionsWithProductsResponse['collections']
): Collection[] =>
  collections.edges.map((collection) => {
    const { alcoholic, rank, gridImage, ...node } = collection.node;
    return {
      ...node,
      id: getCollectionShopifyId(collection.node.id),
      // eslint-disable-next-line no-unsafe-optional-chaining
      rank: rank ? parseInt(rank.value, 10) : null,
      alcoholic: getBooleanFromString(alcoholic?.value),
      gridImage: gridImage?.reference?.image?.originalSrc,
    };
  });

/**
 * @description Extracts all personas from all products of collections and
 * returns them as an array of Persona
 */
export const mapPersonasFromResponse = (
  collections: Shopify.CollectionsWithProductsResponse['collections']
): Persona[] => {
  const personas = extractPersonas(collections);
  return collectCollectionAndProductsIntoPersonas(personas);
};

/**
 * @description Extracts all personas from all products of collections
 */
export const extractPersonas = (
  collections: Shopify.CollectionsWithProductsResponse['collections']
): SinglePersona[] => {
  const shopifyCollections = collections.edges.map((collection) => ({
    id: collection.node.id,
    personas: parseTags(collection.node.personas),
  }));

  return shopifyCollections.reduce<SinglePersona[]>((acc, collection) => {
    collection.personas.forEach((tag) => {
      if (!tag) return;
      acc.push({
        persona: tag,
        collectionId: collection.id,
      });
    });
    return acc;
  }, []);
};

/**
 * @description Make unique persona and collect collectionIds and
 * productIds where personas were extracted
 */
function collectCollectionAndProductsIntoPersonas(personas: SinglePersona[]): Persona[] {
  const uniquePersonas = personas.reduce<Record<string, Persona>>((acc, tag) => {
    const personaObj: Persona = acc[tag.persona];

    if (!personaObj) {
      acc[tag.persona] = {
        persona: tag.persona,
        collectionIds: [tag.collectionId],
      };
    } else {
      personaObj.collectionIds = [...new Set([...personaObj.collectionIds, tag.collectionId])];
    }
    return acc;
  }, {});

  return values(uniquePersonas);
}

function parseTags(tagsMetafield: Shopify.MetaField): string[] {
  const tagsString = tagsMetafield?.value;

  return parseJSON<string[]>(tagsString) || [];
}

export const filterOutCollectionsByPersonaIds = (
  collections: Collection[],
  personasIds: string[]
): Collection[] =>
  collections.filter((collection) => personasIds.some((id) => id === collection.id));

export function sortCollectionsByRank(collections: Collection[]): Collection[] {
  return sortBy(collections, 'rank');
}

export const mapProductsThumbnailsWithMetaFromResponse = (
  response: ProductsResponseWithCollectionId[],
  budget: number
): ProductResponse[] =>
  response.map((col) => {
    const products = col.products.edges.map((prod) => ({
      ...prod.node,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      images: prod.node.images.edges.map((image) => image.node.src!),
    }));

    return {
      data: products,
      collectionId: col.id,
      budget,
    };
  });

export const mapProductsWithMetaFromResponse = (response: Shopify.ProductsResponse): Product[] =>
  response.collection?.products?.edges.map((prod) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { variants, ...product } = prod.node;
    return {
      ...product,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      images: prod.node.images.edges.map((image) => image.node.src!).filter(Boolean),
      requiresShipping: prod.node.variants?.edges?.[0].node.requiresShipping ?? true,
    };
  }) ?? [];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapProductsByIdsWithMetaFromResponse = (
  response: Shopify.ProductsByIdsResponse
): Product[] => {
  const products = response.nodes.filter(Boolean); // clean null responses
  return products.map((product) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const images = product.images.edges.map((image) => image.node.url!);
    return {
      ...product,
      images,
      image: images[0],
    };
  });
};

export const mapProductsWithMetaByTagsFromResponse = (
  response: Shopify.ProductsByTagsResponse
): Product[] => {
  // Map products from GQL response nodes
  const mappedProductsByTags = entries(response).reduce<Record<string, Product[]>>(
    (acc, [tag, productSet]) => {
      const products = productSet.edges.map((prod) => ({
        ...prod.node,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        images: prod.node.images.edges.map((image) => image.node.src!),
      }));
      return { ...acc, [transformFieldName(tag).decode()]: products };
    },
    {}
  );

  const uniqueProductsByTags = getUniqueProductsByTags(mappedProductsByTags);
  const balancedProducts = balanceProductsAmongTags(uniqueProductsByTags, DEFAULT_PRODUCTS_BY_TAGS);
  const flatProducts = values(balancedProducts).flat();

  return deterministicShuffle(flatProducts, flatProducts.length ** 2); // "** 2" for more randomness
};

export const mapProductVariantsFromResponse = (
  response: Shopify.ProductVariantsResponse
): Shopify.ProductVariant[] => response.product.variants.edges.map((variant) => variant.node);

export const mapCollectionWithLandingPageFromResponse = (
  response: Shopify.CollectionByHandleResponse
) => {
  if (!response.collection) return null;
  return {
    ...response.collection,
    alcoholic: getBooleanFromString(response.collection.alcoholic?.value),
    heroImage: response.collection.heroImage?.reference.image.originalSrc ?? null,
    landingPage: getBooleanFromString(response.collection.landingPage?.value),
    seo: getCollectionSeoWithFallback(response.collection, response.collection.seo),
  };
};

export const mapCategorizedCollectionsFromResponse = (
  collections: Shopify.CategorizedCollectionsResponse['collections']
) =>
  collections.edges.map((collection) => {
    const { landingPage, category, ...node } = collection.node;

    return {
      ...node,
      id: getCollectionShopifyId(collection.node.id),
      metafields: {
        landingPage: getBooleanFromString(landingPage?.value),
        category: category?.value ?? null,
      },
    };
  });

export const mapGenericCheckoutResponse = (response: {
  checkout: Shopify.Checkout | null;
  checkoutUserErrors: Shopify.CheckoutUserError[];
}): Shopify.CheckoutCreateUpdateResponse => {
  const { checkout, checkoutUserErrors } = response;
  if (!checkout) throw new Error('Checkout is null');

  return {
    checkout: {
      ...checkout,
      discountApplications: getDiscountApplicationsFromCheckoutResponse(checkout),
      lineItems: getLineItemsFromCheckoutResponse(checkout),
    },
    checkoutUserErrors,
  };
};

// Helpers

function getCollectionSeoWithFallback(collection: Shopify.Collection, seo: Shopify.SEO) {
  const { title, description = '' } = collection;

  return {
    title: seo.title || title,
    description: seo.description || description,
  };
}

function getDiscountApplicationsFromCheckoutResponse(checkout: {
  discountApplications: { edges: { node: Shopify.DiscountApplication }[] };
}): Shopify.DiscountApplication[] {
  return checkout.discountApplications?.edges.map(({ node }) => node) || [];
}

function getLineItemsFromCheckoutResponse(checkout: {
  lineItems: { edges: { node: Shopify.LineItem }[] };
}): Shopify.MappedLineItem[] {
  return (
    checkout.lineItems?.edges.map(({ node }) => ({
      quantity: node.quantity,
      variantId: node.variant.id,
    })) || []
  );
}

/**
 * @description Returns unique products by tags
 *
 * @param groupedProducts {Record<string, Product[]>} - all products grouped by tags
 *
 * @returns {Record<string, Product[]>} - unique products grouped by tags
 */
export function getUniqueProductsByTags(
  groupedProducts: Record<string, Product[]>
): Record<string, Product[]> {
  const uniqueProducts: Record<string, Product[]> = {};
  const seenIds: Set<string> = new Set();

  keys(groupedProducts).forEach((tag) => {
    uniqueProducts[tag] = groupedProducts[tag].filter((product) => {
      if (!seenIds.has(product.id)) {
        seenIds.add(product.id);
        return true;
      }
      return false;
    });
  });

  return uniqueProducts;
}

/**
 * @description Balances products among tags
 *
 * @param groupedProducts {Record<string, Product[]>} - products grouped by tags
 * @param targetAmount {number} - target amount of products to return for all tags
 *
 * @returns {Record<string, Product[]>} - balanced products grouped by tags
 */
export function balanceProductsAmongTags(
  groupedProducts: Record<string, Product[]>,
  targetAmount: number
): Record<string, Product[]> {
  const copyGroupedProducts = cloneDeep(groupedProducts);
  const tags = keys(groupedProducts);
  const balancedProducts: Record<string, Product[]> = {};
  let addedProducts = 0;

  tags.forEach((tag) => {
    balancedProducts[tag] = [];
  });

  while (addedProducts < targetAmount) {
    let addedInIteration = 0;

    // eslint-disable-next-line no-loop-func
    tags.forEach((tag) => {
      const productsByTag = copyGroupedProducts[tag];
      if (productsByTag.length > 0) {
        const product = productsByTag.shift();

        if (product && addedProducts < targetAmount) {
          balancedProducts[tag].push(product);
          addedProducts += 1;
          addedInIteration += 1;
        }
      }
    });

    // If no products were added in the current iteration, break the loop
    if (addedInIteration === 0) {
      break;
    }
  }

  return balancedProducts;
}
