import React from 'react';
import { toast, ToastOptions } from 'react-toastify';
import { AnalyticsService } from '@/services/AnalyticsService';
import { ToastMessageContent } from './types';
import { ToastTypes } from './constants';
import { Toast } from './Toast';

export const showToast = (
  content: ToastMessageContent,
  options: ToastOptions = {}
): Promise<void> => {
  const { title, description, actions } = content;
  const {
    toastId: customToastId,
    autoClose = 5000,
    type = ToastTypes.DEFAULT,
    closeOnClick = true,
    hideProgressBar = true,
  } = options;

  const defaultToastId = title + description;
  const toastId = customToastId ?? defaultToastId; // Avoid duplicate toasts with the same content

  return new Promise((resolve, reject) => {
    try {
      toast(
        <Toast
          title={title}
          description={description}
          style={{ flex: '1 1 auto' }}
          hasColorIndicator={!autoClose || hideProgressBar}
          closeOnClick={closeOnClick}
          actions={actions}
          hasCloseButton
          onClose={() => {
            toast.dismiss(toastId);
            resolve();
          }}
          type={type}
        />,
        {
          toastId,
          className: 'Toastify__toast--plain',
          bodyClassName: 'Toastify__toast-body--plain',
          icon: false,
          closeButton: false,
          closeOnClick: false,
          hideProgressBar,
          ...options,
          onClose: (props) => {
            options?.onClose?.(props);
            resolve();
          },
        }
      );
      if (type === ToastTypes.ERROR) {
        AnalyticsService.error({
          heading: title,
          message: description,
        });
      }
    } catch (error) {
      reject(error);
    }
  });
};

// INFO
export const showInfoMessageToast = (
  content: ToastMessageContent,
  options: ToastOptions = {}
): Promise<void> =>
  showToast(content, {
    type: ToastTypes.INFO,
    ...options,
  });

// SUCCESS
export const showSuccessMessageToast = (
  content: ToastMessageContent,
  options: ToastOptions = {}
): Promise<void> =>
  showToast(content, {
    type: ToastTypes.SUCCESS,
    ...options,
  });

// WARNING
export const showWarningMessageToast = (
  content: ToastMessageContent,
  options: ToastOptions = {}
): Promise<void> =>
  showToast(content, {
    type: ToastTypes.WARNING,
    ...options,
  });

// ERROR
export const showErrorMessageToast = (
  content: ToastMessageContent,
  options: ToastOptions = {}
): Promise<void> =>
  showToast(content, {
    type: ToastTypes.ERROR,
    ...options,
  });
