import { useCallback, useEffect, useState } from 'react';
import { useKeypress } from '@/hooks/useKeyPress';
import { lockDocumentBodyScroll } from '@/utils/browser';
import { useAppSelector } from '@/hooks/useStore';
import { useDeviceType } from '@/hooks/useDeviceType';
import { selectAuth } from '@/redux';
import { User } from '@/types/user';

interface UseNavigation {
  toggleBurgerMenu: () => void;
  closeMenus: () => void;
  isAuthLoading: boolean;
  isBurgerMenuOpen: boolean;
  isUserMenuOpen: boolean;
  user?: User | null;
  closeBurgerMenu(): void;
  setIsUserMenuOpen(value: boolean): void;
}

export const useNavigation = (): UseNavigation => {
  const { loading: isAuthLoading, user } = useAppSelector(selectAuth);

  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);

  const { isDesktop } = useDeviceType();

  const toggleBurgerMenu = useCallback(() => {
    setIsBurgerMenuOpen((prevState) => !prevState);
  }, []);

  const closeBurgerMenu = useCallback(() => {
    setIsBurgerMenuOpen(false);
  }, []);

  const closeMenus = () => {
    closeBurgerMenu();
    setIsUserMenuOpen(false);
  };

  // Hooks

  useKeypress('Escape', closeMenus);

  // Effects

  useEffect(closeMenus, []);

  useEffect(() => {
    if (isUserMenuOpen) {
      setIsBurgerMenuOpen(false);
    }
  }, [isUserMenuOpen]);

  useEffect((): void => {
    if (isBurgerMenuOpen) {
      setIsUserMenuOpen(false);
    }
  }, [isBurgerMenuOpen]);

  useEffect(() => {
    const isMenuFromNavOpen = isBurgerMenuOpen || isUserMenuOpen;

    if (isMenuFromNavOpen) window.scroll({ top: 0, behavior: 'smooth' });

    if (isDesktop) return undefined;

    let timeoutId: ReturnType<typeof setTimeout>;
    if (isMenuFromNavOpen) {
      // Wait for the scroll to finish (mobile browsers issue)
      timeoutId = setTimeout(() => lockDocumentBodyScroll(isMenuFromNavOpen), 500);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isDesktop, isBurgerMenuOpen, isUserMenuOpen]);

  return {
    user,
    isAuthLoading,
    isUserMenuOpen,
    isBurgerMenuOpen,
    closeMenus,
    closeBurgerMenu,
    toggleBurgerMenu,
    setIsUserMenuOpen,
  };
};
