import { useEffect, useState } from 'react';

interface Size {
  width?: number;
  height?: number;
}

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const handleReSize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    let rafId: number;
    const requestResize = () => {
      rafId = requestAnimationFrame(handleReSize);
    };

    // Add event listener
    window.addEventListener('resize', requestResize);

    // Call handler right away so state gets updated with initial window size
    handleReSize();

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('resize', requestResize);
      cancelAnimationFrame(rafId);
    };
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
};
