import posthog from 'posthog-js';
import { SSR } from '@/utils/ssr';
import type { User } from '@/types/user';
import type { PageProperties } from '@/hooks/usePageAnalytics';
import type { EventName, PageName, PropertiesByEvent, PropertiesByPage, UserTraits } from './types';

export class AnalyticsService {
  private static pushEventToDataLayer(
    event: string,
    properties: {
      [key: string]: unknown;
    }
  ) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event,
      ...properties,
    });
  }

  static async track<E extends EventName>(
    event: E,
    properties = {} as PropertiesByEvent<E>
  ): Promise<void> {
    if (SSR) return;
    this.pushEventToDataLayer(event, { ...properties });
    posthog.capture(event, properties);
  }

  static async page<E extends PageName>(
    event: E,
    pageProperties: PageProperties,
    properties = {} as PropertiesByPage<E>
  ): Promise<void> {
    if (SSR) return;
    this.pushEventToDataLayer('Loaded a Page', { ...properties, ...pageProperties });
    await posthog.capture('$pageview', { name: event, ...properties });
  }

  static async identify(user: User): Promise<void> {
    const { id, ...userProperties } = user;
    posthog.identify(id, { ...userProperties });
  }

  static async setUserProperties(traits: UserTraits): Promise<void> {
    if (SSR) return;
    const { ...userProperties } = traits;
    posthog.people.set({ ...userProperties });
  }

  static async error(
    properties: Omit<PropertiesByEvent<'consumer_error_viewed'>, 'screen'>
  ): Promise<void> {
    if (SSR) return;
    await this.track('consumer_error_viewed', {
      ...properties,
      screen: window.location.pathname,
    });
  }
}
