import { shopifyApi } from '@/redux/api/shopify';
import { createSelector } from '@reduxjs/toolkit';
import { SORTING_PERSONAS_ARRAY } from '@/redux/api/utils';

export const selectPersonasResultFromAPI = shopifyApi.endpoints.personas.select();

export const selectPersonas = createSelector(
  selectPersonasResultFromAPI,
  (personasResult): string[] =>
    personasResult.data
      ?.map(({ persona }) => persona)
      .sort((a, b) => SORTING_PERSONAS_ARRAY.indexOf(a) - SORTING_PERSONAS_ARRAY.indexOf(b)) ?? []
);
