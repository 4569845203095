import { shopifyApi } from '@/redux/api/shopify';
import { createSelector } from '@reduxjs/toolkit';
import { Product } from '@/types/entities/product';
import {
  selectActiveAge,
  selectActiveAITags,
  selectActiveBudgetRange,
  selectActiveCollectionId,
  selectActiveGender,
  selectActiveProductIds,
} from '@/redux/slices/activeState/activeState';
import type { RootState } from '@/redux/store';
import { BUDGETS } from '@/constants/Budgets';
import { GENERATED_COLLECTION } from '@/constants/Collections';
import { DEFAULT_PRODUCTS_BY_TAGS_QUERY_AMOUNT } from '@/redux/queries/products';
import { Age, Gender } from '@/types/shopify/shopify-tags';
import { getBudgetMin } from '@/utils/collectionHelpers';

export const selectProductsResultFromAPI = (
  collectionId: string,
  priceMin: number,
  priceMax: number,
  state: RootState
) =>
  shopifyApi.endpoints.products.select({
    collectionId,
    price: {
      min: priceMin,
      max: priceMax,
    },
  })(state);

type ProductsByTagsSelectorParams = {
  tags: string[];
  age: Age | null;
  gender: Gender | null;
  priceMin: number;
  priceMax: number;
  total: number;
};

export const selectProductsByTagsResultFromAPI = (
  productsSelectorParams: ProductsByTagsSelectorParams,
  state: RootState
) => {
  const { priceMin, priceMax, ...selectParamsWithoutPrice } = productsSelectorParams;

  const selectParams = {
    ...selectParamsWithoutPrice,
    price: { min: priceMin, max: priceMax },
  };

  return shopifyApi.endpoints.productsByTags.select(selectParams)(state);
};

export const selectProductsByIdsResultFromAPI = (productIds: string[], state: RootState) =>
  shopifyApi.endpoints.productsByIds.select({
    productIds,
  })(state);

export const selectProductsByCollectionHandleResultFromAPI = (
  params: { handle: string; price: { min: number; max: number }; first?: number },
  state: RootState
) => shopifyApi.endpoints?.productsByCollectionHandle.select(params)(state);

export const selectProductsByCollectionHandle = (params: {
  handle: string;
  price: { min: number; max: number };
  first?: number;
}) =>
  createSelector([(state: RootState) => state], (state) =>
    selectProductsByCollectionHandleResultFromAPI(params, state)
  );

export const selectActiveCollectionProducts = createSelector(
  [selectActiveCollectionId, selectActiveBudgetRange, (state: RootState) => state],
  (activeCollectionId, activeBudgetRange, state): Product[] => {
    const { min: priceMin, max: priceMax } = activeBudgetRange;
    const { data, isSuccess } = selectProductsResultFromAPI(
      activeCollectionId,
      priceMin,
      priceMax,
      state
    );
    return isSuccess ? data : [];
  }
);

export const selectProductsByCollectionId = (collectionId: string | undefined, budget: number) =>
  createSelector([(state: RootState) => state], (state): Product[] | null => {
    if (!collectionId) {
      return null;
    }

    const budgetMin = getBudgetMin(budget);
    const aiTags = selectActiveAITags(state);
    const productIds = selectActiveProductIds(state);
    const age = selectActiveAge(state);
    const gender = selectActiveGender(state);

    let APIResult;

    if (collectionId === GENERATED_COLLECTION.id) {
      if (aiTags.length > 0) {
        APIResult = selectProductsByTagsResultFromAPI(
          {
            tags: aiTags,
            age,
            gender,
            priceMin: budgetMin,
            priceMax: budget,
            total: DEFAULT_PRODUCTS_BY_TAGS_QUERY_AMOUNT,
          },
          state
        );
      } else APIResult = selectProductsByIdsResultFromAPI(productIds, state);
    } else {
      APIResult = selectProductsResultFromAPI(collectionId, budgetMin, budget, state);
    }
    return APIResult.data ?? null;
  });

export const selectAllCollectionProducts = (collectionId: string) =>
  createSelector(
    [(state: RootState) => state],
    (state): Record<number, Product[] | undefined> =>
      BUDGETS.reduce(
        (acc, budget) => {
          const budgetMin = getBudgetMin(budget);

          const { data, isSuccess, isError } = selectProductsResultFromAPI(
            collectionId,
            budgetMin,
            budget,
            state
          );
          const errorResult = isError ? [] : undefined;
          acc[budget] = isSuccess ? data : errorResult;
          return acc;
        },
        {} as Record<number, Product[] | undefined>
      )
  );
