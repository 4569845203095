import { gql } from 'graphql-request';

export const categorizedCollectionsQuery = gql`
  query categorizedCollectionsQuery {
    collections(first: 250) {
      edges {
        cursor
        node {
          id
          title
          handle
          landingPage: metafield(namespace: "my_fields", key: "landingPage") {
            value
          }
          category: metafield(namespace: "my_fields", key: "category") {
            value
          }
        }
      }
    }
  }
`;
