/* eslint no-param-reassign: 0 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import assign from 'lodash/assign';
import type { User } from '@/types/user';
import type { RootState } from '@/redux/store';

// Types

export interface AuthState {
  user: User | null;
  loading: boolean;
}

// Initial state

const initialState: AuthState = {
  user: null,
  loading: true,
};

// Slice

const slice = createSlice({
  name: 'auth',

  initialState,

  reducers: {
    setAuth(state, action: PayloadAction<AuthState>) {
      assign(state, action.payload);
    },
    setAuthLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    clearAuth(state) {
      state.user = initialState.user;
    },
  },
});

// Selectors

export const selectAuth = (state: RootState): AuthState => state.auth;
export const selectUser = (state: RootState): User | null => state.auth.user;

// Actions

export const { setAuth, setAuthLoading, clearAuth } = slice.actions;

export default slice.reducer;
