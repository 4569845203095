import { useCallback, useEffect, useRef } from 'react';

// Define constants for intervals and delays
export const TYPING_INTERVAL_MS = 30;
export const CURSOR_BLINKING_INTERVAL_MS = 500;
export const END_OF_PLACEHOLDER_DELAY_MS = 3000;

type UseAnimatedPlaceholder = (
  inputEl: HTMLTextAreaElement | HTMLInputElement | null,
  placeholders: string[]
) => void;

export const useAnimatedPlaceholder: UseAnimatedPlaceholder = (inputEl, placeholders) => {
  // Create refs to store interval and timeout IDs
  const typingIntervalIdRef = useRef<ReturnType<typeof setInterval>>();
  const holdingTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const blinkingIntervalIdRef = useRef<ReturnType<typeof setInterval>>();

  // Helper function to clear intervals and timeouts
  const clearTimeouts = () => {
    clearInterval(typingIntervalIdRef.current);
    clearTimeout(holdingTimeoutRef.current);
    clearInterval(blinkingIntervalIdRef.current);
  };

  // Helper function to set the input element's placeholder and control the cursor visibility
  const setPlaceholder = (
    el: HTMLTextAreaElement | HTMLInputElement | null,
    placeholder: string,
    isCursorVisible: boolean
  ) => {
    requestAnimationFrame(() => {
      el?.setAttribute('placeholder', `${placeholder}${isCursorVisible ? '|' : ' '}`);
    });
  };

  // Helper function to blink the cursor at the end of the current placeholder
  const blinkCursor = (el: HTMLTextAreaElement | HTMLInputElement | null, placeholder: string) =>
    new Promise<void>((resolve) => {
      let isCursorVisible = true;

      // Stop animation for some time to allow the user to read the placeholder
      holdingTimeoutRef.current = setTimeout(() => {
        clearTimeouts();
        resolve();
      }, END_OF_PLACEHOLDER_DELAY_MS);

      // Start blinking the cursor
      blinkingIntervalIdRef.current = setInterval(() => {
        isCursorVisible = !isCursorVisible;
        setPlaceholder(el, placeholder, isCursorVisible);
      }, CURSOR_BLINKING_INTERVAL_MS);
    });

  // Helper function to type the placeholder text one character at a time
  const typePlaceholder = (
    el: HTMLTextAreaElement | HTMLInputElement | null,
    placeholderList: string[]
  ) => {
    let index = 0;
    let placeholder = '';

    const startTypingInterval = () => {
      typingIntervalIdRef.current = setInterval(async () => {
        // Get the current placeholder and add the next character
        const currPlaceholder = placeholderList?.[index];
        placeholder = currPlaceholder.slice(0, placeholder.length + 1);

        // Set the placeholder
        setPlaceholder(el, placeholder, true);

        // Check if it's the last character
        if (placeholder === currPlaceholder) {
          // Stop typing and blink the cursor
          clearTimeouts();
          await blinkCursor(el, placeholder);

          // Move to the next placeholder
          index = index === placeholderList.length - 1 ? 0 : index + 1;
          placeholder = '';
          startTypingInterval();
        }
      }, TYPING_INTERVAL_MS);
    };

    startTypingInterval();
  };

  // Start the typing animation
  const startAnimation = useCallback(
    () => typePlaceholder(inputEl, placeholders),
    [inputEl, placeholders]
  );

  // Use the useEffect hook to handle side effects related to the input element and placeholders
  useEffect(() => {
    clearTimeouts();

    if (inputEl && placeholders?.length) startAnimation();

    return () => clearTimeouts();
  }, [inputEl, placeholders]);
};
