import React, { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Typography } from '@/components/ui/Typography';
import { Colors } from '@/constants/Colors';
import { SlideSwitchTransition } from '@/components/ui/SlideSwitchTransition';

export const AI_FORM_LOADING_STATE_TEST_ID = 'ai-form-loading-state';

const TEXTS = ['Categories...', 'Occasions...', 'Collections...', 'Recipients...', 'Products...'];
const TEXT_COLORS = [
  Colors.pink[200],
  Colors.pink[300],
  Colors.indigo[600],
  Colors.snappy[700],
  Colors.snappy[600],
];

interface AIFormLoadingStateProps {
  loading: boolean;
}

export const AIFormLoadingState: React.FC<AIFormLoadingStateProps> = ({ loading }) => {
  const searchingElRef = useRef<HTMLDivElement>(null);
  const progressElRef = useRef<HTMLDivElement>(null);

  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => setIndex((i) => (i + 1) % TEXTS.length), 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div
      className="relative flex flex-col items-center"
      data-testid={AI_FORM_LOADING_STATE_TEST_ID}
    >
      <SlideSwitchTransition activeKey={loading} appear={false}>
        <CSSTransition
          appear
          in={loading}
          timeout={500}
          style={{ '--duration': '0.5s', '--max-scale': 0.9 }}
          nodeRef={searchingElRef}
          unmountOnExit
          classNames="zoom-in"
        >
          <div ref={searchingElRef} className="flex items-baseline justify-center gap-x-1">
            <Typography size={20} fontFamily="inter" color={Colors.primary[700]}>
              Searching
            </Typography>

            <div className="overflow-hidden w-[130px]">
              <SlideSwitchTransition activeKey={index} appear={false}>
                <Typography size={20} weight={600} fontFamily="inter" color={TEXT_COLORS[index]}>
                  {TEXTS[index]}
                </Typography>
              </SlideSwitchTransition>
            </div>
          </div>
        </CSSTransition>

        {!loading && (
          <Typography size={20} fontFamily="inter" color={Colors.primary[700]}>
            Generating collection
            <span className="loading-3-dots" />
          </Typography>
        )}
      </SlideSwitchTransition>

      <CSSTransition
        appear
        in={loading}
        timeout={500}
        style={{ '--duration': '0.5s', '--max-scale': 0.9 }}
        nodeRef={progressElRef}
        unmountOnExit
        classNames="zoom-in"
      >
        <div
          ref={progressElRef}
          className="absolute top-full flex justify-center pt-1 overflow-hidden"
        >
          <div className="h-6 flex items-center">
            <div className="loading-progress-line w-[115px]" />
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};
