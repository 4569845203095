import React from 'react';
import clsx from 'clsx';
import { useHorizontalDraggableList } from '@/hooks/useHorizontalDraggableList';
import { getObjectsWithIdsFromIndex } from '@/utils/misc';
import { Colors } from '@/constants/Colors';
import Skeleton from 'react-loading-skeleton';
import { Chip } from '@/components/ui/Chip';

interface PersonasChipsProps {
  onChange(value: string): void;
  personas: string[];
  chipTestId: string;
  value?: string;
  isLoading?: boolean;
  isFullScreen?: boolean;
}

interface PersonaSkeletonProps {
  numberOfChips: number;
}

export const ALL_TAG = 'All';
export const NUM_OF_SKELETON_CHIPS = 10;

export const PersonaSkeleton: React.FC<PersonaSkeletonProps> = ({ numberOfChips }) => (
  <>
    {getObjectsWithIdsFromIndex(numberOfChips).map((item) => (
      <Skeleton
        key={item.id}
        baseColor={Colors.primary['200']}
        highlightColor={Colors.primary['100']}
        width={80}
        height={40}
        borderRadius={999}
      />
    ))}
  </>
);

export const PersonasChips: React.FC<PersonasChipsProps> = ({
  personas,
  onChange,
  chipTestId,
  value,
  isLoading,
  isFullScreen = false,
}) => {
  const { listRef, dragging } = useHorizontalDraggableList({});

  return (
    <div
      ref={listRef}
      className={clsx('persona-chips', { 'wrap-chips': isFullScreen })}
      data-testid={chipTestId}
    >
      {isLoading ? (
        <PersonaSkeleton numberOfChips={NUM_OF_SKELETON_CHIPS} />
      ) : (
        <>
          <Chip
            isSelected={value === ALL_TAG}
            onClick={() => {
              if (!dragging) {
                onChange(ALL_TAG);
              }
            }}
          >
            {ALL_TAG}
          </Chip>
          {personas.map((chip) => (
            <Chip
              key={chip}
              isSelected={chip === value}
              onClick={() => {
                if (!dragging) {
                  onChange(chip);
                }
              }}
            >
              {chip}
            </Chip>
          ))}
        </>
      )}

      {/* Styles */}

      <style jsx>
        {`
          .persona-chips {
            -ms-overflow-style: none;
            scrollbar-width: none;
            @apply flex gap-2 overflow-auto w-full;
            &.wrap-chips {
              @apply flex-wrap;
            }
          }
          .persona-chips::-webkit-scrollbar {
            display: none;
          }
        `}
      </style>
    </div>
  );
};
