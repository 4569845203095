import { useCallback, useState } from 'react';
import { UrlObject } from 'node:url';
import { useRouter } from 'next/router';
import { useCaptcha } from '@/hooks/useCaptcha';
import { useAppSelector } from '@/hooks/useStore';
import { selectActiveBudgetRange, useLazyGetTagsFromGPTQuery } from '@/redux';
import { QueryParams } from '@/utils/browser';
import type { AIGenerateCollectionFormValues } from '../../index';

export enum AI_GENERATE_COLLECTION_FORM_ERROR_KEYS {
  NO_TAGS = 'NO_TAGS',
  UNKNOWN = 'UNKNOWN',
}

export const AI_GENERATE_COLLECTION_FORM_ERRORS: Record<
  AI_GENERATE_COLLECTION_FORM_ERROR_KEYS,
  { title: string; description?: string }
> = {
  [AI_GENERATE_COLLECTION_FORM_ERROR_KEYS.NO_TAGS]: {
    title: 'That was a very specific gift!',
    description: 'Try changing your description or tags',
  },
  [AI_GENERATE_COLLECTION_FORM_ERROR_KEYS.UNKNOWN]: {
    title: 'Something went wrong.\nPlease try again.',
    description: 'Try changing your description or tags',
  },
};

type UseCollectionGeneration = (options?: { onSubmitSuccess?(): void }) => {
  isFetching: boolean;
  isFetchingTags: boolean;
  submittedValues: AIGenerateCollectionFormValues | undefined;
  budget: number;
  error: AI_GENERATE_COLLECTION_FORM_ERROR_KEYS | null;
  resetError(): void;
  onSubmit(values: AIGenerateCollectionFormValues): void;
};

export const useCollectionGeneration: UseCollectionGeneration = ({ onSubmitSuccess } = {}) => {
  const router = useRouter();
  const activeBudgetRange = useAppSelector(selectActiveBudgetRange);
  const executeCaptcha = useCaptcha();

  const [isFetching, setIsFetching] = useState(false);
  const [submittedValues, setSubmittedValues] = useState<AIGenerateCollectionFormValues>();
  const [error, setError] = useState<AI_GENERATE_COLLECTION_FORM_ERROR_KEYS | null>(null);

  const [fetchProductTags, { isFetching: isFetchingTags }] = useLazyGetTagsFromGPTQuery();

  const resetError = useCallback(() => setError(null), []);

  const onSubmit = useCallback(
    async (values: AIGenerateCollectionFormValues) => {
      try {
        resetError();
        setIsFetching(true);
        setSubmittedValues(values);

        const captchaToken = await executeCaptcha('aiTags');
        const prompt = [values.prompt, values.tags.join(', ')].filter(Boolean).join('; ');

        const { data } = await fetchProductTags({ prompt, captchaToken });

        if (!data || !data.tags?.length) {
          setError(AI_GENERATE_COLLECTION_FORM_ERROR_KEYS.NO_TAGS);
          return;
        }

        const { age, gender, tags = [] } = data;

        onSubmitSuccess?.();

        const url: UrlObject = {
          pathname: '/generated-collection',
          query: QueryParams.stringify({ budget: activeBudgetRange.max, tags, gender, age }),
        };
        await router.push(url);
      } catch (err) {
        setError(AI_GENERATE_COLLECTION_FORM_ERROR_KEYS.UNKNOWN);
      } finally {
        setIsFetching(false);
      }
    },
    [activeBudgetRange, fetchProductTags, executeCaptcha, onSubmitSuccess]
  );

  return {
    isFetching,
    isFetchingTags,
    submittedValues,
    budget: activeBudgetRange.max,
    error,
    resetError,
    onSubmit,
  };
};
