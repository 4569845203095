import dynamic from 'next/dynamic';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { lockDocumentBodyScroll } from '@/utils/browser';
import { useOnClickOutside } from '@/hooks/useOnClickOutside';
import { useDeviceType } from '@/hooks/useDeviceType';
import { NavLink } from '@/components/ui/NavBar/NavLink';
import { useKeypress } from '@/hooks/useKeyPress';
import { useWindowResize } from '@/hooks/useWindowResize';
import { GroupedCollection } from '@/types/user';
import { AnalyticsService } from '@/services/AnalyticsService';

const LazyBrowseGiftsSection = dynamic(() => import('./LazyBrowseGiftsSection'));

interface BrowseGiftsDropdownProps {
  onCollectionClick(collection: GroupedCollection): void;
}

export const BrowseGiftsDropdown: React.FC<BrowseGiftsDropdownProps> = ({ onCollectionClick }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);
  const sectionRef = useRef<HTMLDivElement>(null);
  const triangleRef = useRef<HTMLDivElement>(null);

  const [isActive, setIsActive] = useState(false);

  const { isDesktop } = useDeviceType();

  const toggleMenu = useCallback(() => {
    AnalyticsService.track('consumer_navbar_browse_gifts_clicked');
    setIsActive((open) => !open);
  }, []);

  const closeMenu = useCallback(() => setIsActive(false), []);

  // Calc the triangle position on mount and on window resize to align it with the button.
  const calcTrianglePosition = useCallback(() => {
    const xPosition = calcTriangleXPosition(buttonRef.current, sectionRef.current);
    triangleRef.current?.style.setProperty('--x-position', xPosition ? `${xPosition}px` : '50%');
  }, [isActive]);

  const handleCollectionClick = useCallback(
    (collection: GroupedCollection) => {
      if (collection.handle || collection.href) closeMenu();
      onCollectionClick(collection);
    },
    [closeMenu, onCollectionClick]
  );

  useEffect(() => {
    if (isActive && isDesktop) window.scroll({ top: 0, behavior: 'smooth' });
    lockDocumentBodyScroll(isActive);
    return () => lockDocumentBodyScroll(false);
  }, [isActive]);

  useWindowResize(calcTrianglePosition);
  useOnClickOutside(containerRef, closeMenu);
  useKeypress('Escape', closeMenu);

  return (
    <div ref={containerRef} className="browse-gifts-dropdown">
      <NavLink
        ref={buttonRef}
        leftIcon="SearchIcon"
        withCaret
        isActive={isActive}
        onClick={toggleMenu}
      >
        Browse Gifts
      </NavLink>

      <LazyBrowseGiftsSection
        isActive={isActive}
        sectionRef={sectionRef}
        triangleRef={triangleRef}
        closeMenu={closeMenu}
        onCollectionClick={handleCollectionClick}
      />
    </div>
  );
};

// Helpers

/**
 * @description Calculates the x position of the triangle relatively to the other element.
 * @param targetEl {HTMLElement | null} - The target element to align the left position.
 * @param containerEl {HTMLElement | null} - The element to calculate the position relatively to.
 * @returns {number | null} - The x position of the triangle.
 */
function calcTriangleXPosition(
  targetEl: HTMLElement | null,
  containerEl: HTMLElement | null
): number | null {
  if (!(targetEl instanceof HTMLElement) || !(containerEl instanceof HTMLElement)) return null;
  if (!targetEl.getBoundingClientRect || !containerEl.getBoundingClientRect) return null;

  const targetRect = targetEl.getBoundingClientRect();
  const containerRect = containerEl.getBoundingClientRect();

  const el1CenterX = targetRect.left + targetRect.width / 2;

  return el1CenterX - containerRect.left;
}
