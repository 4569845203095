import React, { memo } from 'react';
import clsx from 'clsx';
import { Icon, IconName } from '@/components/ui/Icons/Icon';
import { IconDirection } from '@/components/ui/Icons/types';
import { BaseLink, BaseLinkProps } from '@/components/ui/BaseLink';
import { Typography } from '@/components/ui/Typography';

interface ListItemProps {
  title?: string;
  children?: React.ReactNode;
  isSelected?: boolean;
  testId?: string;
  href?: BaseLinkProps['href'];
  target?: '_blank' | '_self';
  className?: string;
  rightIcon?: IconName;
  rightIconSize?: number;
  rightIconDir?: IconDirection;
  strictActiveCheck?: boolean;
  onClick?(): void;
}

export const ListItem = memo<ListItemProps>(
  ({
    title,
    children,
    isSelected,
    testId,
    href,
    target = '_self',
    className,
    rightIcon,
    rightIconSize = 16,
    rightIconDir,
    strictActiveCheck,
    onClick,
  }) => {
    const Tag = (href ? BaseLink : 'button') as React.ElementType;
    const tagProps = href ? { href, target, strictActiveCheck } : { type: 'button' };

    return (
      <Tag
        {...tagProps}
        data-testid={testId}
        className={clsx('list-item', className, { active: isSelected })}
        onClick={onClick}
      >
        {!!title && (
          <Typography size={14} fontFamily="dm-sans" lineHeight="150%" className="list-item__title">
            {title}
          </Typography>
        )}

        {children}

        {rightIcon && (
          <Icon
            name={rightIcon}
            size={rightIconSize}
            direction={rightIconDir}
            color="currentColor"
          />
        )}

        {/* Styles */}
        <style jsx>{`
          .list-item {
            @apply flex-auto inline-flex items-center gap-2 px-2 py-1 rounded bg-white;
            @apply select-none outline-none;

            :global(&__title) {
              @apply text-primary-800;
            }

            &:hover,
            &:focus-visible {
              @apply bg-indigo-100 text-indigo-700;

              :global(.list-item__title) {
                @apply text-indigo-700;
              }
            }

            &.active {
              @apply bg-primary-100 text-indigo-700;

              :global(.list-item__title) {
                @apply text-primary-900 font-medium;
              }

              &:hover,
              &:focus-visible {
                @apply bg-primary-200 text-indigo-700;

                :global(.list-item__title) {
                  @apply text-primary-900;
                }
              }
            }
          }
        `}</style>
      </Tag>
    );
  }
);
