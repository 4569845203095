import React, { CSSProperties, useCallback, useMemo, useState } from 'react';
import Image, { ImageLoaderProps, ImageProps } from 'next/image';
import clsx from 'clsx';
import { Colors } from '@/constants/Colors';
import { CLOUDINARY_URL, DEFAULT_CDN_URL } from '@/constants/Assets';

export const BASE_IMAGE_ANIMATION_DURATION_MS = 500;

export interface BaseImageProps extends Omit<ImageProps, 'src' | 'alt'> {
  src?: ImageProps['src']; // makes it optional to avoid TS warnings of missing src prop in entities
  alt: string;
  appearBg?: string | boolean;
  testId?: string;
  objectFit?: CSSProperties['objectFit'];
  isCloudinary?: boolean;
  containerClassName?: string;
  visibleByDefault?: boolean;
}

export const BaseImage: React.FC<BaseImageProps> = ({
  src,
  alt,
  style,
  appearBg,
  width,
  height,
  unoptimized,
  layout = 'intrinsic',
  objectFit = 'contain',
  objectPosition = 'center',
  className,
  testId,
  isCloudinary,
  visibleByDefault,
  containerClassName,
  onLoad,
  ...props
}) => {
  const [loaded, setLoaded] = useState(!!visibleByDefault);

  const handleImageLoad = useCallback(
    (e: Parameters<NonNullable<ImageProps['onLoad']>>[0]) => {
      setLoaded(true);
      onLoad?.(e);
    },
    [setLoaded, onLoad]
  );

  const wrappedStyles = useMemo(
    () =>
      ({
        '--bg-color': appearBg === true ? Colors.indigo[100] : appearBg,
      }) as React.CSSProperties,
    [appearBg]
  );

  const layoutToCSS: Partial<Record<NonNullable<ImageProps['layout']>, React.CSSProperties>> = {
    intrinsic: { maxWidth: '100%', height: 'auto' },
    responsive: { width: '100%', height: 'auto' },
    fill: { width: '100%', height: '100%' },
    fixed: { width, height },
  };

  const styles = useMemo(
    () => ({
      objectFit,
      objectPosition,
      ...layoutToCSS[layout],
      ...style,
    }),
    [style, width, height, objectFit, objectPosition, layout]
  );

  const cloudinaryLoader = useCallback(
    (imageProps: ImageLoaderProps) =>
      `${CLOUDINARY_URL}/w_${imageProps.width || 'auto'}/q_${imageProps.quality || 75}/consumer/${
        imageProps.src
      }`,
    []
  );

  const defaultLoader = useCallback(
    (imageProps: ImageLoaderProps) =>
      DEFAULT_CDN_URL ? new URL(imageProps.src, DEFAULT_CDN_URL).href : imageProps.src,
    []
  );

  return (
    <div
      className={clsx('base-image', containerClassName, `layout-${layout}`, {
        loaded,
        visible: visibleByDefault,
      })}
      style={wrappedStyles}
      data-testid={testId}
    >
      {src && (
        <Image
          {...props}
          src={src}
          alt={alt}
          style={styles}
          width={width}
          height={height}
          fill={layout === 'fill'}
          unoptimized={unoptimized ?? !isCloudinary}
          loader={isCloudinary ? cloudinaryLoader : defaultLoader}
          className={clsx('base-image__img', className)}
          onLoad={handleImageLoad}
        />
      )}

      {/* Styles */}

      <style jsx>
        {`
          .base-image {
            --duration: ${BASE_IMAGE_ANIMATION_DURATION_MS}ms;

            @apply relative flex flex-col overflow-hidden;
            @apply transition-all duration-[var(--duration)] delay-200;

            &:not(.layout-fixed) {
              @apply w-full h-full;
            }

            :global(&__img) {
              @apply transition-all duration-[var(--duration)];
            }

            &:not(.loaded):not(.visible) {
              background-color: var(--bg-color);

              :global(.base-image__img) {
                @apply scale-[.95] opacity-0;
              }
            }
          }
        `}
      </style>
    </div>
  );
};
