import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { useAppSelector } from '@/hooks/useStore';
import { selectTheRecentGift } from '@/redux/api/selectors/gifts';

type onConversationCreateHandler = (event: {
  conversationId: string;
  createdAt: string;
  ended: boolean;
  isInAssistantMode: boolean;
}) => void;

export const useChatbot = () => {
  const router = useRouter();
  const recentGift = useAppSelector(selectTheRecentGift);

  const giftId = (router.query.giftId as string) ?? recentGift?.id;

  const handleConversationCreate: onConversationCreateHandler = useCallback(
    (res) => {
      window.Kustomer.describeConversation({
        conversationId: res.conversationId,
        customAttributes: {
          giftIdStr: giftId,
        },
      });
    },
    [giftId]
  );

  const initKustomer = useCallback(() => {
    window.Kustomer.start({ hideChatIcon: true }, () => {
      window.Kustomer.addListener('onConversationCreate', handleConversationCreate);
    });
  }, [handleConversationCreate, router.pathname]);

  const openChatBot = useCallback(() => {
    if (!window.Kustomer) return;
    if (window.Kustomer.startFinished) window.Kustomer.open();
    else {
      window.Kustomer.start({ hideChatIcon: true }, () => {
        window.Kustomer.addListener('onConversationCreate', handleConversationCreate);
        window.Kustomer.open();
      });
    }
  }, [handleConversationCreate]);

  useEffect(() => {
    window.addEventListener('kustomerLoaded', initKustomer);

    return () => {
      window.removeEventListener('kustomerLoaded', initKustomer);
    };
  }, [handleConversationCreate, initKustomer]);

  return { openChatBot };
};
