import React, { forwardRef, HTMLAttributeAnchorTarget } from 'react';
import type { LinkProps } from 'next/link';
import clsx from 'clsx';
import { Spinner } from '@/components/ui/Spinner';
import { Colors } from '@/constants/Colors';
import { BaseLink } from '@/components/ui/BaseLink';

type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'secondary-bold'
  | 'secondary-error'
  | 'tertiary'
  | 'flat'
  | 'filled'
  | 'error';

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  href?: LinkProps['href'];
  isSquare?: boolean;
  isLoading?: boolean;
  size?: 'normal' | 'narrow' | 'compact' | 'medium' | 'big';
  target?: HTMLAttributeAnchorTarget;
  testId?: string;
  title?: string;
  type?: 'button' | 'reset' | 'submit';
  variant?: ButtonVariant;
  onClick?(event: React.MouseEvent<HTMLButtonElement & HTMLAnchorElement>): void;
  onMouseEnter?(e: React.MouseEvent<HTMLButtonElement & HTMLAnchorElement>): void;
  onMouseLeave?(e: React.MouseEvent<HTMLButtonElement & HTMLAnchorElement>): void;
  onTouchEnd?(e: React.TouchEvent<HTMLButtonElement & HTMLAnchorElement>): void;
  onFocus?(e: React.FocusEvent<HTMLButtonElement & HTMLAnchorElement>): void;
  onBlur?(e: React.FocusEvent<HTMLButtonElement & HTMLAnchorElement>): void;
  onTouchStart?: React.TouchEventHandler<HTMLButtonElement & HTMLAnchorElement>;
}

export const LOADING_CONTENT_TEST_ID = 'loading-content';

export const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  (props, ref) => {
    const {
      className,
      children,
      disabled = false,
      href,
      isLoading = false,
      isSquare,
      size = 'normal',
      target,
      testId,
      type = 'button',
      variant = 'primary',
      onClick,
      ...rest
    } = props;
    const Tag = getTag(href);
    const testIdAttributes = { [href ? 'testId' : 'data-testid']: testId };
    const spinnerColor = ['primary', 'error'].includes(variant)
      ? Colors.white
      : Colors.indigo['700'];

    const handleOnClick = (event: React.MouseEvent<HTMLButtonElement & HTMLAnchorElement>) => {
      if (isLoading || disabled) {
        event.preventDefault();
        return;
      }
      onClick?.(event);
    };

    return (
      <Tag
        ref={ref as never} // Fixes the interface of the forwardRef
        disabled={disabled}
        onClick={handleOnClick}
        className={clsx('base-button', variant, size, className, {
          square: isSquare,
          loading: isLoading,
        })}
        {...rest}
        {...testIdAttributes}
        // BaseLink props
        href={href as LinkProps['href']}
        target={target}
        // <button> attributes
        type={type}
      >
        {isLoading && (
          <span className="base-button__spinner" data-testid={LOADING_CONTENT_TEST_ID}>
            <Spinner color={spinnerColor} />
          </span>
        )}

        <span className="base-button__inner">{children}</span>

        {/* Styles */}

        <style jsx>
          {`
            .base-button {
              @apply relative inline-flex items-center justify-center;
              @apply font-dm-sans font-medium text-base;
              @apply transition-all duration-300 ease-in-out;
              @apply rounded-full outline-none;

              &.active {
                @apply bg-black;
              }
              &:not(.narrow):not(.compact) {
                @apply px-6;
              }
              &:not(.compact) {
                @apply py-[0.625rem] min-h-[44px];
              }
              &.medium:not(.narrow):not(.compact) {
                @apply py-2 px-4 min-h-[40px];
              }
              &.big:not(.narrow):not(.compact) {
                @apply py-2 px-4 min-h-[56px];
              }
              &__spinner {
                @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
              }
              &__inner {
                @apply inline-flex items-center justify-center;
              }

              &.primary {
                @apply bg-indigo-700 text-white;

                &:disabled,
                &.disabled {
                  &:not(.loading) {
                    @apply bg-primary-200 text-primary-500 active:opacity-100;
                  }
                }

                &:not(:disabled):not(.disabled) {
                  &:hover,
                  &:focus-visible {
                    @apply bg-indigo-800;
                  }
                }
              }

              &.secondary {
                @apply bg-transparent text-primary-800 border-solid border border-indigo-400;

                &:disabled,
                &.disabled {
                  &:not(.loading) {
                    @apply bg-primary-200 text-primary-500 active:opacity-100;
                  }
                }

                &:not(:disabled):not(.disabled) {
                  &:hover,
                  &:focus-visible {
                    @apply text-primary-900 border-indigo-700;
                  }
                }
              }

              &.secondary-bold {
                @apply bg-transparent text-primary-900 border-solid border border-primary-600;

                &:disabled,
                &.disabled {
                  &:not(.loading) {
                    @apply text-primary-500 border-primary-500 active:opacity-100;
                  }
                }

                &:not(:disabled):not(.disabled) {
                  @apply shadow-[0_4px_4px_0_rgba(1,39,84,0.15)];

                  &:hover,
                  &:focus-visible {
                    @apply text-primary-900 border-primary-900;
                    @apply shadow-[0_8px_8px_0_rgba(1,39,84,0.15)];
                  }
                }
              }

              &.secondary-error {
                @apply bg-transparent text-coral-600 border-solid border border-system-error;

                &:disabled,
                &.disabled {
                  &:not(.loading) {
                    @apply bg-primary-200 text-primary-500 active:opacity-100;
                  }
                }

                &:not(:disabled):not(.disabled) {
                  &:hover,
                  &:focus-visible {
                    @apply bg-primary-200;
                  }
                }
              }

              &.tertiary {
                @apply bg-transparent text-indigo-700;

                &:disabled,
                &.disabled {
                  &:not(.loading) {
                    @apply bg-primary-200 text-primary-500 active:opacity-100;
                  }
                }

                &:not(:disabled):not(.disabled) {
                  &:hover,
                  &:focus-visible {
                    @apply bg-indigo-200 rounded-full;
                  }
                }
              }

              &.filled {
                @apply text-primary-800;
                background-color: rgba(1, 39, 84, 0.05);

                &:disabled,
                &.disabled {
                  &:not(.loading) {
                    @apply bg-primary-200 text-primary-500 active:opacity-100;
                  }
                }

                &:not(:disabled):not(.disabled) {
                  &:hover,
                  &:focus-visible {
                    background-color: rgba(1, 39, 84, 0.1);
                  }
                }
              }

              &.error {
                @apply text-white;
                background-color: ${Colors.system.error};

                &:disabled,
                &.disabled {
                  &:not(.loading) {
                    @apply bg-primary-200 text-primary-500 active:opacity-100;
                  }
                }

                &:not(:disabled):not(.disabled) {
                  &:hover,
                  &:focus-visible {
                    background-color: ${Colors.darkBrown};
                  }
                }
              }

              &.flat {
                @apply bg-transparent text-indigo-700;

                &:disabled,
                &.disabled {
                  @apply opacity-50;
                  &:not(.loading) {
                    @apply bg-primary-200 text-primary-500 active:opacity-100;
                  }
                }
              }

              &.square {
                @apply rounded-2lg;
              }

              &.loading {
                @apply cursor-progress;
                .base-button__inner {
                  @apply invisible;
                }
              }
            }
          `}
        </style>
      </Tag>
    );
  }
);

function getTag(href: ButtonProps['href']) {
  return href ? BaseLink : 'button';
}
