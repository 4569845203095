/* eslint no-param-reassign: 0 */
import { createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit';
import type { RootState } from '@/redux/store';
import { MyGiftsTabLabels } from '@/components/my-gifts/constants';

// Types

export type MyGiftsAmount = {
  [MyGiftsTabLabels.SENT]: number;
  [MyGiftsTabLabels.RECEIVED]: number;
  [MyGiftsTabLabels.SCHEDULED]: number;
};

export interface uiState {
  isEmailSubscriptionShown: boolean;
  myGiftsAmount: MyGiftsAmount;
  isMarketingCTAsShown: boolean;
}

const initialState: uiState = {
  isEmailSubscriptionShown: true,
  myGiftsAmount: {
    [MyGiftsTabLabels.SENT]: 0,
    [MyGiftsTabLabels.RECEIVED]: 0,
    [MyGiftsTabLabels.SCHEDULED]: 0,
  },
  isMarketingCTAsShown: true,
};

const ui = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setIsEmailSubscriptionShown(state, action: PayloadAction<boolean>) {
      state.isEmailSubscriptionShown = action.payload;
    },
    setMyGiftsAmount(state, action: PayloadAction<Partial<MyGiftsAmount>>) {
      state.myGiftsAmount = { ...state.myGiftsAmount, ...action.payload };
    },
    setIsMarketingCTAsShown(state, action: PayloadAction<boolean>) {
      state.isMarketingCTAsShown = action.payload;
    },
  },
});

// Selectors

export const selectIsEmailSubscriptionShown = (state: RootState) =>
  state.ui.isEmailSubscriptionShown;
export const selectMyGiftsAmount = (state: RootState) => state.ui.myGiftsAmount;
export const selectIsMarketingCTAsShown = (state: RootState) => state.ui.isMarketingCTAsShown;

export const { setIsEmailSubscriptionShown, setMyGiftsAmount, setIsMarketingCTAsShown } =
  ui.actions;

export default ui.reducer as Reducer<typeof initialState>;
