// Create API method

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { catchAndNotify } from '@/utils/errorHandlers';
import { GreetingDto } from '@/types/entities/greeting';
import { KEEP_UNUSED_RTK_QUERY_DATA_FOR } from '@/constants/Values';

const GREETING_API = '/greetings';

export const greetingApi = createApi({
  reducerPath: 'greetingApi',
  keepUnusedDataFor: KEEP_UNUSED_RTK_QUERY_DATA_FOR,
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.NEXT_PUBLIC_API_BASE_URL}`,
  }),
  tagTypes: ['Greeting'],
  endpoints: (builder) => ({
    // Greeting API
    greeting: builder.query({
      query: ({ relationshipStatus, level, captchaToken }) => ({
        url: GREETING_API,
        body: {
          status: relationshipStatus,
          level,
          captchaToken,
        },
        method: 'POST',
      }),
      providesTags: ['Greeting'],
      transformResponse: catchAndNotify({
        fn: (data: GreetingDto) => data.greeting,
        errorMessage: 'Could not create greeting.',
      }),
    }),
  }),
});

export const { useLazyGreetingQuery } = greetingApi;
