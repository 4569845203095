/* eslint-disable global-require */

module.exports = {
  content: ['./src/**/*.{js,ts,jsx,tsx}'],
  theme: {
    container: {
      center: true,
      padding: '0.75rem',
      screens: {
        xl: '1240px',
      },
    },
    extend: {
      boxShadow: {
        arrows: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        signIn: '0 15px 45px rgba(100,153,214,0.3)',
        card: '0px 1px 2px rgba(0, 0, 0, 0.1)',
        dropdown: '0px 8px 10px -6px rgba(0, 0, 0, 0.1), 0px 20px 25px -5px rgba(0, 0, 0, 0.1)',
        '3lg': '0 10px 20px rgba(0,0,0,0.05)',
        '4lg': '0 18px 40px -4px rgba(6, 20, 36, 0.2)',
        drawer: '0 0 15px rgba(0, 0, 0, 0.5)',
        product: '0px 5px 10px rgba(40, 100, 168, 0.04)',
        shuffle: '0px 2px 4px rgba(48, 119, 201, 1)',
        toggle: 'inset 0px 1px 2px rgba(1, 39, 84, 0.15)',
        toggleButton: '0px 2px 4px rgba(1, 39, 84, 0.25)',
      },
      fontFamily: {
        inter: ['Inter', 'sans-serif'],
        'dm-sans': ['DM Sans', 'sans-serif'],
        'dm-serif': ['DM Serif Display', 'sans-serif'],
        caveat: ['Caveat', 'sans-serif'],
        satisfy: ['Satisfy', 'sans-serif'],
      },
      fontSize: {
        0: '0', // 0px
        xxs: '0.625rem', // 10px
        '2lg': '1.1875rem', // 19px
        '2.5xl': '1.75rem', // 28px
        '3.5xl': '2rem', // 32px
        '4.5xl': '2.5rem', // 40px
      },
      lineHeight: {
        small: '1.1',
        tight: '1.3',
      },
      colors: {
        textDark: '#012754',
        transparent: 'transparent',
        current: 'currentColor',
        scrollBarBg: ' #a0aec0',
        scrollBarColor: 'rgba(229, 231, 235,0)',
        modalOverlay: 'rgba(0, 0, 0, 0.4)',
        overlay: 'rgba(1, 39, 84, 0.03)',
        white: '#ffffff',
        dashboard: '#f6f9fc',
        skeletonGray: '#ebebeb',
        system: {
          success: '#328560', // success
          error: '#D14734', // error
          warning: '#FFC352', // warning
          info: '#713CFC', // info
        },
        primary: {
          100: '#FAFBFC',
          200: '#F2F4F6',
          300: '#E6E9EE',
          400: '#CCD4DD',
          500: '#A6B3C3', // disabled text, search field
          600: '#8093A9', // tertiary color
          700: '#5A7390', // icons default color
          800: '#345276', // secondary text
          900: '#012754', // primary color, primary text
        },
        indigo: {
          100: '#F5F8FC',
          200: '#EAF1FA',
          300: '#E0EBF7',
          400: '#CBDDF1',
          500: '#97BBE4',
          600: '#6499D6',
          700: '#3077C9', // main CTA
          800: '#2864A8',
        },
        snappy: {
          100: '#F5FDFF',
          200: '#EBFBFF',
          300: '#CDF4FF',
          400: '#9AE9FF',
          500: '#68DFFF',
          600: '#36D4FF',
          700: '#3CA8DE',
        },
        gold: {
          100: '#FFFCF6',
          200: '#FFF9EE',
          300: '#FFF0D4',
          400: '#FFE1A8',
          500: '#FFD27D',
          600: '#FFC352',
          700: '#3D330A',
        },
        purple: {
          100: '#F8F5FD',
          200: '#F1ECFA',
          250: '#EAE2F8',
          300: '#DBCFF3',
          400: '#B89FE7',
          500: '#946FDB',
          600: '#713FCF',
        },
        green: {
          100: '#F7FDFA',
          200: '#EFFBF5',
          300: '#D6F5E7',
          400: '#ACEBCF',
          500: '#83E0B7',
          600: '#5AD69F',
          700: '#0A4238',
        },
        coral: {
          100: '#FFBDB4',
          200: '#FF7B69',
          300: '#FF7575',
          400: '#F35555',
          500: '#E14545',
          600: '#ce3f3f',
          700: '#360D08',
        },
        pink: {
          50: '#FFDBDB',
          100: '#F7C6D7',
          200: '#F294C8',
          300: '#CC7AC4',
        },
      },
      transitionTimingFunction: {
        'in-expo': 'cubic-bezier(0.95, 0.05, 0.795, 0.035)',
        'out-expo': 'cubic-bezier(0.19, 1, 0.22, 1)',
        'out-spring': 'cubic-bezier(0.19, 1, 0.22, 1.5)',
        'out-soft-spring': 'cubic-bezier(0.2, 1.4, 0.5, 0.98)',
        'in-out-eager': 'cubic-bezier(0.65, 0.05, 0.36, 1)',
      },
      keyframes: {
        appear: {
          from: { opacity: 0 },
          to: { opacity: 1 },
        },
        appearUp: {
          from: { transform: 'translateY(30px)', opacity: 0 },
          to: { transform: 'translateY(0)', opacity: 1 },
        },
        shake: {
          '0%': { transform: 'translate(2px, 1px)  rotate(0deg)' },
          '10%': { transform: 'translate(-1px, -2px) rotate(-2deg)' },
          '20%': { transform: 'translate(-3px, 0px)  rotate(3deg)' },
          '30%': { transform: 'translate(0px, 2px)   rotate(0deg)' },
          '40%': { transform: 'translate(1px, -1px)  rotate(1deg)' },
          '50%': { transform: 'translate(-1px, 2px)  rotate(-1deg)' },
          '60%': { transform: 'translate(-3px, 1px)  rotate(0deg)' },
          '70%': { transform: 'translate(2px, 1px)   rotate(-2deg)' },
          '80%': { transform: 'translate(-1px, -1px) rotate(4deg)' },
          '90%': { transform: 'translate(2px, 2px)   rotate(0deg)' },
          '100%': { transform: 'translate(1px, -2px)  rotate(-1deg)' },
        },
        fly: {
          '0%': { transform: 'translateY(1000px)' },
          '100%': { transform: 'translateY(0px)' },
        },
        cycle: {
          '0%': { transform: 'translateY(0px)' },
          '50%': { transform: 'translateY(100px)' },
          '100%': { transform: 'translateY(0px)' },
        },
        beat: {
          '0%': { transform: 'scale(1)' },
          '50%': { transform: 'scale(1)' },
          '100%': { transform: 'scale(1)' },
          '30%': { transform: 'scale(0.92, 0.95)' },
          '80%%': { transform: 'scale(0.92, 0.95)' },
        },
      },
      animation: {
        appear: 'appear 0.5s forwards',
        appearUp: 'appearUp 0.5s forwards',
        shake: 'shake 1s infinite',
        fly: 'fly 4s ease forwards',
        cycle: 'cycle 4s cubic-bezier(.4,0,.6,1) infinite 4s',
        beat: 'beat 1s ease 0s infinite',
      },
      screens: {
        xxs: '375px',
        // => @media (min-width: 375px) { ... }

        xs: '390px',
        // => @media (min-width: 390px) { ... }

        xsm: '414px',
        // => @media (min-width: 414px) { ... }

        sm: '640px',
        // => @media (min-width: 640px) { ... }

        md: '768px',
        // => @media (min-width: 768px) { ... }

        lg: '1024px',
        // => @media (min-width: 1024px) { ... }

        xl: '1280px',
        // => @media (min-width: 1280px) { ... }

        xxl: '1536px',
        // => @media (min-width: 1536px) { ... }
      },
      transitionDuration: {
        0: '0ms',
        400: '400ms',
      },
      maxWidth: {
        '1/2': '50%',
        '1/3': '33.3333%',
      },
      transitionProperty: {
        height: 'height',
        spacing: 'margin, padding',
      },
      zIndex: {
        1: '1',
        2: '2',
        3: '3',
        4: '4',
        5: '5',
        6: '6',
        7: '7',
        8: '8',
        9: '9',
      },
      scale: {
        101: '1.01',
        100.5: '1.005',
      },
      borderWidth: {
        1.5: '1.5px',
      },
      borderRadius: {
        '2lg': '0.625rem', // 10px
        '2.5xl': '1.25rem', // 20px
        '4xl': '1.75rem', // 28px
      },
    },
  },
  plugins: [require('@tailwindcss/aspect-ratio'), require('tailwind-scrollbar-hide')],
};
