import capitalize from 'lodash/capitalize';
import { ERROR_MESSAGES } from '@/constants/Strings';

const uncatchableErrors: {
  [key: string]: {
    error: string;
    title: string;
    description: string;
  };
} = {
  checkoutDiscountCodeApply: {
    error: 'Unable to apply this discount code.',
    title: 'Invalid Discount Code.',
    description: 'Unable to apply this discount code.',
  },
};

const getErrorMessageByStatus = (
  status: number,
  defaultMessage = 'Something went wrong. Try again later.'
): string => {
  switch (status) {
    case 400:
      return ERROR_MESSAGES.HTTP_BAD_REQUEST_MESSAGE;
    case 401:
      return ERROR_MESSAGES.HTTP_UNAUTHORIZED_MESSAGE;
    case 403:
      return ERROR_MESSAGES.HTTP_FORBIDDEN_MESSAGE;
    case 404:
      return ERROR_MESSAGES.HTTP_NOT_FOUND_MESSAGE;
    case 405:
      return ERROR_MESSAGES.HTTP_METHOD_NOT_ALLOWED_MESSAGE;
    case 406:
      return ERROR_MESSAGES.HTTP_NOT_ACCEPTABLE_MESSAGE;
    case 409:
      return ERROR_MESSAGES.HTTP_CONFLICT_MESSAGE;
    case 410:
      return ERROR_MESSAGES.HTTP_GONE_MESSAGE;
    case 429:
      return ERROR_MESSAGES.HTTP_TOO_MANY_REQUESTS_MESSAGE;
    case 500:
      return ERROR_MESSAGES.HTTP_INTERNAL_SERVER_ERROR_MESSAGE;
    case 502:
      return ERROR_MESSAGES.HTTP_BAD_GATEWAY_MESSAGE;
    case 503:
      return ERROR_MESSAGES.HTTP_SERVICE_UNAVAILABLE_MESSAGE;
    case 504:
      return ERROR_MESSAGES.HTTP_GATEWAY_TIMEOUT_MESSAGE;
    default:
      return defaultMessage;
  }
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const getErrorData = (action: any) => {
  const { baseQueryMeta, arg } = action.meta;
  const { message: error, data, errorMessage, errorDescription } = action.payload || {};

  const status: number = baseQueryMeta?.response?.status ?? 0;
  const resource: string = arg?.endpointName ?? '[unknown]';

  // uncatchable RTK errors coming from Shopify
  const uncatchableError = uncatchableErrors[resource];
  const isUncatchableError = uncatchableError && error && error.includes(uncatchableError.error);

  const title =
    errorMessage ||
    (isUncatchableError && uncatchableError.title) ||
    `"${resource}" didn't load properly.`;

  const description =
    errorDescription ||
    (isUncatchableError && uncatchableError.description) ||
    capitalize(data?.message) ||
    getErrorMessageByStatus(status);

  return {
    title,
    description,
    status,
    resource,
  };
};
