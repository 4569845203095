import { gql } from 'graphql-request';

export const checkoutCreateMutation = gql`
  mutation checkoutCreate($input: CheckoutCreateInput!) {
    checkoutCreate(input: $input) {
      checkout {
        id
        createdAt
        email
        lineItems(first: 2) {
          edges {
            node {
              quantity
              variant {
                id
              }
            }
          }
        }
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const checkoutDiscountCodeApplyMutation = gql`
  mutation checkoutDiscountCodeApplyV2($checkoutId: ID!, $discountCode: String!) {
    checkoutDiscountCodeApplyV2(checkoutId: $checkoutId, discountCode: $discountCode) {
      checkout {
        id
        createdAt
        discountApplications(first: 1) {
          edges {
            node {
              targetType
              targetSelection
              value {
                __typename
                ... on MoneyV2 {
                  amount
                }
                ... on PricingPercentageValue {
                  percentage
                }
              }
            }
          }
        }
        email
        lineItems(first: 2) {
          edges {
            node {
              quantity
              variant {
                id
              }
            }
          }
        }
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const checkoutDiscountCodeRemoveMutation = gql`
  mutation checkoutDiscountCodeRemove($checkoutId: ID!) {
    checkoutDiscountCodeRemove(checkoutId: $checkoutId) {
      checkout {
        id
        createdAt
        discountApplications(first: 1) {
          edges {
            node {
              targetType
              targetSelection
              value {
                __typename
                ... on MoneyV2 {
                  amount
                }
                ... on PricingPercentageValue {
                  percentage
                }
              }
            }
          }
        }
        email
        lineItems(first: 2) {
          edges {
            node {
              quantity
              variant {
                id
              }
            }
          }
        }
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const checkoutEmailUpdate = gql`
  mutation CheckoutEmailUpdate($checkoutId: ID!, $email: String!) {
    checkoutEmailUpdateV2(checkoutId: $checkoutId, email: $email) {
      checkout {
        id
        createdAt
        discountApplications(first: 1) {
          edges {
            node {
              targetType
              targetSelection
              value {
                __typename
                ... on MoneyV2 {
                  amount
                }
                ... on PricingPercentageValue {
                  percentage
                }
              }
            }
          }
        }
        email
        lineItems(first: 2) {
          edges {
            node {
              quantity
              variant {
                id
              }
            }
          }
        }
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const checkoutLineItemsReplace = gql`
  mutation checkoutLineItemsReplace($checkoutId: ID!, $lineItems: [CheckoutLineItemInput!]!) {
    checkoutLineItemsReplace(checkoutId: $checkoutId, lineItems: $lineItems) {
      checkout {
        id
        createdAt
        discountApplications(first: 1) {
          edges {
            node {
              targetType
              targetSelection
              value {
                __typename
                ... on MoneyV2 {
                  amount
                }
                ... on PricingPercentageValue {
                  percentage
                }
              }
            }
          }
        }
        email
        lineItems(first: 2) {
          edges {
            node {
              quantity
              variant {
                id
              }
            }
          }
        }
      }
      userErrors {
        code
        field
        message
      }
    }
  }
`;
