import { useEffect, useRef } from 'react';

/**
 * useKeyPress
 * @param {string} key - the name of the key to respond to, compared against event.key
 * @param {function} action - the action to perform on key press
 */
export const useKeypress = (key: string, action: () => void) => {
  const actionRef = useRef(action);
  actionRef.current = action;

  useEffect(() => {
    const onKeyup = (e: KeyboardEvent) => {
      if (e.key === key) {
        actionRef.current();
      }
    };

    window.addEventListener('keyup', onKeyup);
    return () => window.removeEventListener('keyup', onKeyup);
  }, [key]);
};
