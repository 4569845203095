import type { OrderDiscount } from '@/types/checkout';

export type SendingMethod = 'mail' | 'sms' | 'link';

export enum GiftOrderStatus {
  CLAIMED = 'CLAIMED',
  CANCELED = 'CANCELED',
  SENT = 'SENT',
  EXPIRED = 'EXPIRED',
  SCHEDULED = 'SCHEDULED',
  FAILED = 'FAILED',
}

export enum GiftShipmentStatus {
  ORDER_RECEIVED = 'ORDER_RECEIVED',
  PROCESSING = 'PROCESSING',
  IN_TRANSIT = 'IN_TRANSIT',
  OUT_FOR_DELIVERY = 'OUT_FOR_DELIVERY',
  DELIVERED = 'DELIVERED',
}

type GiftCollection = {
  _id: string;
  name: string;
  thumbnail: string;
};

export type GiftEstimatedFinancials = {
  serviceFee: number;
  shipping: number;
  tax: number;
  subtotalAmount: number;
  totalAmount: number;
};

export type GiftActualFinancials = {
  serviceFee: number;
  shipping: number;
  tax: number;
  subtotalAmount: number;
  totalAmount: number;
};

type GiftUser = {
  firstName: string;
  lastName: string;
  email?: string;
  phoneNum?: string;
};

type GiftReceiverUser = {
  firstname: string;
  lastname: string;
  email?: string;
  phoneNum?: string;
};

type ChosenProduct = {
  id: string;
  title: string;
  image: string;
};

export interface GetLinkResponse {
  link: string;
}

export interface PaginatedGiftDto<T = Gift> {
  gifts: T[];
  total: number;
}

export enum GiftDirection {
  SENT = 'sent',
  RECEIVED = 'received',
}

export type GiftTYNPayload = {
  giftId: string;
  thankYouNote: string;
  captchaToken: string | null;
};

export type TrackingInfo = {
  shipmentStatus: GiftShipmentStatus;
  number: string;
  link: string;
};

export enum GiftGreetingType {
  TEXT = 'text',
  VIDEO = 'video',
  ENVELOPE = 'envelope',
}

interface BaseGift {
  id: string;
  link: string;
  direction: GiftDirection;
  sender: GiftUser;
  recipient: GiftUser;
  status: GiftOrderStatus;
  collection: GiftCollection;
  product?: ChosenProduct;
  expiredAt?: number;
  createdAt?: number;
  thankYouNote?: string;
  greetingMessage?: string;
  greetingType?: GiftGreetingType;
  tracking?: TrackingInfo | null;
  claimedAt?: number;
}

export interface Gift extends BaseGift {
  budget: number;
  scheduledAt?: number;
  discount: OrderDiscount | null;
  receiver?: GiftReceiverUser;
  estimatedFinancials: GiftEstimatedFinancials;
  actualFinancials: GiftActualFinancials | null;
}

export interface ReceivedGift extends BaseGift {
  sendingCompany?: string;
}
