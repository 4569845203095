export enum DiscountType {
  FIXED = 'fixed_amount',
  PERCENTAGE = 'percentage',
}

export type Discount = {
  type: DiscountType;
  code: string;
  value: number;
};

export type OrderDiscount = {
  amount: number;
  code: string;
  type: DiscountType;
};
