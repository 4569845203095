import React from 'react';
import { useRouter } from 'next/router';
// TODO: replace with Typography
import { Subtitle } from '@/components/checkout/components/Subtitle';
import { Button } from '@/components/ui/Button/Button';
import { Typography } from '@/components/ui/Typography';
import { Colors } from '@/constants/Colors';
import { ParallaxBackground } from '@/components/ui/ParallaxBackground';
import signUpImg from 'public/assets/images/auth/sign-up.webp';

export interface ErrorBoundaryFallbackProps {
  error: Error;
}

export const ErrorBoundaryFallback: React.FC<ErrorBoundaryFallbackProps> = ({ error }) => {
  const router = useRouter();

  const refresh = async () => {
    // Redirect to home and reload the app
    await router.push('/home');
    window.location.reload();
  };

  return (
    <div className="error-fallback">
      <div className="error-fallback__container">
        <div className="error-fallback__bg">
          <ParallaxBackground src={signUpImg.src} />
        </div>

        <div className="error-fallback__alert-wrapper">
          <div className="error-fallback__alert-wrapper-inner">
            <Subtitle className="error-fallback__text" tag="h3">
              Something went wrong...
            </Subtitle>
            <div className="error-fallback__inner-wrapper">
              <Subtitle className="error-fallback__error-text" uppercase={false}>
                Error: {error?.message || '[unknown]'}
              </Subtitle>
            </div>
            <div className="error-fallback__inner-wrapper">
              <Button
                variant="primary"
                onClick={refresh}
                className="'error-fallback__refresh-button"
              >
                <Typography type="button-2" color={Colors.white}>
                  Refresh
                </Typography>
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* Styles */}

      <style jsx>
        {`
          .error-fallback {
            @apply w-full h-full flex flex-col flex-auto;
            @apply mt-0 bg-white;

            &__container {
              @apply relative flex flex-col items-center h-full w-full;
              @apply flex-auto;
              @apply p-4 overflow-hidden;
            }

            &__bg {
              @apply absolute inset-0 bg-[#D7F5FF];
            }

            &__alert-wrapper {
              @apply absolute top-[15%] w-full max-w-[410px];
              @apply py-10 px-4 rounded-2xl bg-white shadow-signIn;
            }

            &__alert-wrapper-inner {
              @apply relative flex flex-col items-center w-full max-w-[300px] m-auto;
            }

            &__inner-wrapper {
              @apply mt-6;
            }

            :global(.error-fallback__text) {
              @apply text-primary-900 align-middle font-inter font-medium;
            }

            :global(.error-fallback__text-error) {
              @apply text-primary-700 align-middle;
            }

            :global(.error-fallback__refresh-button) {
              @apply flex rounded-full h-[50px] items-center text-white justify-center;
            }
          }
        `}
      </style>
    </div>
  );
};
