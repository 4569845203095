import keys from 'lodash/keys';
import reduce from 'lodash/reduce';
import entries from 'lodash/entries';
import fromPairs from 'lodash/fromPairs';
import qs from 'query-string';
import { ToastTypeOptions } from '@/components/ui/Toast/types';
import { parseJSON } from '@/utils/formatters';
import { Colors } from '@/constants/Colors';

export class QueryParams {
  static parseOptions: qs.ParseOptions = {
    parseBooleans: true,
    parseNumbers: true,
  };

  static stringifyOptions: qs.StringifyOptions = {
    arrayFormat: 'index',
    skipNull: true,
  };

  static parse<T extends object>(
    url: string,
    arrayFormat:
      | 'bracket'
      | 'index'
      | 'comma'
      | 'separator'
      | 'bracket-separator'
      | 'colon-list-separator'
      | 'none'
      | undefined
  ): T {
    return qs.parse(url, { ...this.parseOptions, arrayFormat }) as T;
  }

  static stringify(query: object): string {
    return qs.stringify(query, this.stringifyOptions);
  }

  static parseIndexObj<T extends object>(query: object): T {
    return this.parse(this.stringify(query), 'index') as T;
  }

  static parseCommaObj<T extends object>(query: object): T {
    return this.parse(this.stringify(query), 'comma') as T;
  }
}

function getScrollbarWidth() {
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  document.body.removeChild(outer);

  return scrollbarWidth;
}

export const lockDocumentBodyScroll = (isLocked: boolean): void => {
  const LOCK_CLASS = 'lock-scroll';
  const scrollbarWidth = getScrollbarWidth();
  const isScrollbarVisible = document.body.clientHeight > window.innerHeight;
  const isDocumentLocked = document.body.classList.contains(LOCK_CLASS);

  const shouldAddFakeScrollbar = [
    scrollbarWidth,
    isScrollbarVisible,
    isLocked,
    !isDocumentLocked,
  ].every(Boolean);

  document.body.classList.toggle(LOCK_CLASS, isLocked);
  document.body.style.paddingRight = shouldAddFakeScrollbar ? `${scrollbarWidth}px` : '';
};

export const getQueryParamsWithMessageAndToastType = (
  message: string,
  toastType: ToastTypeOptions
) => `/home?message=${message}&toastType=${toastType}`;

export const getAllQueryParams = <T extends Record<string, string | number | undefined>>(
  defaultParams?: Partial<T>
): T | Record<string, string | number | undefined> => {
  if (typeof window === 'undefined') return {} as T;
  const searchParams = new URLSearchParams(window.location.search);
  const params = fromPairs([...searchParams.entries()]);
  const result = reduce(
    params,
    (acc, value, key) => {
      acc[key] = getQueryParam(key, defaultParams?.[key]);
      return acc;
    },
    {} as Record<string, string | number | undefined>
  );
  return { ...defaultParams, ...result };
};

export const getQueryParam = <T extends string | number | boolean | undefined>(
  param: string,
  defaultParam?: T
): T => {
  if (typeof window === 'undefined') return defaultParam as T;
  const searchParams = new URLSearchParams(window.location.search);
  const queryParam = searchParams.get(param) ?? undefined;
  const resultParam =
    queryParam !== undefined && parseJSON(queryParam) !== undefined
      ? parseJSON(queryParam)
      : queryParam;
  const result =
    defaultParam !== undefined && typeof resultParam !== typeof defaultParam
      ? defaultParam
      : resultParam;

  return (result ?? defaultParam) as T; // Couldn't find a better way to do without "as"
};

export const setQueryParams = (params: {
  [key: string]: string | number | boolean | null | undefined;
}): void => {
  const searchParams = new URLSearchParams(window.location.search);

  // eslint-disable-next-line no-restricted-syntax
  for (const key in params) {
    if (params[key] === undefined || params[key] === null) {
      searchParams.delete(key);
    } else {
      searchParams.set(key, `${params[key]}`);
    }
  }

  // eslint-disable-next-line no-restricted-globals
  history.replaceState(null, '', `${window.location.pathname}?${searchParams.toString()}`);
};

export const extractRouterQueryValue = (value: string | string[] | undefined) => {
  if (Array.isArray(value)) {
    return value[value.length - 1];
  }
  return value;
};

export const setStatusBarColor = (color: string = Colors.white): void => {
  const body = document.querySelector('body');
  const metaThemeColor = document.querySelector('meta[name=theme-color]');

  if (body) body.style.backgroundColor = color;
  metaThemeColor?.setAttribute('content', color);
};

export const getRGBFromHEX = (hexCode = ''): [number, number, number] | [] => {
  if (!hexCode) {
    return [];
  }

  let hex = hexCode.replace('#', '');

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return [r, g, b];
};

// Gist from: https://gist.github.com/danieliser/b4b24c9f772066bcf0a6
export const convertHexToRGBA = (hexCode = '', opacity = 1): string => {
  if (!hexCode) {
    return '';
  }

  const [r, g, b] = getRGBFromHEX(hexCode);

  /* Backward compatibility for whole number based opacity values. */
  if (opacity > 1 && opacity <= 100) {
    return `rgba(${r},${g},${b},${opacity / 100})`;
  }

  return `rgba(${r},${g},${b},${opacity})`;
};

export const immediateScrollTo = (elOrPosition: HTMLElement | number): void => {
  const position = typeof elOrPosition === 'number' ? elOrPosition : elOrPosition.offsetTop;
  // Fix for immediate scroll to filters after budget change
  document.documentElement.style.scrollBehavior = 'auto';
  window.scrollTo(0, position);
  document.documentElement.style.scrollBehavior = '';
};

/**
 * @description Scrolls to the element with smooth behavior
 * @param el {HTMLElement | null} - The element to scroll to
 * @returns void
 */
export const scrollToElement = (el: HTMLElement | null): void => {
  if (!el?.getBoundingClientRect) return;
  const top = el.getBoundingClientRect().top + window.scrollY;
  window.scrollTo({ top, behavior: 'smooth' });
};

/**
 * @description open a window with a given url and config
 * @param url {string} to open
 * @param config {object} to open the window with
 * @param onClose {function} to callback when the window is closed
 * @returns Window | null
 */
export const windowOpen = (
  url: string,
  { height, width, ...configRest }: { height: number; width: number; [key: string]: unknown },
  onClose?: (dialog: Window | null) => void
) => {
  const config: { [key: string]: string | number } = {
    height,
    width,
    location: 'no',
    toolbar: 'no',
    status: 'no',
    directories: 'no',
    menubar: 'no',
    scrollbars: 'yes',
    resizable: 'no',
    centerscreen: 'yes',
    chrome: 'yes',
    ...configRest,
  };

  const shareDialog = window.open(
    url,
    '',
    keys(config)
      .map((key) => `${key}=${config[key]}`)
      .join(', ')
  );

  if (onClose) {
    const interval = setInterval(() => {
      try {
        if (shareDialog === null || shareDialog.closed) {
          clearInterval(interval);
          onClose(shareDialog);
        }
      } catch (e) {
        /* eslint-disable no-console */
        console.error(e);
        /* eslint-enable no-console */
        clearInterval(interval);
      }
    }, 1000);
  }

  return shareDialog;
};

/**
 * @description receive and object and covert it into URI params
 * @param object {Object} with params to be converted to encoded URI
 * @returns string
 */

export const objectToGetParams = (object: {
  [key: string]: string | number | undefined | null;
}) => {
  const params = entries(object)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`);

  return params.length > 0 ? `?${params.join('&')}` : '';
};
