import React, { useEffect } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import '@/styles/globals.css';
import '@/styles/tailwind.css';
import '@/styles/toast.css';
import '@/styles/datepicker.css';
import '@/styles/animation.css';
import '@/styles/transitions.css';
import '@/styles/react-select.css';
import '@/styles/image-slider.css';
import type { AppProps } from 'next/app';
import Storages from 'js-storage';
import { Provider } from 'react-redux';
import NextNProgress from 'nextjs-progressbar';
import TagManager from 'react-gtm-module';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorBoundaryFallback } from '@/components/ui/ErrorBoundry/ErrorBoundryFallback';
import { errorBoundaryHandler } from '@/components/ui/ErrorBoundry/helpers';
import { NavBar } from '@/components/ui/NavBar';
import { ToastAppContainer } from '@/components/ui/Toast';
import { PageMeta, PageMetaProps } from '@/components/ui/PageMeta';
import { wrapper } from '@/redux/store';
import { AuthProvider } from '@/providers/AuthProvider';
import { UserFlagsProvider } from '@/providers/UserFlagsProvider';
import { CaptchaProvider } from '@/components/Captcha';
import { useToastFromQueryParams } from '@/hooks/useToastFromQueryParams';
import { useAppCrushHandle } from '@/hooks/useAppCrushHandle';

Storages.alwaysUseJsonInStorage(true);

export interface AppContainerProps {
  meta?: Omit<PageMetaProps, 'children'>;
}

const App = ({ Component, pageProps }: AppProps<AppContainerProps>) => {
  const { meta, ...otherPageProps } = pageProps;
  const { store, props } = wrapper.useWrappedStore({ pageProps: otherPageProps });

  useEffect(() => {
    TagManager.initialize({ gtmId: String(process.env.NEXT_PUBLIC_GTM_CONTAINER_ID) });
  }, []);

  useAppCrushHandle();
  useToastFromQueryParams();

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={errorBoundaryHandler}>
      <Provider store={store}>
        <PageMeta {...meta} />
        <AuthProvider>
          <CaptchaProvider>
            <UserFlagsProvider>
              <NextNProgress options={{ showSpinner: false }} />
              <section className="relative flex flex-col flex-auto">
                <NavBar />
                <Component {...props.pageProps} />
              </section>
              <ToastAppContainer />
            </UserFlagsProvider>
          </CaptchaProvider>
        </AuthProvider>
      </Provider>
    </ErrorBoundary>
  );
};

export default App;
