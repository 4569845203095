import React, { useEffect, useRef } from 'react';
import { useDeviceType } from '@/hooks/useDeviceType';

export const PARALLAX_BACKGROUND_TEST_ID = 'parallax-background';
export const PARALLAX_BACKGROUND_IMAGE_TEST_ID = 'parallax-background-image';

interface ParallaxBackgroundProps extends React.HTMLAttributes<HTMLDivElement> {
  src: string;
  shift?: number;
}

export const ParallaxBackground: React.FC<ParallaxBackgroundProps> = ({ src, shift = 40 }) => {
  const bgRef = useRef<HTMLDivElement>(null);
  const { isDesktop } = useDeviceType();

  useEffect(() => {
    function handleMouseMove(e: MouseEvent) {
      requestAnimationFrame(() => {
        if (!bgRef.current) return;

        const x = Math.round(shift * (e.clientX / window.innerWidth - 0.5)) / shift;
        const y = Math.round(shift * (e.clientY / window.innerHeight - 0.5)) / shift;
        const bgX = -x * shift - shift / 2;
        const bgY = -y * shift - shift / 2;

        bgRef.current.style.transform = `translate(${bgX}px, ${bgY}px)`;
      });
    }

    if (isDesktop && src) {
      window.addEventListener('mousemove', handleMouseMove);
    }

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isDesktop, src]);

  return (
    <section className="parallax-bg" data-testid={PARALLAX_BACKGROUND_TEST_ID}>
      <div
        ref={bgRef}
        className="parallax-bg__image"
        style={{
          top: -shift,
          left: -shift,
          right: -shift,
          bottom: -shift,
          backgroundImage: `url(${src})`,
        }}
        data-testid={PARALLAX_BACKGROUND_IMAGE_TEST_ID}
      />

      {/* Styles */}

      <style jsx>
        {`
          .parallax-bg {
            @apply absolute inset-0 overflow-hidden;

            &__image {
              @apply absolute bg-cover bg-no-repeat transition-all ease-out-spring duration-[2s];
            }
          }
        `}
      </style>
    </section>
  );
};
