import { gql } from 'graphql-request';

export const collectionsQuery = gql`
  query collectionsQuery {
    collections(first: 250) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          title
          descriptionHtml
          handle
          image {
            src
          }
          rank: metafield(namespace: "my_fields", key: "rank") {
            id
            value
          }
          alcoholic: metafield(namespace: "my_fields", key: "alcoholic") {
            id
            value
          }
          gridImage: metafield(namespace: "my_fields", key: "gridImage") {
            reference {
              ... on MediaImage {
                image {
                  originalSrc
                }
              }
            }
          }
        }
      }
    }
  }
`;
