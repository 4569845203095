import React from 'react';
import { User } from '@/types/user';

interface AvatarProps {
  size: number;
  userInfo: User;
}

export const Avatar: React.FC<AvatarProps> = ({ size, userInfo }) => {
  const userNameAbbreviation = `${userInfo.firstname?.[0] ?? ''}${userInfo.lastname?.[0] ?? ''}`;

  return (
    <div
      className="avatar"
      style={{
        fontSize: size,
        width: size,
        height: size,
      }}
    >
      <span className="avatar__text">{userNameAbbreviation}</span>

      {/* Styles */}

      <style jsx>
        {`
          .avatar {
            @apply flex items-center justify-center;
            @apply bg-purple-200 rounded-full;

            &__text {
              @apply text-purple-600 text-[0.4em];
              @apply font-dm-sans font-medium select-none;
            }
          }
        `}
      </style>
    </div>
  );
};
