import React, { useCallback } from 'react';
import clsx from 'clsx';
import { Button } from '@/components/ui/Button';
import { Icon } from '@/components/ui/Icons/Icon';
import { ToastProps } from './types';
import { ToastTypes } from './constants';

export const Toast: React.FC<ToastProps> = ({
  title,
  description,
  style,
  actions,
  type = ToastTypes.DEFAULT,
  onClose,
  closeOnClick,
  hasCloseButton,
}) => {
  const handleToastClick = useCallback(() => {
    if (closeOnClick) onClose?.();
  }, [closeOnClick, onClose]);

  return (
    <div
      role="button"
      className={clsx('toast__wrapper', {
        success: type === ToastTypes.SUCCESS,
        warning: type === ToastTypes.WARNING,
        info: type === ToastTypes.INFO,
        error: type === ToastTypes.ERROR,
      })}
      style={style}
      onClick={handleToastClick}
    >
      {hasCloseButton && (
        <div className="toast__close-button-wrapper">
          <Icon name="CloseIcon" size={14} color="white" />
        </div>
      )}
      <div className="toast__content">
        <div className="toast__content-wrapper">
          {title && <span className="toast__title">{title}</span>}

          <span className="toast__title">{description}</span>

          {!!actions?.length && (
            <div className="toast__actions">
              {actions?.map((action) => (
                <Button
                  key={action.label}
                  isSquare
                  variant={action.type}
                  onClick={() => {
                    action.onClick();
                    onClose?.();
                  }}
                >
                  {action.label}
                </Button>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Style */}
      <style jsx>
        {`
          .toast {
            &__close-button-wrapper {
              @apply top-2 right-2 absolute;
            }

            &__wrapper {
              @apply flex flex-col pt-4 pb-4 px-5 bg-dashboard border-solid border border-dashboard;
              &.success {
                @apply bg-system-success border-solid border border-system-success;
              }
              &.warning {
                @apply bg-system-warning border-solid border border-system-warning;
              }
              &.info {
                @apply bg-system-info border-solid border border-system-info;
              }
              &.error {
                @apply bg-system-error border-solid border border-system-error;
              }
            }

            &__content {
              @apply flex items-center flex-shrink flex-grow;
            }

            &__content-wrapper {
              @apply flex flex-col flex-shrink flex-grow justify-center;
            }

            &__actions {
              @apply flex gap-3 mt-1 mb-1 items-center;
            }

            &__title {
              @apply text-dashboard text-sm font-medium;
              &.success {
                @apply text-system-success;
              }
              &.warning {
                @apply text-system-warning;
              }
              &.info {
                @apply text-system-info;
              }
              &.error {
                @apply text-system-error;
              }
            }
          }
        `}
      </style>
    </div>
  );
};
