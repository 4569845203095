import { CategorizedCollection } from '@/types/entities/collection';
import reduce from 'lodash/reduce';
import concat from 'lodash/concat';
import { BUDGETS_TO_LOW_RANGE } from '@/constants/Budgets';

export function getBudgetMin(budget: number): number {
  if (!budget) return 0;
  return BUDGETS_TO_LOW_RANGE[budget] ?? Math.floor(budget ? budget - budget * 0.1 : 0);
}

export function groupCollectionsByCategories(collections?: CategorizedCollection[]) {
  return reduce(
    collections,
    (acc, collection) => {
      if (!collection.metafields?.category || !collection.metafields?.landingPage) {
        return acc;
      }

      acc[collection.metafields?.category] = concat(
        acc[collection.metafields?.category],
        collection
      ).filter(Boolean);

      return acc;
    },
    {} as { [key in Shopify.COLLECTION_CATEGORIES]: CategorizedCollection[] }
  );
}
