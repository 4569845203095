import summerEssentialImg from 'public/assets/images/navbar/summer-essential.webp';
import birthdaySnappyImg from 'public/assets/images/navbar/birthday-snappy.webp';
import { FeaturedCollection, GroupedCollection } from '@/types/user';

export const FEATURED_COLLECTIONS: Record<'left' | 'right', FeaturedCollection> = {
  left: {
    label: 'Summer\n Essentials',
    description:
      'Thrill everyone on your list this summer with the best hostess, birthday and “just because” gift collection.',
    title: 'Summer Essentials',
    handle: 'summer-living',
    src: summerEssentialImg.src,
  },
  right: {
    label: 'Birthday Gifts',
    description: 'Impress a loved one with a collection of our favorite gifts.',
    title: 'Birthday',
    handle: 'happy-birthday',
    src: birthdaySnappyImg.src,
  },
};

export const GROUPED_COLLECTIONS: GroupedCollection[] = [
  {
    title: 'By Occasion',
    icon: 'Calendar',
    collections: [
      {
        title: 'Birthday',
        handle: 'happy-birthday',
      },
      {
        title: 'New Baby',
        handle: 'baby',
      },
      {
        title: 'Thank You',
        handle: 'thank-you-1',
      },
      {
        title: 'Housewarming',
        handle: 'housewarming',
      },
      {
        title: 'Wedding',
        handle: 'tying-the-knot',
      },
      {
        title: 'Get Well',
        handle: 'get-well-new',
      },
      {
        title: 'Sympathy',
        handle: 'thinking-of-you-1',
      },
    ],
  },
  {
    title: 'By Interest',
    icon: 'Heart',
    collections: [
      {
        title: 'Home & Office',
        collections: [
          {
            title: 'Tech',
            handle: 'the-techie',
          },
          {
            title: 'Work From Anywhere',
            handle: 'work-from-home',
          },
          {
            title: 'Cooking',
            handle: 'the-chef',
          },
          {
            title: 'Home Improvement',
            handle: 'home-improvement',
          },
        ],
      },
      {
        title: 'Beauty & Wellness',
        collections: [
          {
            title: 'Beauty',
            handle: 'the-beauty-guru',
          },
          {
            title: 'Health & Wellness',
            handle: 'health-wellness-1',
          },
        ],
      },
      {
        title: 'Gourmet Food & Drinks',
        collections: [
          {
            title: 'Snacks & Sweets',
            handle: 'the-foodie',
          },
          {
            title: 'Coffee & Tea',
            handle: 'get-caffeinated',
          },
          {
            title: 'Vegan',
            handle: 'vegan',
          },
        ],
      },
      {
        title: 'Sports & Outdoors',
        collections: [
          {
            title: 'Outdoor',
            handle: 'outdoorsy-finds',
          },
          {
            title: 'Sports',
            handle: 'sports-fanatic',
          },
        ],
      },
      {
        title: 'Books & Puzzles',
        collections: [
          {
            title: 'Best Selling Books',
            handle: 'nyt-best-sellers-collection',
          },
          {
            title: 'Puzzles',
            handle: 'puzzle-gifts',
          },
        ],
      },
      {
        title: 'Social Impact',
        collections: [
          {
            title: 'Celebrating Diversity',
            handle: 'rising-voices',
          },
          {
            title: 'Sustainable',
            handle: 'live-sustainably',
          },
        ],
      },
      {
        title: 'Luxe Gifts',
        handle: 'luxe-gifts',
      },
      {
        title: 'New & Noteworthy',
        handle: 'top-picks',
      },
    ],
  },
  {
    title: 'By Recipient',
    icon: 'UserIcon',
    collections: [
      {
        title: 'For Her',
        handle: 'gifts-for-her',
      },
      {
        title: 'For Him',
        handle: 'gifts-for-him',
      },
      {
        title: 'For Kids',
        collections: [
          {
            title: 'Ages 3-5',
            handle: 'ages-3-5',
          },
          {
            title: 'Ages 6-8',
            handle: 'ages-6-8',
          },
          {
            title: 'Ages 9-11',
            handle: 'ages-9-11',
          },
        ],
      },
      {
        title: 'Teens',
        handle: 'preteens',
      },
      {
        title: 'College Students',
        handle: 'college-care-package',
      },
      {
        title: 'Coworkers',
        handle: 'gifts-for-your-boss',
      },
      {
        title: 'Teachers',
        handle: 'teacher-appreciation',
      },
      {
        title: 'Grandparents',
        handle: 'gifts-for-grandparents',
      },
      {
        title: 'Bridesmaids',
        handle: 'bridesmaids',
      },
    ],
  },
];
