import { gql } from 'graphql-request';
import { transformFieldName } from '@/utils/shopifyHelpers';
import { DEFAULT_PRODUCTS_AMOUNT } from '@/constants/Products';
import { Age, Gender } from '@/types/shopify/shopify-tags';

// the target general amount of product for all tags
export const DEFAULT_PRODUCTS_BY_TAGS = 44;
// the target amount for query to balance products among tags with lack of products
export const DEFAULT_PRODUCTS_BY_TAGS_QUERY_AMOUNT = DEFAULT_PRODUCTS_BY_TAGS * 3;

export const productsQuery = gql`
  query productQuery($collectionId: ID!, $min: Float, $max: Float, $first: Int = ${DEFAULT_PRODUCTS_AMOUNT}) {
    collection(id: $collectionId) {
      products(first: $first, filters: [{ available: true }, { price: { min: $min, max: $max } }]) {
        edges {
          node {
            id
            title
            descriptionHtml
            variants(first: 1) {
              edges {
                node {
                  requiresShipping
                }
              }
            }
            images(first: 4) {
              edges {
                node {
                  id
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const productsByCollectionHandleQuery = gql`
  query productQuery($handle: String!, $min: Float, $max: Float, $first: Int = ${DEFAULT_PRODUCTS_AMOUNT}) {
    collection(handle: $handle) {
      products(first: $first, filters: [{ available: true }, { price: { min: $min, max: $max } }]) {
        edges {
          node {
            id
            title
            images(first: 4) {
              edges {
                node {
                  id
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const productVariantsQuery = `query getProductVariants ($id: ID!, $first: Int = ${DEFAULT_PRODUCTS_AMOUNT}) {
  product(id: $id) {
    variants (first: $first) {
      edges {
        node {
          id
          price {
            amount
          }
          sku
        }
      }
    }
  }
}`;

const getProductFragmentByTag = (
  tag: string,
  min: number,
  max: number,
  first: number,
  gender: Gender | null,
  age: Age | null
) =>
  `${transformFieldName(
    tag
  ).encode()}: products(first: ${first}, query: "(tag:'${tag}') AND (tag:'AI') AND (variants.price:>${min}) AND (variants.price:<=${max})${
    age ? ` AND tag:'${age}'` : ''
  }${
    gender ? ` AND NOT tag:'${gender === Gender.HIM ? Gender.HER : Gender.HIM}'` : ''
  } AND published_status:published AND available_for_sale:true") {
  edges {
    node {
      id
      title
      descriptionHtml
      tags
      images(first: 4) {
        edges {
          node {
            id
            src
          }
        }
      }
    }
  }
}`;

export const createDynamicProductsByTagsQuery = (
  tags: string[],
  min: number,
  max: number,
  age: Age | null,
  gender: Gender | null,
  total = DEFAULT_PRODUCTS_BY_TAGS_QUERY_AMOUNT
) => gql`
  query productByTagsQuery {
    ${tags
      .map((tag) =>
        getProductFragmentByTag(tag, min, max, Math.floor(total / tags.length), gender, age)
      )
      .join(`\n`)}
  }
`;

export const createDynamicProductsByIdsQuery = (productIds: string[]) => gql`
query productsByIds {
  nodes(
    ids: ${JSON.stringify(productIds.map((id) => `gid://shopify/Product/${id}`))}
  ) {
    ... on Product {
      id
      title
      descriptionHtml
      tags
      images(first: 4) {
        edges {
          node {
            id
            url
          }
        }
      }
    }
  }
}
`;
