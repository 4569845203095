import { useEffect, useMemo, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { SSR } from '@/utils/ssr';

interface Size {
  w: number;
  h: number;
}

type UseElementSizeProp = {
  multiplier: number;
};

/**
 * @description Hook to get the size of an element considering resizing
 * @returns {RefObject<HTMLElement>} ref - Ref to attach to the element
 * @returns {Size} size - Size of the element
 */
export const useElementSize = <T extends HTMLElement = HTMLDivElement>(
  options?: UseElementSizeProp
): {
  ref: (el: T) => void;
  size: Size;
} => {
  const { multiplier = 1 } = options || {};

  const [ref, setRef] = useState<T | null>(null);

  const [size, setSize] = useState<Size>({ w: 0, h: 0 });
  const multipliedSize = useMemo<Size>(
    () => ({ w: size.w * multiplier, h: size.h * multiplier }),
    [size, multiplier]
  );

  useEffect(() => {
    // Ensure the element exists, and observe it
    if (!ref || SSR) return undefined;

    const updateSize = () => {
      setSize({
        w: ref.offsetWidth || 0,
        h: ref.offsetHeight || 0,
      });
    };

    const observer = new ResizeObserver(updateSize);

    observer.observe(ref);

    // Cleanup by disconnecting the observer
    return () => observer.disconnect();
  }, [ref]);

  return { ref: setRef, size: multipliedSize };
};
