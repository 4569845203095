import clsx from 'clsx';
import React from 'react';
import { Colors } from '@/constants/Colors';
import { Icon } from '@/components/ui/Icons/Icon';

interface ChipProps {
  children: React.ReactNode;
  isSelected?: boolean;
  isDisabled?: boolean;
  withCloseIcon?: boolean;
  testId?: string;
  onClick?: () => void;
  bgColor?: string;
}

export const Chip: React.FC<ChipProps> = ({
  children,
  isSelected,
  isDisabled,
  testId,
  bgColor,
  withCloseIcon = false,
  onClick,
}) => (
  <button
    data-testid={testId}
    type="button"
    className={clsx('chip__wrapper', {
      isSelected,
      isClickable: onClick,
      isDisabled,
    })}
    style={{ backgroundColor: bgColor }}
    disabled={isDisabled}
    onClick={onClick}
    tabIndex={isDisabled || !onClick ? -1 : 0}
  >
    <div className={clsx('chip__value', { isSelected, isDisabled })}>{children}</div>

    {withCloseIcon && (
      <div className="chip__close-icon">
        <Icon name="CloseIcon" size={16} color={Colors.indigo['800']} />
      </div>
    )}

    {/* Styles */}

    <style jsx>
      {`
        .chip {
          &__wrapper {
            @apply flex items-center py-[9px] px-4 rounded-full bg-primary-100 w-[max-content];
            @apply border-solid border border-indigo-400 z-1 outline-none;

            &:hover,
            &:focus-visible {
              @apply text-primary-900 border-primary-600;

              .chip__value {
                @apply text-primary-900;
              }
            }

            &.isSelected {
              @apply bg-indigo-200 border-transparent;
              animation: bounceX 0.3s ease-out;
            }

            &.isClickable {
              @apply cursor-pointer;
            }

            &.isDisabled {
              @apply bg-primary-100 border-primary-100 pointer-events-none;
            }
          }

          &__value {
            @apply text-primary-800 text-base font-dm-sans font-medium my-0;

            &.isDisabled {
              @apply text-primary-500;
            }
          }

          &__close-icon {
            @apply -mr-1;
          }

          @keyframes bounceX {
            0% {
              transform: scaleX(1);
            }
            50% {
              transform: scaleX(1.05);
            }
            100% {
              transform: scaleX(1);
            }
          }
        }
      `}
    </style>
  </button>
);
