export const enum ImpactMethods {
  identify = 'identify',
  track = 'trackConversion',
}

interface ImpactParams {
  customerId: string;
  orderId?: string;
}

interface ImpactAnalytics {
  (method: ImpactMethods, actionTrackerId: number | null, params: ImpactParams): void;
}

export default ImpactAnalytics;
