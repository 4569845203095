import { Colors } from '@/constants/Colors';

export const AI_GENERATE_COLLECTION_PLACEHOLDERS = [
  'Tech gifts for my 30-year old brother',
  'Show me housewarming gifts for my friend',
  'Birthday gifts for my teenage nephew who plays soccer',
  'Perfect gifts for my wife who loves to cook',
  'Show me self care gifts for a new mom',
];

export const AI_GENERATE_COLLECTION_TAGS = [
  'Tech',
  'Home & Kitchen',
  'Beauty',
  'Wellness',
  'Sports & Outdoors',
  'Garden',
  'Food & Beverage',
  'Birthday',
  'New Baby',
  'Wedding',
  'Pets',
];

export const AI_GENERATE_COLLECTION_TAG_COLORS = [
  '#FFF6F6',
  Colors.indigo[200],
  Colors.snappy[200],
];
