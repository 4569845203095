import {
  signIn as amplifySignIn,
  confirmSignIn,
  signOut as amplifySignOut,
  fetchUserAttributes,
  fetchAuthSession,
  FetchUserAttributesOutput,
} from 'aws-amplify/auth';
import { User } from '@/types/user';
import { postData } from '@/services/ApiConnectors';
import { SSR } from '@/utils/ssr';

interface CreateUserPayload {
  email: string;
  firstName: string;
  lastName: string;
  captchaToken: string;
}

export type CognitoUser = FetchUserAttributesOutput & {
  'custom:first_name': string;
  'custom:last_name': string;
};

export class AuthService {
  private static GUEST_TOKEN_KEY = 'guestToken';

  static isGuestMode(): boolean {
    return !SSR && Boolean(sessionStorage.getItem(this.GUEST_TOKEN_KEY));
  }

  static setGuestToken(token: string): void {
    if (!SSR) sessionStorage.setItem(this.GUEST_TOKEN_KEY, token);
  }

  static clearGuestToken(): void {
    if (!SSR) sessionStorage.removeItem(this.GUEST_TOKEN_KEY);
  }

  static async getAccessToken(): Promise<string | undefined> {
    const guestToken = (!SSR && sessionStorage.getItem(this.GUEST_TOKEN_KEY)) || undefined;
    if (guestToken) return guestToken;
    const { tokens } = await fetchAuthSession({ forceRefresh: true });
    return tokens?.accessToken?.toString();
  }

  static async signUp(user: CreateUserPayload): Promise<void> {
    this.clearGuestToken();
    await postData('/users', user);
  }

  static async signIn(email: string): Promise<void> {
    this.clearGuestToken();
    await amplifySignIn({
      username: email,
      options: {
        authFlowType: 'CUSTOM_WITHOUT_SRP',
      },
    });
  }

  static async validateSignInEmailCode(verificationCode: string): Promise<void> {
    const response = await confirmSignIn({ challengeResponse: verificationCode });
    if (!response.isSignedIn) throw new Error('Invalid verification code');
  }

  static async signOut(): Promise<void> {
    await amplifySignOut();
  }

  static async getUser(): Promise<User | null> {
    const cognitoUser = await fetchUserAttributes();
    return this.claimsToUser(cognitoUser);
  }

  static claimsToUser(cognitoUser: FetchUserAttributesOutput): User {
    const id = cognitoUser.sub || '';
    const email = cognitoUser?.email ?? '';
    const firstname = cognitoUser?.['custom:first_name'] ?? 'unknown';
    const lastname = cognitoUser?.['custom:last_name'] ?? '';

    return { id, email, firstname, lastname };
  }

  static async refresh(): Promise<void> {
    await fetchAuthSession({ forceRefresh: true });
  }
}
