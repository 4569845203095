/* eslint react/jsx-no-useless-fragment: 0 */
import { Amplify } from 'aws-amplify';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks/useStore';
import { AuthService } from '@/services/AuthService';
import { KlaviyoService } from '@/services/KlaviyoService';
import { interceptor } from '@/services/Interceptors';
import { selectAuth, setAuth } from '@/redux';
import { ImpactService } from '@/services/ImpactService';
import { AnalyticsService } from '@/services/AnalyticsService';

interface AuthProviderProps {
  children: React.ReactNode;
}

interceptor();

Amplify.configure(
  {
    Auth: {
      Cognito: {
        userPoolClientId: process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID!,
        userPoolId: process.env.NEXT_PUBLIC_COGNITO_POOL_ID!,
      },
    },
  },
  { ssr: true }
);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(selectAuth);

  // Auth state -> Redux
  // Analytics
  useEffect(() => {
    const getUserData = async () => {
      try {
        const data = await AuthService.getUser();
        dispatch(
          setAuth({
            user: data,
            loading: false,
          })
        );
      } catch (error) {
        // In case token is invalid or guest user
        dispatch(
          setAuth({
            user: null,
            loading: false,
          })
        );
      }
    };
    getUserData();
  }, []);

  useEffect(() => {
    if (user) {
      AnalyticsService.identify(user);
      KlaviyoService.identify(user.email);
      ImpactService.identify(user.id);
    }
  }, [user]);

  return <>{children}</>;
};
