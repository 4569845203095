import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Colors } from '@/constants/Colors';
import dynamic from 'next/dynamic';
import { ICON_SIZES } from './constants';
import { IconProps } from './types';
import { getFillColorForVariation, getStylesForDirection } from './helpers';

const ICONS = ({ size }: { size: IconProps['size'] }) => {
  const ssr = false;
  const loading = () => (
    <Skeleton style={{ width: size, height: size }} baseColor={Colors.bgGray} circle />
  );

  return {
    ArrowRightReversalIcon: dynamic(() => import('./icons/ArrowRightReversalIcon'), {
      ssr,
      loading,
    }),
    CashIcon: dynamic(() => import('./icons/CashIcon'), { ssr, loading }),
    ChevronIcon: dynamic(() => import('./icons/ChevronIcon'), { ssr, loading }),
    ChevronTriangleIcon: dynamic(() => import('./icons/ChevronTriangleIcon'), { ssr, loading }),
    ClickIcon: dynamic(() => import('./icons/ClickIcon'), { ssr, loading }),
    CloseIcon: dynamic(() => import('./icons/CloseIcon'), { ssr, loading }),
    CopyIcon: dynamic(() => import('./icons/CopyIcon'), { ssr, loading }),
    DrinkIcon: dynamic(() => import('./icons/DrinkIcon'), { ssr, loading }),
    ExternalLinkIcon: dynamic(() => import('./icons/ExternalLinkIcon'), { ssr, loading }),
    FacebookIcon: dynamic(() => import('./icons/FacebookIcon'), { ssr, loading }),
    GiftBoxIcon: dynamic(() => import('./icons/GiftBoxIcon'), { ssr, loading }),
    GoogleIcon: dynamic(() => import('./icons/GoogleIcon'), { ssr, loading }),
    InfoIcon: dynamic(() => import('./icons/InfoIcon'), { ssr, loading }),
    InfoIconFilled: dynamic(() => import('./icons/InfoIconFilled'), { ssr, loading }),
    LinkedinIcon: dynamic(() => import('./icons/LinkedinIcon'), { ssr, loading }),
    LinkIcon: dynamic(() => import('./icons/LinkIcon'), { ssr, loading }),
    MessagesIcon: dynamic(() => import('./icons/MessagesIcon'), { ssr, loading }),
    PriceTagIcon: dynamic(() => import('./icons/PriceTagIcon'), { ssr, loading }),
    TooltipTriangleIcon: dynamic(() => import('./icons/TooltipTriangleIcon'), { ssr, loading }),
    TruckIcon: dynamic(() => import('./icons/TruckIcon'), { ssr, loading }),

    // Unicons

    Calendar: dynamic(() => import('@iconscout/react-unicons/icons/uil-calender'), {
      ssr,
      loading,
    }),
    CheckIcon: dynamic(() => import('@iconscout/react-unicons/icons/uil-check'), { ssr, loading }),
    CheckCircleIcon: dynamic(() => import('@iconscout/react-unicons/icons/uil-check-circle'), {
      ssr,
      loading,
    }),
    ClockThree: dynamic(() => import('@iconscout/react-unicons/icons/uil-clock-three'), {
      ssr,
      loading,
    }),
    CreditCardIcon: dynamic(() => import('@iconscout/react-unicons/icons/uil-credit-card'), {
      ssr,
      loading,
    }),
    EditIcon: dynamic(() => import('@iconscout/react-unicons/icons/uil-pen'), { ssr, loading }),
    EnvelopeIcon: dynamic(() => import('@iconscout/react-unicons/icons/uil-envelope-alt'), {
      ssr,
      loading,
    }),
    Eye: dynamic(() => import('@iconscout/react-unicons/icons/uil-eye'), { ssr, loading }),
    EyeSlash: dynamic(() => import('@iconscout/react-unicons/icons/uil-eye-slash'), {
      ssr,
      loading,
    }),
    GiftIcon: dynamic(() => import('@iconscout/react-unicons/icons/uil-gift'), { ssr, loading }),
    Heart: dynamic(() => import('@iconscout/react-unicons/icons/uil-heart'), { ssr, loading }),
    HomeIcon: dynamic(() => import('@iconscout/react-unicons/icons/uil-estate'), { ssr, loading }),
    MinusIcon: dynamic(() => import('@iconscout/react-unicons/icons/uil-minus-circle'), {
      ssr,
      loading,
    }),
    MobileIcon: dynamic(() => import('@iconscout/react-unicons/icons/uil-mobile-android'), {
      ssr,
      loading,
    }),
    PlayCircle: dynamic(() => import('@iconscout/react-unicons/icons/uil-play-circle'), {
      ssr,
      loading,
    }),
    PlusIcon: dynamic(() => import('@iconscout/react-unicons/icons/uil-plus-circle'), {
      ssr,
      loading,
    }),
    QuestionIcon: dynamic(() => import('@iconscout/react-unicons/icons/uil-question-circle'), {
      ssr,
      loading,
    }),
    SearchIcon: dynamic(() => import('@iconscout/react-unicons/icons/uil-search'), {
      ssr,
      loading,
    }),
    ShareAltIcon: dynamic(() => import('@iconscout/react-unicons/icons/uil-share-alt'), {
      ssr,
      loading,
    }),
    SignOutIcon: dynamic(() => import('@iconscout/react-unicons/icons/uil-signout'), {
      ssr,
      loading,
    }),
    ThickArrow: dynamic(() => import('@iconscout/react-unicons/icons/uil-arrow-left'), {
      ssr,
      loading,
    }),
    UserIcon: dynamic(() => import('@iconscout/react-unicons/icons/uil-user'), { ssr, loading }),
  } as const;
};

export type IconName = keyof ReturnType<typeof ICONS>;

interface LazyIconProps extends IconProps {
  name: IconName;
  className?: string;
}

export const Icon: React.FC<LazyIconProps> = React.memo(
  ({ color, direction, name, size = ICON_SIZES.md, style, variation = 'default', ...props }) => {
    const IconComponent = ICONS({ size })[name] ?? null;
    const fillColor = color ?? getFillColorForVariation(variation);
    const svgStyle = {
      flex: 'none',
      ...style,
      ...getStylesForDirection(direction),
    };

    return (
      !!IconComponent && <IconComponent color={fillColor} size={size} style={svgStyle} {...props} />
    );
  }
);
