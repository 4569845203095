import uniq from 'lodash/uniq';
import find from 'lodash/find';
import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from '@/redux/store';
import {
  selectActiveCollectionId,
  selectActivePersona,
} from '@/redux/slices/activeState/activeState';
import { shopifyApi } from '@/redux/api/shopify';
import { Collection } from '@/types/entities/collection';
import { ALL_TAG } from '@/components/homepage-v1/components/PersonasChips';
import { GENERATED_COLLECTION } from '@/constants/Collections';
import { filterOutCollectionsByPersonaIds, sortCollectionsByRank } from '../utils';
import { selectPersonasResultFromAPI } from './personas';

const NUM_OF_SELECTED_PERSONA_VALUES = 1;

const selectCollectionsResultFromAPI = shopifyApi.endpoints.collections.select();
const selectCollectionByIdResultFromAPI = (id: string) =>
  shopifyApi.endpoints?.collectionById.select(id);
const selectCollectionByHandleResultFromAPI = (handle: string) =>
  shopifyApi.endpoints.collectionByHandle.select(handle);

export const selectActiveFilters = createSelector(
  [selectPersonasResultFromAPI, selectActivePersona],
  (personasResult, activePersona) => {
    const { data: personas, isSuccess } = personasResult;
    if (isSuccess) {
      if (activePersona === ALL_TAG) {
        return personas;
      }
      return personas.filter((persona) => activePersona === persona.persona);
    }
    return [];
  }
);

export const selectSortedCollections = createSelector(
  [selectCollectionsResultFromAPI, selectActiveFilters],
  (allCollections, filters): Collection[] => {
    if (allCollections.data) {
      const sortedByRank = sortCollectionsByRank(allCollections.data);
      if (filters.length) {
        if (filters.length > NUM_OF_SELECTED_PERSONA_VALUES) {
          return sortedByRank;
        }
        const personaCollectionIds = filters[0]?.collectionIds;
        return filterOutCollectionsByPersonaIds(sortedByRank, personaCollectionIds);
      }
      return sortedByRank;
    }
    return [];
  }
);

export const selectCollectionById = (collectionId: string | undefined) =>
  createSelector(
    (state: RootState) => state,
    (state): Collection | null => getCollectionById(collectionId, state)
  );

export const selectCollectionByHandle = (handle: string) =>
  createSelector(
    [selectCollectionByHandleResultFromAPI(handle)],
    (collectionResult): Collection | null => {
      if (!handle) {
        return null;
      }
      return collectionResult.data ?? null;
    }
  );

export const selectCollectionsByHandles = (handles: string[]) =>
  createSelector(
    handles.map((handle) => selectCollectionByHandle(handle)),
    (...collections) => collections.filter(Boolean) as Collection[]
  );

export const selectActiveCollection = createSelector(
  selectActiveCollectionId,
  (state: RootState) => state,
  (activeCollectionId, state): Collection | null => getCollectionById(activeCollectionId, state)
);

export const selectActiveCollectionIds = createSelector(
  [selectActiveFilters],
  (seletcedFilters): string[] => uniq(seletcedFilters.flatMap((filter) => filter.collectionIds))
);

// Helpers

function getCollectionById(id: string | undefined, state: RootState): Collection | null {
  if (!id) {
    return null;
  }

  if (id === GENERATED_COLLECTION.id) {
    return GENERATED_COLLECTION;
  }

  const collections = selectCollectionsResultFromAPI(state).data;

  const collectionById = selectCollectionByIdResultFromAPI(id)(state).data;
  const collectionFromAll = find(collections, { id });

  return collectionById ?? collectionFromAll ?? null;
}
