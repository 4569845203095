import { AuthService } from '@/services/AuthService/AuthService';
import { setAuth } from '@/redux';
import { useAppDispatch } from './useStore';

export const useSignOut = (): (() => Promise<void>) => {
  const dispatch = useAppDispatch();

  return async () => {
    await AuthService.signOut();
    dispatch(
      setAuth({
        user: null,
        loading: false,
      })
    );
    window.location.href = '/home';
  };
};
