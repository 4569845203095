export const enum KlaviyoMethods {
  identify = 'identify',
  track = 'track',
}

export const enum KlaviyoEvents {
  viewedProduct = 'Viewed Product',
  viewedCollection = 'Viewed Collection',
  subscribe = 'Subscribe',
}

export type KlaviyoIdentifyProps = {
  $email: string;
  $consent: string[];
};

export type KlaviyoViewedProductProps = {
  productId: string;
  productName: string;
  productDescription: string | undefined;
  imageUrl: string | undefined;
};

export type KlaviyoViewedCollectionProps = {
  collectionId: string;
  collectionName: string;
  imageUrl: string | undefined;
  collectionDescription: string;
  alcoholic: boolean;
  handle: string;
};

export type KlaviyoSubscribeProps = {
  page: string;
};

type KlaviyoIdentify = [KlaviyoMethods.identify, KlaviyoIdentifyProps];
type KlaviyoTrack = [
  KlaviyoMethods.track,
  KlaviyoEvents,
  KlaviyoViewedProductProps | KlaviyoViewedCollectionProps | KlaviyoSubscribeProps,
];

type ExtractParamsByMethodName<O, M> = O extends { method: M } ? O : never;

type Option<Method, Params> = {
  method: Method;
  params: Params;
};

type Options =
  | Option<KlaviyoEvents.viewedProduct, KlaviyoViewedProductProps>
  | Option<KlaviyoEvents.viewedCollection, KlaviyoViewedCollectionProps>
  | Option<KlaviyoEvents.subscribe, KlaviyoSubscribeProps>;

export type ParamsByMethod<M> = ExtractParamsByMethodName<Options, M>['params'];

interface KlaviyoAnalytics {
  push(analytic: KlaviyoIdentify | KlaviyoTrack): void;
}

export default KlaviyoAnalytics;
