import React from 'react';

type UsePressEnterHandler = (
  fn?: (e: React.KeyboardEvent) => void
) => (e: React.KeyboardEvent) => void;

export const usePressEnterHandler: UsePressEnterHandler = (fn) => (e: React.KeyboardEvent) => {
  if (e.key === 'Enter') {
    fn?.(e);
  }
};
