import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { Icon } from '@/components/ui/Icons/Icon';

interface CloseButtonProps {
  onClick?: () => void;
  className?: string;
  testId?: string;
}

export const CloseButton = forwardRef<HTMLButtonElement, CloseButtonProps>(
  ({ onClick, className, testId }, ref) => (
    <button
      ref={ref}
      data-testid={testId}
      type="button"
      aria-label="close button"
      className={clsx('close-btn', className)}
      onClick={onClick}
    >
      <Icon name="CloseIcon" size={20} />

      {/* Styles */}

      <style jsx>
        {`
          .close-btn {
            @apply bg-primary-200 lg:bg-primary-100 rounded-3xl hover:bg-indigo-100 cursor-pointer;
            @apply p-3 transition-all duration-300 ease-in-out;
          }
        `}
      </style>
    </button>
  )
);
