import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { AuthService } from '@/services/AuthService';
import { ExperienceBanner } from '@/types/entities/experience-banners';
import {
  GetLinkResponse,
  Gift,
  GiftTYNPayload,
  PaginatedGiftDto,
  ReceivedGift,
} from '@/types/entities/gift';
import { CreateGiftOrderPayload, CreateUpdateGiftOrderResponse } from '@/types/entities/gift-order';
import { catchAndNotify, transformErrorResponse } from '@/utils/errorHandlers';
import { Subscription, SubscriptionRequestPayload } from '@/types/entities/subscriptions';
import {
  AIProductTagsPayload,
  AIProductTagsResponse,
  AIQueryPayload,
  AIQueryResponse,
} from '@/types/entities/ai';
import { KEEP_UNUSED_RTK_QUERY_DATA_FOR } from '@/constants/Values';

// Constants

const GIFTS_API = '/gifts';
const GIFTS_PREVIEW_API = '/preview-gift';
const EXPERIENCE_API = '/experiences';
const GIFT_LINK_API = '/gift-link';
const AI_TAGS_API = '/products/ai-tags/search';
const AI_QUERY_API = '/integrations/openai/query';

export const consumerApi = createApi({
  reducerPath: 'consumerApi',
  keepUnusedDataFor: KEEP_UNUSED_RTK_QUERY_DATA_FOR,
  baseQuery: retry(
    fetchBaseQuery({
      baseUrl: `${process.env.NEXT_PUBLIC_API_BASE_URL}`,
      prepareHeaders: async (headers) => {
        const authToken = await AuthService.getAccessToken();
        if (authToken) {
          headers.set('authorization', `Bearer ${authToken}`);
          headers.set('content-type', 'application/json');
          headers.set('accept', 'application/json');
        }
        return headers;
      },
    }),
    {
      maxRetries: 3,
    }
  ),
  tagTypes: ['Gift', 'ReceivedGift'],
  endpoints: (builder) => ({
    // Experiences API
    experiences: builder.query<ExperienceBanner[], void>({
      query: () => EXPERIENCE_API,
    }),
    // Gifts API
    applyDiscountToGift: builder.mutation<
      CreateUpdateGiftOrderResponse,
      {
        shopifyCheckoutCreatedAt: string;
        code: string | null;
        orderId: string;
        senderEmail: string;
      }
    >({
      query: ({ code, shopifyCheckoutCreatedAt, orderId, senderEmail }) => ({
        url: `${GIFTS_API}/coupon`,
        method: 'PUT',
        body: {
          code,
          shopifyCheckoutCreatedAt,
          senderEmail,
          orderId,
        },
      }),
      invalidatesTags: ['Gift'],
    }),
    gifts: builder.query<Gift[], void>({
      query: () => `${GIFTS_API}?schema=v2&limit=1000&skip=0`,
      providesTags: ['Gift'],
      transformResponse: catchAndNotify({
        fn: (data: PaginatedGiftDto) => data.gifts,
        errorMessage: '"Gifts" didn\'t load properly.',
      }),
    }),
    receivedGifts: builder.query<ReceivedGift[], void>({
      query: () => `${GIFTS_API}?schema=v2&direction=received`,
      providesTags: ['ReceivedGift'],
      transformResponse: catchAndNotify({
        fn: (data: PaginatedGiftDto<ReceivedGift>) => data.gifts,
        errorMessage: '"Gifts" didn\'t load properly.',
      }),
    }),
    createGift: builder.mutation<CreateUpdateGiftOrderResponse, CreateGiftOrderPayload>({
      query: (body) => ({
        url: GIFTS_API,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Gift'],
    }),
    cancelGift: builder.mutation({
      query: (id) => ({
        url: `${GIFTS_API}/${id}`,
        method: 'DELETE',
      }),
      transformErrorResponse: transformErrorResponse({
        errorMessage: 'Gift was not canceled',
        errorDescription: 'Something went wrong. Please try again later.',
      }),
      invalidatesTags: ['Gift'],
    }),
    // Gift Preview API
    giftPreviewLink: builder.mutation({
      query: (data) => ({
        url: GIFTS_PREVIEW_API,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Gift'],
    }),
    // Get Gift Link API
    giftLink: builder.query<GetLinkResponse, string | undefined>({
      query: (paymentIntentId) => `${GIFT_LINK_API}?paymentIntentId=${paymentIntentId}`,
    }),
    // Subscriptions API
    subscribeToMarketing: builder.mutation<Subscription, SubscriptionRequestPayload>({
      query: (payload) => ({
        url: '/subscriptions',
        method: 'POST',
        body: payload,
      }),
    }),
    // Get product tags from GPT prompt
    getTagsFromGPT: builder.query<AIProductTagsResponse, AIProductTagsPayload>({
      query: ({ prompt, captchaToken }) => ({
        url: AI_TAGS_API,
        method: 'POST',
        body: { prompt, captchaToken },
      }),
      transformErrorResponse: transformErrorResponse({ shouldThrow: false }),
    }),
    getGPTQuery: builder.query<AIQueryResponse, AIQueryPayload>({
      query: ({ id }) => ({
        url: `${AI_QUERY_API}/${id}`,
        method: 'GET',
      }),
      transformErrorResponse: transformErrorResponse({ shouldThrow: false }),
    }),
    // Send thank you note of a gift
    sendGiftTYN: builder.mutation<void, GiftTYNPayload>({
      query: ({ giftId, thankYouNote, captchaToken }) => ({
        url: `${GIFTS_API}/tyn`,
        method: 'POST',
        body: { giftId, thankYouNote, captchaToken },
        responseHandler: 'content-type',
      }),
      transformErrorResponse: transformErrorResponse({
        errorMessage: 'Failed to send thank you note',
      }),
      async onQueryStarted({ giftId, thankYouNote }, { dispatch, queryFulfilled }) {
        const performOptimisticUpdate = (queryName: 'gifts' | 'receivedGifts') => {
          let previousNote;
          dispatch(
            consumerApi.util.updateQueryData(queryName, undefined, (data) => {
              if (!data) return data;
              // Here we're casting data to Gift[] | ReceivedGift[]
              const gifts = data as Array<Gift | ReceivedGift>;
              const gift = gifts.find((g) => g.id === giftId);
              if (gift) {
                // Store the previous thankYouNote to restore it in case of an error
                previousNote = gift.thankYouNote;
                // Update the thankYouNote field of the gift
                gift.thankYouNote = thankYouNote;
              }
              return gifts;
            })
          );
          return previousNote;
        };

        const rollbackUpdate = (
          queryName: 'gifts' | 'receivedGifts',
          previousNote: string | undefined
        ) => {
          dispatch(
            consumerApi.util.updateQueryData(queryName, undefined, (data) => {
              if (!data) return data;
              // Here we're casting data to Gift[] | ReceivedGift[]
              const gifts = data as Array<Gift | ReceivedGift>;
              const gift = gifts.find((g) => g.id === giftId);
              if (gift) {
                gift.thankYouNote = previousNote;
              }
              return gifts;
            })
          );
        };

        // Optimistic update
        const previousNoteGifts = performOptimisticUpdate('gifts');
        const previousNoteReceivedGifts = performOptimisticUpdate('receivedGifts');

        try {
          // Await for the mutation to complete
          await queryFulfilled;
        } catch (err) {
          // If the mutation fails, revert the thankYouNote field to its previous value
          rollbackUpdate('gifts', previousNoteGifts);
          rollbackUpdate('receivedGifts', previousNoteReceivedGifts);
        }
      },
    }),
  }),
});

export const {
  useExperiencesQuery,
  useApplyDiscountToGiftMutation,
  useGiftsQuery,
  useReceivedGiftsQuery,
  useLazyReceivedGiftsQuery,
  useCancelGiftMutation,
  useCreateGiftMutation,
  useGiftPreviewLinkMutation,
  useLazyGiftLinkQuery,
  useSubscribeToMarketingMutation,
  useLazyGetTagsFromGPTQuery,
  useSendGiftTYNMutation,
} = consumerApi;
