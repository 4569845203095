import { combineReducers } from 'redux';
import { createAction, Reducer } from '@reduxjs/toolkit';
import { persistReducer, purgeStoredState } from 'redux-persist';
import { PersistedState } from 'redux-persist/es/types';
import sessStorage from 'redux-persist/lib/storage/session';
import locStorage from 'redux-persist/lib/storage';
import { shopifyApi } from '@/redux/api/shopify';
import activeState from '@/redux/slices/activeState/activeState';
import auth, { AuthState } from '@/redux/auth';
import checkout from '@/redux/checkout';
import productThumbnails from '@/redux/productThumbnails';
import { consumerApi } from '@/redux/api/consumer';
import { CheckoutState } from '@/redux/checkout/types';
import { ui, uiState } from '@/redux/slices/ui';
import { greetingApi } from '@/redux/api/greeting';

const PERSIST_KEY_CHECKOUT = 'checkout';
const PERSIST_KEY_UI = 'ui';
const PERSIST_KEY_AUTH = 'auth';

const checkoutPersistConfig = {
  key: PERSIST_KEY_CHECKOUT,
  storage: sessStorage,
  whitelist: ['payment'],
  version: 1,
  migrate: clearPersistedStateOnVersionMismatch,
};

const uiPersistConfig = {
  key: PERSIST_KEY_UI,
  storage: locStorage,
  version: 1,
  migrate: clearPersistedStateOnVersionMismatch,
};

const authPersistConfig = {
  key: PERSIST_KEY_AUTH,
  storage: locStorage,
  whitelist: ['user'],
  version: 1,
  migrate: clearPersistedStateOnVersionMismatch,
};

export const clearStore = createAction('clearStore');

// eslint-disable-next-line default-param-last
export const appReducer: Reducer = (state = {}, action) => {
  if (action.type === clearStore.type) {
    return undefined;
  }
  return state;
};

export const rootReducer = combineReducers({
  auth: persistReducer<AuthState>(authPersistConfig, auth),
  activeState,
  ui: persistReducer<uiState>(uiPersistConfig, ui),
  checkout: persistReducer<CheckoutState>(checkoutPersistConfig, checkout),
  productThumbnails,
  [shopifyApi.reducerPath]: shopifyApi.reducer,
  [consumerApi.reducerPath]: consumerApi.reducer,
  [greetingApi.reducerPath]: greetingApi.reducer,
});

async function clearPersistedStateOnVersionMismatch(
  state: PersistedState,
  currentVersion: number
): Promise<PersistedState> {
  const currentCacheVersion = state?._persist.version;

  if (currentCacheVersion !== undefined && currentVersion !== currentCacheVersion) {
    await Promise.all([
      purgeStoredState({
        key: PERSIST_KEY_CHECKOUT,
        storage: locStorage,
      }),
      purgeStoredState({
        key: PERSIST_KEY_UI,
        storage: sessStorage,
      }),
      purgeStoredState({
        key: PERSIST_KEY_AUTH,
        storage: locStorage,
      }),
    ]);

    return Promise.resolve(undefined);
  }
  return Promise.resolve(state);
}
