import { DISCOUNT_CODE_ERROR_MESSAGES } from '@/constants/Strings';
import collectionMappingProd from './collectionMapping-prod.json';

export function getCollectionShopifyId(id: string): string {
  const mapping = collectionMappingProd;
  return mapping.find((c) => c._id === id)?.shopifyId ?? id;
}

export function getCollectionConsumerId(id: string): string {
  const mapping = collectionMappingProd;
  return mapping.find((c) => c.shopifyId === id)?._id ?? id;
}

export function extractIdNumber(shopifyId = ''): string {
  return shopifyId.split('/').pop() ?? '';
}

export function getErrorMessagesFromCheckoutUserErrors(
  checkoutUserErrors: Shopify.CheckoutUserError[]
): string {
  const errorMessages = checkoutUserErrors
    .map((error) => DISCOUNT_CODE_ERROR_MESSAGES[error.code])
    .filter(Boolean);

  return errorMessages.length ? errorMessages.join('. ') : '';
}

export function getDiscountCodeNotFoundCheckoutUserError(): Shopify.CheckoutUserError {
  return {
    code: 'DISCOUNT_NOT_FOUND',
    field: [],
    message: DISCOUNT_CODE_ERROR_MESSAGES.DISCOUNT_NOT_FOUND,
  };
}

/**
 * @description Encodes a field name to be used as a key in a GraphQL query.
 * (e.g. "product.title" -> "product_title")
 *
 * @param fieldName {string} - The name of the field to encode
 */
function encodeFieldName(fieldName: string): string {
  const encodeChar = (char: string) => `_${char.charCodeAt(0).toString(16)}_`;
  return fieldName.replace(/[^a-zA-Z0-9_]/g, encodeChar);
}

/**
 * @description Decodes a field name that was encoded with encodeFieldName.
 * (e.g. "product_title" -> "product.title")
 *
 * @param encodedFieldName {string} - The name of the field to decode
 */
function decodeFieldName(encodedFieldName: string): string {
  const decodeChar = (match: string) => String.fromCharCode(parseInt(match.slice(1, -1), 16));
  return encodedFieldName.replace(/_[0-9a-fA-F]{2}_/g, decodeChar);
}

/**
 * @description Encodes and decodes a field name to be used as a key in a GraphQL query.
 *
 * @param fieldName {string} - The name of the field to encode and decode
 */
export function transformFieldName(fieldName: string) {
  return {
    encode: () => encodeFieldName(fieldName),
    decode: () => decodeFieldName(fieldName),
  };
}

/**
 * @description Adds the specified width and height to the image URL.
 * @param url {string} - The URL of the image
 * @param size {{ w: number, h: number }} - The width and height to add to the image URL
 * @param defaultValue {string=} - Default value to return if no size provided
 */
export function modifySizeOfShopifyImageUrl(
  url: string | undefined | null,
  size: { w: number; h: number },
  defaultValue = ''
): string {
  if (!url) return defaultValue;

  // If the URL is not a Shopify URL, return the original URL
  if (!url.includes('shopify.com/')) return url;

  // If no size is provided, return the default value
  if (!size?.w || !size?.h) return defaultValue;

  // Convert the size to a string
  const sizeParam = `${Math.round(size.w)}x${Math.round(size.h)}`;

  // Regular expression to find the image extension and preceding size (if any)
  const regex = /(_\d+x\d+)?\.(jpg|jpeg|png|gif|webp)(.*)$/;

  // If the URL matches the regular expression
  const match = url.match(regex);

  // Insert the size before the extension, replacing any existing size
  if (match) return url.replace(regex, `_${sizeParam}.$2$3`);

  // If the URL does not match the regular expression, return the original URL
  return url;
}
