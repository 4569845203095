import axios, { AxiosPromise } from 'axios';

export const getData = <R, P = object>(endPoint: string, params?: P): AxiosPromise<R> =>
  axios.get(endPoint, { params });

export const postData = <R, D = object>(endPoint: string, data: D): AxiosPromise<R> =>
  axios.post(endPoint, data);

export const putData = <R, D = object>(endPoint: string, data: D): AxiosPromise<R> =>
  axios.put(endPoint, data);

export const patchData = <R, D = object>(endPoint: string, data: D): AxiosPromise<R> =>
  axios.patch(endPoint, data);

export const deleteData = <R>(endPoint: string): AxiosPromise<R> => axios.delete(endPoint);
