import axios from 'axios';

import { AuthService } from '@/services/AuthService/AuthService';

export const interceptor = (): void => {
  axios.interceptors.request.use(
    async (req) => {
      req.withCredentials = true;
      const authToken = await AuthService.getAccessToken();
      if (authToken && req.headers) {
        req.headers.Authorization = `Bearer ${authToken}`;
      }
      if (req.headers) {
        req.headers.Accept = req.headers?.Accept || 'application/json';

        req.baseURL = process.env.NEXT_PUBLIC_API_BASE_URL ?? '';

        return req;
      }
      return req;
    },
    (err) => Promise.reject(err)
  );

  axios.interceptors.response.use(
    (res) => Promise.resolve(res),
    (err) => {
      // Here we can handle all api error responses
      if (err.response?.status === 401) {
        console.error('Error from ::: interceptor');
      }
      return Promise.reject(err);
    }
  );
};
