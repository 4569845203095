import React, { useEffect, useRef } from 'react';
import { Typography } from '@/components/ui/Typography';
import { Button } from '@/components/ui/Button';
import { Colors } from '@/constants/Colors';
import {
  AI_GENERATE_COLLECTION_FORM_ERROR_KEYS,
  AI_GENERATE_COLLECTION_FORM_ERRORS,
} from '../../hooks/useCollectionGeneration';

export const AI_FORM_ERROR_STATE_TEST_ID = 'ai-form-error-state';

interface AIFormErrorStateProps {
  errorKey: AI_GENERATE_COLLECTION_FORM_ERROR_KEYS | null;
  onTryAgain(): void;
}

export const AIFormErrorState: React.FC<AIFormErrorStateProps> = ({ errorKey, onTryAgain }) => {
  const errorInfo = errorKey && AI_GENERATE_COLLECTION_FORM_ERRORS[errorKey];

  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    buttonRef.current?.focus();
  }, []);

  return (
    <div className="ai-form-error-state" data-testid={AI_FORM_ERROR_STATE_TEST_ID}>
      <div className="flex flex-col gap-2">
        {errorInfo?.title && (
          <Typography
            tag="h2"
            size={20}
            fontFamily="inter"
            weight={600}
            lineHeight="130%"
            color={Colors.primary[800]}
          >
            {errorInfo.title}
          </Typography>
        )}

        {errorInfo?.description && (
          <Typography
            tag="h3"
            size={16}
            fontFamily="inter"
            weight={500}
            lineHeight="130%"
            color={Colors.primary[700]}
          >
            {errorInfo.description}
          </Typography>
        )}
      </div>

      <Button
        ref={buttonRef}
        variant="primary"
        size="medium"
        className="max-md:w-full max-w-[400px]"
        onClick={onTryAgain}
      >
        Try again
      </Button>

      {/*  Styles  */}

      <style jsx>{`
        .ai-form-error-state {
          @apply flex-auto flex flex-col md:flex-row items-center justify-between md:justify-start;
          @apply gap-4 w-full max-md:text-center whitespace-pre-wrap;
        }
      `}</style>
    </div>
  );
};
