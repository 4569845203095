import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { QueryReturnValue } from '@reduxjs/toolkit/src/query/baseQueryTypes';

type TransformErrorResponse = (options: {
  errorMessage?: string;
  errorDescription?: string;
  shouldThrow?: boolean;
}) => (baseQueryReturnValue: QueryReturnValue<BaseQueryFn<unknown, unknown, object>>) =>
  | (QueryReturnValue<BaseQueryFn<unknown, unknown, object>> & {
      errorMessage?: string;
      errorDescription?: string;
    })
  | undefined;

/**
 * @description This function is used to transform the error response from a baseQuery in RTK Query.
 * It adds errorMessage and errorDescription to the toast message when an error occurs in request.
 *
 * @param errorMessage
 * @param errorDescription
 * @param shouldThrow
 */
export const transformErrorResponse: TransformErrorResponse =
  ({ errorMessage, errorDescription, shouldThrow = true } = {}) =>
  (baseQueryReturnValue) => {
    if (!shouldThrow) return undefined;

    return {
      ...(typeof baseQueryReturnValue === 'object' ? baseQueryReturnValue : ({} as never)),
      errorMessage,
      errorDescription,
    };
  };
