import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

interface CaptchaProviderProps {
  publicKey?: string;
  children?: React.ReactNode;
}

export const CaptchaProvider: React.FC<CaptchaProviderProps> = ({
  publicKey = String(process.env.NEXT_PUBLIC_CAPTCHA_PUBLIC_KEY),
  children,
}) => <GoogleReCaptchaProvider reCaptchaKey={publicKey}>{children}</GoogleReCaptchaProvider>;
