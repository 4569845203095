import { ImpactMethods } from '@/types/impact';
import { SSR } from '@/utils/ssr';

const TRACK_EVENT_ID = 33668;

export class ImpactService {
  static track(id: string, orderId: string): void {
    if (SSR) return;
    window?.ire?.(ImpactMethods.track, TRACK_EVENT_ID, {
      orderId,
      customerId: id,
    });
  }

  static identify(id: string): void {
    if (SSR) return;
    window?.ire?.(ImpactMethods.identify, null, {
      customerId: id,
    });
  }
}
