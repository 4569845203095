import React, { memo } from 'react';
import { Typography } from '@/components/ui/Typography';
import { Colors } from '@/constants/Colors';

export const AI_FORM_TITLE_LABEL_TEST_ID = 'ai-form-title-label';

export const AIFormTitleLabel = memo(() => (
  <div className="ai-form-title" data-testid={AI_FORM_TITLE_LABEL_TEST_ID}>
    <Typography
      size={16}
      lineHeight={1.3}
      fontFamily="dm-sans"
      className="md:font-bold md:uppercase"
      color={Colors.primary[900]}
    >
      Build Custom Collections <br className="md:hidden" /> With The Power Of AI
    </Typography>

    <span className="ai-form-title__badge">
      <Typography
        tag="span"
        size={10}
        fontFamily="dm-sans"
        weight={700}
        lineHeight={1.3}
        color={Colors.white}
      >
        BETA
      </Typography>
    </span>

    {/* Styles */}

    <style jsx>{`
      .ai-form-title {
        @apply w-full flex gap-2 justify-between md:justify-start items-end md:items-center max-md:px-4;

        &__badge {
          @apply flex items-center bg-coral-200 px-1 py-[2px] rounded;
        }
      }
    `}</style>
  </div>
));
