import { createSelector } from '@reduxjs/toolkit';
import { consumerApi } from '@/redux/api/consumer';

const selectExperiencesResultFromAPI = consumerApi.endpoints.experiences.select();

export const selectExperienceByType = createSelector(
  [selectExperiencesResultFromAPI, (state, type: string | null) => type],
  (experiencesQueryResult, type) => {
    if (type && experiencesQueryResult.data) {
      return experiencesQueryResult.data.find((experience) => experience.type === type);
    }
    return null;
  }
);
