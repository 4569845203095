import KlaviyoAnalytics, { KlaviyoEvents, KlaviyoMethods, ParamsByMethod } from '@/types/klaviyo';
import { SSR } from '@/utils/ssr';

let klaviyoClient: KlaviyoAnalytics = [];

if (!SSR) {
  window._learnq = window._learnq || [];
  klaviyoClient = window._learnq;
}

export class KlaviyoService {
  static track(event: KlaviyoEvents, params: ParamsByMethod<KlaviyoEvents>): void {
    if (SSR) return;
    klaviyoClient.push([KlaviyoMethods.track, event, params]);
  }

  static identify(email: string): void {
    if (SSR) return;
    if (email) {
      klaviyoClient.push([
        KlaviyoMethods.identify,
        {
          $email: email,
          $consent: ['email'],
        },
      ]);
    }
  }
}
