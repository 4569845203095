import { useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

export const useCaptcha = (): ((actionName: string) => Promise<string | null>) => {
  const { executeRecaptcha: executeGoogleReCaptcha } = useGoogleReCaptcha();

  const executeCaptcha = useCallback(
    async (actionName: string) => {
      if (!executeGoogleReCaptcha) {
        console.warn("Captcha isn't initialized");
        return null;
      }
      try {
        return await executeGoogleReCaptcha(actionName);
      } catch (err) {
        console.error('Error during executing Google ReCaptcha.', err);
        return null;
      }
    },
    [executeGoogleReCaptcha]
  );

  return executeCaptcha;
};
