import React from 'react';
import clsx from 'clsx';

type TypographyTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'div' | 'label';
type TypographyAlign = 'left' | 'center' | 'right' | 'justify';
type TypographyWhiteSpace =
  | 'normal'
  | 'nowrap'
  | 'pre'
  | 'pre-wrap'
  | 'pre-line'
  | 'break-spaces'
  | 'inherit';

const fontFamilies = {
  inter: 'font-inter',
  'dm-serif': 'font-dm-serif',
  'dm-sans': 'font-dm-sans',
};
const fontTypes = {
  'heading-1-h0': 'font-heading-1-h0',
  'heading-1-h1': 'font-heading-1-h1',
  'heading-1-h2': 'font-heading-1-h2',
  'heading-1-h3': 'font-heading-1-h3',
  'heading-1-h4': 'font-heading-1-h4',
  'heading-1-h5': 'font-heading-1-h5',
  'heading-1-h6': 'font-heading-1-h6',
  'heading-2-h0': 'font-heading-2-h0',
  'heading-2-h1': 'font-heading-2-h1',
  'heading-2-h2': 'font-heading-2-h2',
  'heading-2-h3': 'font-heading-2-h3',
  'heading-2-h4': 'font-heading-2-h4',
  'heading-2-h5': 'font-heading-2-h5',
  'heading-2-h6': 'font-heading-2-h6',
  'body-1': 'font-body-1',
  'body-2': 'font-body-2',
  'body-3': 'font-body-3',
  'body-4': 'font-body-4',
  'button-1': 'font-button-1',
  'button-2': 'font-button-2',
  'button-3': 'font-button-3',
};

export interface TypographyProps extends React.HTMLAttributes<HTMLElement> {
  //  ↓ This is the main property that defines font from UI kit.
  type?: keyof typeof fontTypes;
  //  ↓ All the other properties are for flexibility.
  tag?: TypographyTag;
  align?: TypographyAlign;
  weight?: string | number;
  whiteSpace?: TypographyWhiteSpace;
  zIndex?: number;
  fontFamily?: keyof typeof fontFamilies;
  rows?: number;
  size?: string | number;
  color?: string;
  bgColor?: string;
  htmlFor?: string;
  truncate?: boolean;
  lineHeight?: string | number;
  textTransform?: 'none' | 'uppercase' | 'lowercase' | 'capitalize';
}

export const Typography: React.FC<TypographyProps> = ({
  tag: Tag = 'span',
  type,
  rows,
  size,
  color,
  align,
  style,
  weight,
  bgColor,
  truncate,
  className,
  lineHeight,
  fontFamily,
  whiteSpace,
  zIndex,
  textTransform,
  children,
  ...props
}) => {
  const classNames = [
    'typography',
    className,
    { truncate },
    type && fontTypes[type],
    fontFamily && fontFamilies[fontFamily],
  ];

  const styles = {
    ...style,
    color,
    lineHeight,
    whiteSpace,
    zIndex,
    textTransform,
    fontSize: size,
    textAlign: align,
    fontWeight: weight,
    backgroundColor: bgColor,
  };

  if (rows) {
    styles.display = '-webkit-box';
    styles.WebkitBoxOrient = 'vertical';
    styles.WebkitLineClamp = rows;
  }

  return (
    <Tag {...props} className={clsx(classNames)} style={styles}>
      {children}

      <style jsx>
        {`
          .typography {
            @apply flex-initial max-w-full text-ellipsis overflow-hidden;
          }
        `}
      </style>
    </Tag>
  );
};
