import React, { ChangeEvent, useRef } from 'react';
import clsx from 'clsx';
import { Collapse } from '@/components/ui/Collapse';

export interface TextInputProps extends Omit<React.HTMLAttributes<HTMLInputElement>, 'onChange'> {
  value: string;
  size?: 'sm' | 'md';
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  maxLength?: number;
  isError?: boolean;
  error?: string;
  successMessage?: string;
  testId?: string;
  inputClassName?: string;
  onChange?: (newValue: string) => void;
}

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      size = 'md',
      disabled,
      placeholder,
      value,
      maxLength,
      isError,
      error,
      className,
      inputClassName,
      successMessage,
      testId = 'input',
      onChange,
      ...props
    },
    forwardRef
  ): JSX.Element => {
    // Leave previous messages for keeping text within Collapse until it's fully closed.
    const prevErrorRef = useRef(error);
    const prevSuccessRef = useRef(successMessage);

    const handleChange = (e: ChangeEvent) => {
      const target = e.target as HTMLInputElement;
      onChange?.(target.value ?? '');
    };

    return (
      <div className={clsx('text-input', className)}>
        <input
          className={clsx('text-input__input', size, inputClassName, { error: error || isError })}
          ref={forwardRef}
          disabled={disabled}
          placeholder={placeholder}
          onChange={handleChange}
          value={value}
          maxLength={maxLength}
          data-testid={testId}
          {...props}
        />

        <Collapse in={Boolean(successMessage) && typeof successMessage === 'string'} unmountOnExit>
          <div className="text-input__success">{successMessage || prevSuccessRef.current}</div>
        </Collapse>

        <Collapse in={Boolean(error) && typeof error === 'string'} unmountOnExit>
          <div className="text-input__error">{error || prevErrorRef.current}</div>
        </Collapse>

        <style jsx>
          {`
            .text-input {
              @apply flex flex-col;

              &__input {
                @apply px-3 w-full sm:text-sm text-base resize-none;
                @apply border border-indigo-400 border-solid rounded;
                @apply transition-all duration-300 ease-out-spring;
                @apply placeholder:text-sm placeholder:font-medium placeholder:text-primary-600;

                &.sm {
                  @apply py-2 h-10;
                }

                &.md {
                  @apply py-5 h-14;
                }

                &.error {
                  @apply border-system-error;
                  @apply placeholder:text-system-error;
                }
              }

              &__error {
                @apply pt-1 text-system-error text-sm font-medium;
              }

              &__success {
                @apply pt-1 text-system-success text-sm font-medium;
              }
            }
          `}
        </style>
      </div>
    );
  }
);
