import { useEffect } from 'react';

/**
 * @description Hook that calls a callback on window resize
 * @param callback {() => void} - Callback to be called on window resize
 */
export const useWindowResize = (callback: () => void): void => {
  useEffect(() => {
    callback();
    window.addEventListener('resize', callback);
    return () => window.removeEventListener('resize', callback);
  }, [callback]);
};
