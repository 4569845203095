import React from 'react';
import clsx from 'clsx';
import isObject from 'lodash/isObject';
import { Colors } from '@/constants/Colors';
import { usePressEnterHandler } from '@/hooks/usePressEnterHandler';
import { Icon, IconName } from '@/components/ui/Icons/Icon';
import { BaseLink } from '@/components/ui/BaseLink';
import { RootStackParamList } from '@/types/screens';

interface MenuItem {
  name: string;
  path: string | { href: keyof RootStackParamList };
  icon?: IconName;
}

interface MobileMenuItemProps {
  children?: React.ReactNode;
  hasBorder?: boolean;
  isActive?: boolean;
  item?: MenuItem;
  onClick?: () => void;
}

const ICON_SIZE_PX = 24;

export const MobileMenuItem: React.FC<MobileMenuItemProps> = ({
  children,
  hasBorder,
  isActive,
  item,
  onClick,
}) => {
  const getIconColor = (): string => (isActive ? Colors.indigo['700'] : Colors.primary['700']);

  const handlePressEnter = usePressEnterHandler(onClick);

  return (
    <div className="mobile-menu-item">
      {item ? (
        <BaseLink
          href={isObject(item.path) ? item.path.href : item.path}
          onClick={onClick}
          {...(typeof item.path === 'string' && { target: '_blank' })}
        >
          <div
            className={clsx('mobile-menu-item__content', {
              'has-border': hasBorder,
            })}
          >
            {!!item.icon && (
              <div className="mobile-menu-item__content-icon">
                <Icon name={item.icon} size={ICON_SIZE_PX} color={getIconColor()} />
              </div>
            )}
            <div
              className={clsx('mobile-menu-item__content-text', {
                active: isActive,
              })}
            >
              {item.name}
            </div>
            <div className="mobile-menu-item__content-icon">
              <Icon
                name="ThickArrow"
                direction="right"
                size={ICON_SIZE_PX}
                color={getIconColor()}
              />
            </div>
          </div>
        </BaseLink>
      ) : (
        <div
          role="button"
          tabIndex={0}
          aria-pressed="false"
          onClick={onClick}
          onKeyDown={handlePressEnter}
          className={clsx('mobile-menu-item__content', {
            'has-border': hasBorder,
          })}
        >
          {children}
        </div>
      )}

      {/* Style */}
      <style jsx>
        {`
          .mobile-menu-item {
            &__content {
              @apply flex items-center w-full py-8;
              @apply text-xl leading-normal font-dm-sans;
              @apply text-primary-900 font-medium;

              &.has-border {
                @apply border-b border-solid border-primary-300;
              }

              &-text {
                @apply flex-1;

                &.active {
                  @apply text-indigo-700;
                }
              }

              &-icon {
                @apply first:pr-4 last:pl-4;
              }
            }
          }
        `}
      </style>
    </div>
  );
};
