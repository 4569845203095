import React from 'react';

type UsePrevious = <T>(value: T, options?: { ignoreValues: unknown[] }) => T | undefined;

export const usePrevious: UsePrevious = (value, options) => {
  const ref = React.useRef<typeof value>();

  React.useEffect(() => {
    if (Array.isArray(options?.ignoreValues) && options?.ignoreValues.includes(value)) return;
    ref.current = value;
  });

  return ref.current;
};
