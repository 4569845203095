/* eslint no-param-reassign: 0 */
import { createSelector, createSlice, Reducer } from '@reduxjs/toolkit';
import { shopifyApi } from '@/redux/api/shopify';
import { Product } from '@/types/entities/product';
import { selectActiveBudgetRange } from '@/redux/slices/activeState/activeState';
import type { RootState } from '@/redux/store';

// Types

type MappingState = {
  [key: string]: {
    [key: string]: Product[];
  };
};
// Slice

const initialState = {} as MappingState;
const slice = createSlice({
  name: 'productThumbnails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      shopifyApi.endpoints.productThumbnails.matchFulfilled,
      (state, { payload }) => {
        payload.forEach((collection) => {
          const productsThumbnails = collection.data;
          state[collection.collectionId] = {
            ...state[collection.collectionId],
            [collection.budget]: productsThumbnails,
          };
        });
      }
    );
  },
});

export const selectProductThumbnails = (state: RootState) => state.productThumbnails;

export const selectProductThumbnailsByCollectionId = createSelector(
  [
    selectProductThumbnails,
    selectActiveBudgetRange,
    (state: RootState, collectionId: string) => collectionId,
  ],
  (thumbnails, productsPriceRange, collectionId) => {
    const budgetProductsMapping = thumbnails[collectionId];
    return budgetProductsMapping ? budgetProductsMapping[productsPriceRange.max] : [];
  }
);

export default slice.reducer as Reducer<typeof initialState>;
