import { CheckoutCollection, CheckoutState } from '@/redux/checkout/types';
import { createSelector } from '@reduxjs/toolkit';
import { selectCollectionById } from '@/redux/api/selectors/collections';
import { selectExperienceByType } from '@/redux/api/selectors/experiences';
import {
  selectProductsByCollectionHandle,
  selectProductsByCollectionId,
} from '@/redux/api/selectors/products';
import { ExperienceBanner } from '@/types/entities/experience-banners';
import type { RootState } from '@/redux/store';
import { getBudgetMin } from '@/utils/collectionHelpers';

export const selectGift = createSelector(
  [(state: RootState) => state],
  (
    state: RootState
  ): Omit<CheckoutState['gift'], 'collectionId' | 'experienceType'> & {
    collection: CheckoutCollection;
    experience: ExperienceBanner | null;
  } => {
    const { gift } = state.checkout;
    const { collectionId, experienceType, ...restGift } = gift;
    const collection = selectCollectionById(collectionId)(state);
    const experience = selectExperienceByType(state, experienceType ?? '');

    const productsByCollectionId = selectSelectedProductsByCollectionId(
      collection?.id,
      gift.budget
    )(state);
    const productsByCollectionHandle = selectSelectedProductsByCollectionByHandle(
      collection?.handle,
      {
        min: getBudgetMin(gift.budget),
        max: gift.budget,
      }
    )(state);

    const products = productsByCollectionId?.length
      ? productsByCollectionId
      : productsByCollectionHandle;

    return {
      ...restGift,
      experience: experience ?? null,
      collection: {
        id: collection?.id ?? '',
        title: collection?.title ?? '',
        handle: collection?.handle ?? '',
        imageSrc: collection?.image?.src ?? '',
        products: products ?? [],
      },
    };
  }
);

export const selectPayment = (state: RootState) => state.checkout.payment;

export const selectRecipient = (state: RootState) => state.checkout.recipient;

export const selectSender = (state: RootState) => state.checkout.sender;

export const selectSelectedProductIds = (state: RootState) =>
  state.checkout.gift.selectedProductIds ?? [];

export const selectSelectedProductsByCollectionId = (
  collectionId: string | undefined,
  budget: number
) =>
  createSelector(
    [selectProductsByCollectionId(collectionId, budget), selectSelectedProductIds],
    (products, selectedProductIds) =>
      products?.filter((product) => selectedProductIds.includes(product.id)) ?? []
  );

export const selectSelectedProductsByCollectionByHandle = (
  collectionHandle: string | undefined,
  price: { min: number; max: number }
) =>
  createSelector(
    [
      selectProductsByCollectionHandle({
        handle: collectionHandle ?? '',
        price,
      }),
      selectSelectedProductIds,
    ],
    (products, selectedProductIds) =>
      products.data?.filter((product) => selectedProductIds.includes(product.id)) ?? []
  );

export const selectShopifyCheckout = (state: RootState) => state.checkout.shopifyCheckout;

export const selectShopifyCheckoutOrderPayload = (state: RootState) => state.checkout.orderPayload;

export const selectShopifyCheckoutOrderData = (state: RootState) => state.checkout.orderData;
