import tailwindConfig from '../../tailwind.config';

export const Colors = {
  // colors go here
  bgPrimary: '#3077C9',
  bgSecondary: '#EAF1FA',
  error: '#cc0000',
  warning: '#f1c40f',
  textLight: { 100: '#5A7390', 75: '#405D7F' },
  screenBg: '#FFFFFF',
  bgGray: '#F2F4F6',
  inputBorder: '#E0EBF7',
  bgBlue: '#D2F3FF',
  niceNavy: { 35: '#A6B3C3', 50: '#8093A9', 65: '#5A7390', 80: '#345276' },
  primaryIndigo: '#F5F8FC',
  darkIndigo: '#2864A8',
  primaryBlue: '#EBFBFF',
  darkBlue: '#1B2559',
  givingGreen: '#EFFBF5',
  bgGreen: '#5AD69F',
  bgBlack: '#000000',
  borderGray: 'rgba(60, 66, 87, .12)',
  gradients: {
    onBoardingScreen1: ['rgba(235,251,255, .19)', 'rgba(235,251,255, 1)'],
    onBoardingScreen2: ['rgba(239, 251, 245, 1)', 'rgba(239, 251, 245, 1)'],
    onBoardingScreen3: ['rgba(235,251,255, .19)', 'rgba(235,251,255, 1)'],
    onBoardingScreen4: ['rgba(235,251,255, .19)', 'rgba(235,251,255, 1)'],
    success: ['#CDF4FF', '#EBFBFF'],
  },
  darkBrown: '#bd4734',
  blackRed: '#360D08',
  gray: '#E6E9EE',
  disabled: '#cccccc',
  activeMenu: '#6499D6',
  // theme colors
  ...tailwindConfig.theme.extend.colors,
};
