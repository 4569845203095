import { SSR } from '@/utils/ssr';
import { AnalyticsService } from '@/services/AnalyticsService';
import * as Sentry from '@sentry/nextjs';
import { useEffect } from 'react';

let isAppIsMounted = false;

// ERROR HANDLING
// Show error alert for unhandled errors during app initialization
if (!SSR) {
  window.addEventListener('error', (event) => {
    setTimeout(() => {
      if (isAppIsMounted) return;

      AnalyticsService.error({ heading: 'App is crashed', message: event.message });

      Sentry.withScope((scope) => {
        scope.setTransactionName('App is crashed');
        scope.setExtras(event.error);
        Sentry.captureException(event);
      });

      // eslint-disable-next-line no-restricted-globals,no-alert
      const shouldReload = confirm('Oops, something went wrong.\nLet me try to refresh the app 🤞');
      if (shouldReload) window.location.reload();
      // Wait for 5 seconds for the app mounting before showing the error alert.
      // This is to prevent showing the alert when the app is still loading.
    }, 5000);
  });
}

export const useAppCrushHandle = () => {
  useEffect(() => {
    isAppIsMounted = true;
  }, []);
};
