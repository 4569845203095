import { gql } from 'graphql-request';

export const collectionByIdQuery = gql`
  query collectionByIdQuery($id: ID!) {
    collection(id: $id) {
      id
      handle
      descriptionHtml
      title
      image {
        id
        src: url
        altText
      }
      alcoholic: metafield(namespace: "my_fields", key: "alcoholic") {
        id
        value
      }
      landingPage: metafield(namespace: "my_fields", key: "landingPage") {
        value
      }
      heroImage: metafield(namespace: "my_fields", key: "heroImage") {
        reference {
          ... on MediaImage {
            image {
              originalSrc
            }
          }
        }
      }
      seo {
        title
        description
      }
    }
  }
`;
