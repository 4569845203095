import { gql } from 'graphql-request';

export const personasQuery = gql`
  query personasQuery {
    collections(first: 250) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          personas: metafield(namespace: "my_fields", key: "personas") {
            id
            value
          }
        }
      }
    }
  }
`;
