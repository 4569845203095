import type { ErrorInfo } from 'react';
import type { Extras } from '@sentry/types';
import { AnalyticsService } from '@/services/AnalyticsService';
import * as Sentry from '@sentry/nextjs';

// ERROR HANDLING
// Handle error during render time
export const errorBoundaryHandler = (error: Error, info: ErrorInfo): void => {
  AnalyticsService.error({ heading: 'Error boundary', message: error.message });

  Sentry.withScope((scope) => {
    scope.setTransactionName('Error boundary');
    scope.setExtras(info as Extras);
    Sentry.captureException(error);
  });
};
