import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { useDeviceType } from '@/hooks/useDeviceType';
import { Icon, IconName } from '@/components/ui/Icons/Icon';
import { BaseLink } from '@/components/ui/BaseLink';
import { Typography } from '@/components/ui/Typography';

interface NavLinkProps {
  children: React.ReactNode;
  href?: string;
  size?: 'sm' | 'md';
  target?: '_blank' | '_self';
  disabled?: boolean;
  isActive?: boolean;
  leftIcon?: IconName;
  withCaret?: boolean;
  className?: string;
  testId?: string;
  onClick?(): void;
}

export const NavLink = forwardRef<HTMLElement, NavLinkProps>(
  (
    {
      href,
      size = 'md',
      target = '_self',
      disabled,
      withCaret,
      leftIcon,
      isActive,
      children,
      className,
      testId,
      onClick,
    },
    ref
  ) => {
    const { isDesktop } = useDeviceType();

    const Tag = (href ? BaseLink : 'button') as React.ElementType;
    const tagProps = href ? { href, target } : { type: 'button', disabled };

    const caretMobileDir = isActive ? 'down' : 'right';
    const caretDesktopDir = isActive ? 'up' : 'down';
    const caretDir = isDesktop ? caretDesktopDir : caretMobileDir;

    return (
      <Tag
        ref={ref}
        {...tagProps}
        tabIndex={disabled ? -1 : 0}
        className={clsx('nav-link', className, size, { active: isActive, disabled })}
        data-testid={testId}
        onClick={() => (disabled ? null : onClick?.())}
      >
        {leftIcon && (
          <Icon name={leftIcon} size={20} color="currentColor" className="nav-link__icon" />
        )}
        <Typography size={16} lineHeight={1} weight={500} fontFamily="dm-sans">
          {children}
        </Typography>
        {withCaret && (
          <Icon
            name="ChevronIcon"
            direction={caretDir}
            size={20}
            color="currentColor"
            className="nav-link__icon"
          />
        )}

        {/*  Styles  */}
        <style jsx>{`
          .nav-link {
            @apply flex-none flex flex-nowrap items-center gap-1;
            @apply rounded-2xl outline-none focus:outline-none;

            &.sm {
              @apply p-3;
            }

            &.md {
              @apply p-4;
            }

            &:not(.disabled):hover,
            &:not(.disabled):focus-visible,
            :global(&:not(.disabled).active) {
              @apply bg-indigo-100 text-indigo-800;

              :global(.nav-link__icon) {
                @apply text-indigo-800;
              }
            }

            :global(&__icon) {
              @apply text-primary-700;
            }
          }
        `}</style>
      </Tag>
    );
  }
);
