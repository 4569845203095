/* eslint indent: 0 */

export type CatchAndHandle<F, D> = {
  fn: F;
  errorHandler: (error: Error) => void;
  defaultValue?: D;
  shouldThrow?: boolean;
};

/**
 * Handles errors on any wrapped function and calls the provided errorHandler.
 */
export const catchAndHandle = <
  F extends (...args: Parameters<F>) => ReturnType<F> | Promise<ReturnType<F>>,
>({
  fn,
  errorHandler,
  defaultValue,
  shouldThrow,
}: CatchAndHandle<F, ReturnType<F>>) => {
  const handler = (...args: Parameters<F>): ReturnType<F> | Promise<ReturnType<F>> => {
    try {
      const result = fn.apply(this, args) ?? (defaultValue as ReturnType<F>);

      // Check if method is asynchronous
      if (result && result instanceof Promise) {
        // Return promise
        return result.catch((error) => {
          errorHandler(error);

          if (shouldThrow) {
            throw error;
          } else {
            return defaultValue as ReturnType<F>;
          }
        });
      }

      return result;
    } catch (error) {
      errorHandler(error);

      if (shouldThrow) {
        throw error;
      }
    }

    return defaultValue as ReturnType<F>;
  };

  return handler;
};
