import { useEffect, useState } from 'react';
import { useWindowSize } from '@/hooks/useWindowSize';
import { Screens } from '@/constants/Screens';

interface DeviceType {
  isSmallDevice: boolean; // < 1024px
  isDesktop: boolean; // >= 1024px
}

/**
 * Hook to get the current device type by window width
 *
 * @example
 * const { isSmallDevice, isDesktop } = useDeviceType();
 *
 * return (
 *  <div>
 *    {isSmallDevice && <MobileComponent />}
 *    {isDesktop && <DesktopComponent />}
 *  <div>
 * );
 *
 * @returns {DeviceType} deviceType
 */
export const useDeviceType = (): DeviceType => {
  const { width } = useWindowSize();

  const [deviceType, setDeviceType] = useState<DeviceType>({
    isSmallDevice: false,
    isDesktop: false,
  });

  useEffect(() => {
    const updateDeviceType = () => {
      const isSmallDevice = !!width && width < Screens.lg;
      const isDesktop = !!width && width >= Screens.lg;

      setDeviceType({ isSmallDevice, isDesktop });
    };

    updateDeviceType();
  }, [width]);

  return deviceType;
};
