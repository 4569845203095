import React, { memo } from 'react';
import { Typography } from '@/components/ui/Typography';
import { Colors } from '@/constants/Colors';
import { BaseLink } from '@/components/ui/BaseLink';
import { URLS } from '@/constants/Strings';

export const BANNER_B2B_TEST_ID = 'banner-b2b';

export const BannerB2B = memo(() => (
  <section className="banner-b2b" data-testid={BANNER_B2B_TEST_ID}>
    <div className="banner-b2b__container">
      <BaseLink href={URLS.B2B} target="_blank" className="banner-b2b__business-link">
        <Typography size={14} weight={500} fontFamily="dm-sans">
          For Business
        </Typography>
      </BaseLink>
      <Typography size={16} color={Colors.primary[600]}>
        |
      </Typography>
      <Typography
        size={14}
        weight={500}
        fontFamily="dm-sans"
        className="underline"
        color={Colors.primary[900]}
      >
        For Family & Friends
      </Typography>
    </div>

    {/* Styles */}

    <style jsx>{`
      .banner-b2b {
        @apply flex-none flex items-center w-full;

        &__container {
          @apply flex items-center justify-center lg:justify-start gap-4;
        }

        :global(&__business-link) {
          /* changed to the closest custom color because of Lighthouse audit contrast ratio */
          @apply text-[#556d89] hover:text-primary-900;
        }
      }
    `}</style>
  </section>
));
