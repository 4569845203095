import { gql } from 'graphql-request';

const DEFAULT_PRODUCT_THUMBNAILS_AMOUNT = 8;

export const productThumbnailsQuery = gql`
  query productThumbnailsQuery($collectionIds: [ID!]!, $min: Float, $max: Float, $first: Int = ${DEFAULT_PRODUCT_THUMBNAILS_AMOUNT}) {
    nodes(ids: $collectionIds) {
      ... on Collection {
        id
        products(first: $first, filters: [{ available: true }, { price: { min: $min, max: $max } }]) {
          edges {
            node {
              id
              images(first: 1) {
                edges {
                  node {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
