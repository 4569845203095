/* eslint indent: 0 */
import * as Sentry from '@sentry/nextjs';
import type { CatchAndHandle } from '@/utils/errorHandlers/catchAndHandle';
import { catchAndHandle } from '@/utils/errorHandlers/catchAndHandle';

type CatchAndLog<F, D> = Omit<CatchAndHandle<F, D>, 'errorHandler'>;

/**
 * Handles errors on any wrapped function and logs them.
 */
export const catchAndLog = <
  F extends (...args: Parameters<F>) => ReturnType<F> | Promise<ReturnType<F>>,
>({
  fn,
  defaultValue,
  shouldThrow,
}: CatchAndLog<F, ReturnType<F>>) =>
  catchAndHandle({
    fn,
    errorHandler: (error) => {
      Sentry.withScope((scope) => {
        scope.setTransactionName('catchAndLog');
        Sentry.captureException(error);
      });
    },
    defaultValue,
    shouldThrow,
  });
