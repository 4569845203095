import React, { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { CSSTransitionProps } from 'react-transition-group/CSSTransition';

type CollapseProps = Partial<CSSTransitionProps> & {
  children: React.ReactNode;
  appear?: boolean;
  delay?: number;
  tag?: 'div' | 'li';
};

export const Collapse: React.FC<CollapseProps> = ({
  children,
  delay,
  timeout = 400,
  className,
  in: inProp = false,
  tag: Tag = 'div',
  ...props
}) => {
  const childrenNodeRef = useRef<HTMLDivElement & HTMLLIElement>(null);

  return (
    <>
      <CSSTransition
        nodeRef={childrenNodeRef}
        {...props}
        in={inProp}
        timeout={typeof timeout === 'number' ? timeout + (delay || 0) : timeout}
        classNames="base-collapse"
      >
        <Tag ref={childrenNodeRef} className={className}>
          {children}
        </Tag>
      </CSSTransition>

      {/* Styles */}

      <style jsx>
        {`
          [class*='base-collapse'] {
            --height: 1000px;
            --timeout: ${timeout}ms;
            --delay: ${delay === undefined ? `calc(${timeout}ms / 2)` : `${delay}ms`};
            --inner-shift: -5px;
            z-index: 1;

            // Sorry for this fun rule but that is the shortest way
            // to style element without any classes on mount.
            &.base-collapse-appear,
            &.base-collapse-enter,
            &.base-collapse-exit-done {
              max-height: 0;
              :global(& > *) {
                opacity: 0;
                transform: translateY(var(--inner-shift));
              }
            }

            &.base-collapse-exit {
              max-height: var(--height);
              :global(& > *) {
                opacity: 1;
                transform: none;
              }
            }

            &.base-collapse-appear-active,
            &.base-collapse-enter-active {
              max-height: var(--height);
              overflow: hidden;
              transition: max-height var(--timeout) cubic-bezier(0.56, 0.02, 0.62, 0.7);
              :global(& > *) {
                opacity: 1;
                transform: none;
                transition-property: opacity, transform;
                transition-duration: var(--timeout);
                transition-delay: var(--delay);
              }
            }

            &.base-collapse-exit-active {
              max-height: 0;
              overflow: hidden;
              transition: max-height var(--timeout) cubic-bezier(0.25, 0.75, 0.5, 1);
              :global(& > *) {
                opacity: 0;
                transform: translateY(var(--inner-shift));
                transition-property: opacity, transform;
                transition-duration: var(--timeout);
              }
            }

            &.base-collapse-enter-done {
              overflow: unset;
            }
          }
        `}
      </style>
    </>
  );
};
