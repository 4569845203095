/* eslint no-param-reassign: 0 */
import assign from 'lodash/assign';
import merge from 'lodash/merge';
import { createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit';
import { CheckoutState } from '@/redux/checkout/types';
import { Discount, DiscountType } from '@/types/checkout';
import { CreateGiftOrderPayload, CreateUpdateGiftOrderResponse } from '@/types/entities/gift-order';

const initialOrderState: CheckoutState['payment']['order'] = {
  id: '',
  discount: null,
  estimatedServiceFee: 0,
  estimatedShipping: 0,
  estimatedTax: 0,
  giftBudget: 0,
  subtotalAmount: 0,
  totalAmount: 0,
};

const initialPaymentState: CheckoutState['payment'] = {
  clientSecret: '',
  discount: {
    code: '',
    type: DiscountType.FIXED,
    value: 0,
  },
  order: initialOrderState,
  paymentSucceeded: false,
};

const initialState: CheckoutState = {
  sender: {
    firstname: '',
    lastname: '',
    email: '',
    isSubscribedToNewsletter: true,
  },
  recipient: {
    firstname: '',
    lastname: '',
    email: '',
    phoneNum: '',
  },
  gift: {
    budget: 0,
    collectionId: '',
    experienceType: '',
    greetingMessage: '',
    scheduledAt: null,
    sendingMethod: 'mail',
    selectedProductIds: [],
  },
  payment: {
    ...initialPaymentState,
  },
  shopifyCheckout: {
    createdAt: '',
    checkoutUserErrors: [],
    discountApplications: [],
    email: '',
    lineItems: [],
    id: '',
  },
  orderPayload: null,
  orderData: null,
};

const slice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },

    resetDiscount(state) {
      assign(state.payment.discount, { ...initialPaymentState.discount });
    },

    setCouponCode(state, action: PayloadAction<string>) {
      state.payment.discount.code = action.payload;
    },

    setOrderPayload(state, action: PayloadAction<CreateGiftOrderPayload | null>) {
      state.orderPayload = action.payload;
    },

    setOrderData(
      state,
      action: PayloadAction<{
        giftOrder: CreateUpdateGiftOrderResponse;
        shopifyCheckoutDiscount: Discount | null;
      } | null>
    ) {
      state.orderData = action.payload;
    },

    resetOrder(state) {
      state.payment = {
        clientSecret: '',
        discount: state.payment.discount,
        order: { ...initialOrderState },
        paymentSucceeded: state.payment.paymentSucceeded,
      };
    },

    resetShopifyCheckout(state) {
      state.shopifyCheckout = { ...initialState.shopifyCheckout };
    },

    setProductIds(state, action: PayloadAction<string[]>) {
      state.gift.selectedProductIds = action.payload;
    },

    updateGift(state, action: PayloadAction<Partial<CheckoutState['gift']>>) {
      // Reset selected products to avoid merging array with previous state
      if (action.payload.selectedProductIds) state.gift.selectedProductIds = [];
      merge(state.gift, action.payload);
    },
    updatePayment(state, action: PayloadAction<Partial<CheckoutState['payment']>>) {
      merge(state.payment, action.payload);
    },
    updateRecipient(state, action: PayloadAction<Partial<CheckoutState['recipient']>>) {
      merge(state.recipient, action.payload);
    },
    updateSender(state, action: PayloadAction<Partial<CheckoutState['sender']>>) {
      merge(state.sender, action.payload);
    },
    updateShopifyCheckout(state, action: PayloadAction<Partial<CheckoutState['shopifyCheckout']>>) {
      // Reset array properties to avoid merging array with previous state
      if (action.payload.checkoutUserErrors) state.shopifyCheckout.checkoutUserErrors = [];
      if (action.payload.discountApplications) state.shopifyCheckout.discountApplications = [];
      if (action.payload.lineItems) state.shopifyCheckout.lineItems = [];
      merge(state.shopifyCheckout, action.payload);
    },
  },
});

export default slice.reducer as Reducer<typeof initialState>;

export const {
  clearState,
  resetDiscount,
  resetOrder,
  resetShopifyCheckout,
  setOrderPayload,
  setOrderData,
  updateGift,
  updatePayment,
  updateRecipient,
  updateSender,
  updateShopifyCheckout,
  setCouponCode,
  setProductIds,
} = slice.actions;
