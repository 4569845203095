import React, { useState } from 'react';
import { useAppSelector } from '@/hooks/useStore';
import { selectNavigationBarContent } from '@/redux';
import { GroupedCollection } from '@/types/user';
import { Icon } from '@/components/ui/Icons/Icon';
import { Colors } from '@/constants/Colors';
import { Collapse } from '@/components/ui/Collapse';
import { Typography } from '@/components/ui/Typography';
import { ListItem } from '@/components/ui/NavBar/ListItem';
import { CollectionGroup } from './CollectionGroup';

export const BROWSE_GIFTS_GROUPED_COLLECTIONS_MOBILE_TEST_ID =
  'browse-gifts-grouped-collections-mobile';

interface BrowseGiftsGroupedCollectionsMobileProps {
  onCollectionClick(collection: GroupedCollection): void;
}

export const BrowseGiftsGroupedCollectionsMobile: React.FC<
  BrowseGiftsGroupedCollectionsMobileProps
> = ({ onCollectionClick }) => {
  const [selectedGroup, setSelectedGroup] = useState('');

  const isSelected = (title?: string) => selectedGroup === title;

  const toggleSelectedGroup = (title: string) => {
    setSelectedGroup((prev) => (prev === title ? '' : title));
  };

  const { groupedCollections } = useAppSelector(selectNavigationBarContent);

  return (
    <div
      className="grouped-collections-mobile"
      data-testid={BROWSE_GIFTS_GROUPED_COLLECTIONS_MOBILE_TEST_ID}
    >
      {groupedCollections.map((group) => (
        <div key={group.title} className="grouped-collections-mobile__col">
          <ListItem
            testId={`list-item-for-${group.title}`}
            rightIcon="ChevronIcon"
            rightIconSize={20}
            isSelected={isSelected(group.title)}
            rightIconDir={isSelected(group.title) ? 'down' : 'right'}
            onClick={() => toggleSelectedGroup(group.title)}
          >
            <div className="grouped-collections-mobile__col-title">
              {group.icon && (
                <span className="grouped-collections-mobile__col-title-icon">
                  <Icon name={group.icon} size={16} color={Colors.indigo[700]} />
                </span>
              )}
              <Typography size={16} weight={600} fontFamily="inter">
                {group.title}
              </Typography>
            </div>
          </ListItem>

          {group.collections && (
            <Collapse in={isSelected(group.title)} unmountOnExit>
              <div className="pl-6 pt-3">
                <CollectionGroup group={group.collections} onCollectionClick={onCollectionClick} />
              </div>
            </Collapse>
          )}
        </div>
      ))}

      {/*  Styles  */}

      <style jsx>{`
        .grouped-collections-mobile {
          @apply flex-auto flex flex-col lg:flex-row gap-y-3;

          &__col {
            @apply flex-auto flex flex-col justify-items-stretch;
          }

          &__col-title {
            @apply flex items-center gap-2 pl-5 py-2;
          }

          &__col-title-icon {
            @apply flex-none p-1 bg-indigo-100 rounded-lg;
          }
        }
      `}</style>
    </div>
  );
};
