import React from 'react';
import clsx from 'clsx';

interface SubtitleProps extends React.HTMLAttributes<HTMLSpanElement> {
  tag?: 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
  uppercase?: boolean;
}

export const Subtitle = ({
  tag: Tag = 'h3',
  uppercase = true,
  children,
  className,
}: SubtitleProps): JSX.Element => (
  <Tag className={clsx('subtitle', className, { 'is-uppercase': uppercase })}>
    {children}

    <style jsx>
      {`
        .subtitle {
          @apply text-sm text-primary-700 font-normal break-words;
          @apply font-dm-sans leading-normal sm:leading-5;
          word-break: break-word;

          &.is-uppercase {
            @apply uppercase;
          }
        }
      `}
    </style>
  </Tag>
);
