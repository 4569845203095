export enum TEST_ID {
  // General

  CLOSE_MODAL_BUTTON = 'close-modal-button',
  CLOSE_DRAWER_BUTTON = 'close-drawer-button',

  // Homepage

  HOMEPAGE_PERSONA_SELECT_STICKY_MOBILE = 'homepage-personas-select-filter-mobile',

  HOMEPAGE_BUDGET_DROPDOWN = 'homepage-budget-dropdown',
  HOMEPAGE_BUDGET_DROPDOWN_MOBILE = 'homepage-budget-dropdown-mobile',
  HOMEPAGE_BUDGET_DROPDOWN_OPTION_MOBILE = 'homepage-budget-dropdown-option-mobile',
  HOMEPAGE_BUDGET_DROPDOWN_STICKY_MOBILE = 'homepage-budget-dropdown-sticky-mobile',

  HOMEPAGE_PERSONAS_CHIP = 'homepage-persona-chip',
  HOMEPAGE_PERSONAS_CHIP_MOBILE = 'homepage-persona-chip-mobile',

  HOMEPAGE_COLLECTION_CARD = 'homepage-collection-card',
  HOMEPAGE_COLLECTION_CARD_GIFT_PREVIEW_BUTTON = 'homepage-collection-card-gift-preview-button',
  HOMEPAGE_COLLECTION_CARD_GIFT_PREVIEW_BUTTON_MOBILE = 'homepage-collection-card-gift-preview-button-mobile',
  HOMEPAGE_COLLECTION_CARD_SEND_BUTTON = 'homepage-collection-card-send-button',
  HOMEPAGE_COLLECTION_CARD_SEND_BUTTON_MOBILE = 'homepage-collection-card-send-button-mobile',
  HOMEPAGE_COLLECTION_CARD_CLOSE_BUTTON = 'homepage-collection-card-close-button',

  HOMEPAGE_COLLECTION_CARD_PRODUCT = 'homepage-collection-card-product',
  HOMEPAGE_COLLECTION_CARD_PRODUCT_PREV_ARROW = 'homepage-collection-card-product-prev-arrow',
  HOMEPAGE_COLLECTION_CARD_PRODUCT_NEXT_ARROW = 'homepage-collection-card-product-next-arrow',
  HOMEPAGE_COLLECTION_CARD_PRODUCT_IMAGE = 'homepage-collection-card-product-image',
  HOMEPAGE_COLLECTION_CARD_PRODUCT_IMAGE_PREV_ARROW = 'homepage-collection-card-product-image-prev-arrow',
  HOMEPAGE_COLLECTION_CARD_PRODUCT_IMAGE_NEXT_ARROW = 'homepage-collection-card-product-image-next-arrow',
  HOMEPAGE_COLLECTION_CARD_PRODUCT_CLOSE_BUTTON = 'homepage-collection-card-product-close-button',
  HOMEPAGE_COLLECTION_CARD_ALCOHOL_DISCLAIMER_BUTTON_DESKTOP = 'homepage-collection-card-alcohol-disclaimer',
  HOMEPAGE_COLLECTION_CARD_ALCOHOL_DISCLAIMER_BUTTON_MOBILE = 'homepage-collection-card-alcohol-disclaimer-mobile',

  // Checkout

  CHECKOUT_STEPPER_GIFT_CHOICES = 'checkout-stepper-gift-choices',
  CHECKOUT_STEPPER_PERSONALIZE = 'checkout-stepper-personalize',
  CHECKOUT_STEPPER_ABOUT_YOU = 'checkout-stepper-about-you',
  CHECKOUT_STEPPER_PAY_AND_FINISH = 'checkout-stepper-pay-and-finish',

  CHECKOUT_STEP_PERSONALIZE_CHANGE_GIFT_BUTTON = 'checkout-step-personalize-change-gift-button',
  CHECKOUT_STEP_PERSONALIZE_CHANGE_GIFT_BUTTON_MOBILE = 'checkout-step-personalize-change-gift-button-mobile',
  CHECKOUT_STEP_PERSONALIZE_EXPERIENCE = 'checkout-step-personalize-experience',
  CHECKOUT_STEP_PERSONALIZE_EXPERIENCE_PREV_BUTTON = 'checkout-step-personalize-experience-prev-button',
  CHECKOUT_STEP_PERSONALIZE_EXPERIENCE_NEXT_BUTTON = 'checkout-step-personalize-experience-next-button',
  CHECKOUT_STEP_PERSONALIZE_GREETING_INPUT = 'checkout-step-personalize-greeting-input',
  CHECKOUT_STEP_PERSONALIZE_RECIPIENT_FIRSTNAME_INPUT = 'checkout-step-personalize-recipient-firstname-input',
  CHECKOUT_STEP_PERSONALIZE_RECIPIENT_LASTNAME_INPUT = 'checkout-step-personalize-recipient-lastname-input',
  CHECKOUT_STEP_PERSONALIZE_RECIPIENT_EMAIL_OR_PHONE_INPUT = 'checkout-step-personalize-recipient-email-or-phone-input',
  CHECKOUT_STEP_PERSONALIZE_RECIPIENT_EMAIL_INPUT = 'checkout-step-personalize-recipient-email-input',
  CHECKOUT_STEP_PERSONALIZE_RECIPIENT_PHONE_INPUT = 'checkout-step-personalize-recipient-phone-input',
  CHECKOUT_STEP_PERSONALIZE_GIFT_PREVIEW = 'checkout-step-personalize-gift-preview',
  CHECKOUT_STEP_PERSONALIZE_SCHEDULED_AT_DATE_BUTTON = 'checkout-step-personalize-scheduled-at-date-button',
  CHECKOUT_STEP_PERSONALIZE_SCHEDULED_AT_TIME_BUTTON = 'checkout-step-personalize-scheduled-at-time-button',
  CHECKOUT_STEP_PERSONALIZE_CONTINUE_BUTTON = 'checkout-step-personalize-continue-button',

  CHECKOUT_STEP_ABOUT_YOU_CHANGE_RECIPIENT_BUTTON = 'checkout-step-about-you-change-recipient-button',
  CHECKOUT_STEP_ABOUT_YOU_CHANGE_RECIPIENT_BUTTON_MOBILE = 'checkout-step-about-you-change-recipient-button-mobile',
  CHECKOUT_STEP_ABOUT_YOU_SENDER_FIRSTNAME_INPUT = 'checkout-step-about-you-sender-firstname-input',
  CHECKOUT_STEP_ABOUT_YOU_SENDER_LASTNAME_INPUT = 'checkout-step-about-you-sender-lastname-input',
  CHECKOUT_STEP_ABOUT_YOU_SENDER_EMAIL_INPUT = 'checkout-step-about-you-sender-email-input',
  CHECKOUT_STEP_ABOUT_YOU_CONTINUE_BUTTON = 'checkout-step-about-you-continue-button',
  CHECKOUT_STEP_ABOUT_YOU_GOOGLE_SIGNIN_BUTTON = 'checkout-step-about-you-google-signin-button',
  CHECKOUT_STEP_ABOUT_YOU_SIGNIN_BUTTON = 'checkout-step-about-you-signin-button',

  CHECKOUT_STEP_PAY_AND_FINISH_FAQ_BUTTON = 'checkout-step-pay-and-finish-faq-button',
  CHECKOUT_STEP_PAY_AND_FINISH_SUBMIT_BUTTON = 'checkout-step-pay-and-finish-submit-button',

  // Sign In

  SIGN_IN_BUTTON = 'sign-in-button',

  // Collection landing page

  COLLECTION_SEND_BUTTON = 'collection-send-button',
  COLLECTION_BUDGET_DROPDOWN = 'collection-budget-dropdown',
  COLLECTION_HOW_IT_WORKS_BUTTON = 'collection-how-it-works-button',
  COLLECTION_HOW_IT_WORKS_PREVIEW_RECIPIENT_EXPERIENCE_BUTTON = 'collection-how-it-works-preview-recipient-experience-button',

  // Legacy

  GIFT_CARD = 'gift-card',
  CANCEL_GIFT_BUTTON = 'cancel-gift-button',
  CLOSE_GIFT_MODAL_BUTTON = 'close-gift-modal-button',
  SHARE_GIFT_BUTTON = 'share-gift-button',
}
