export const BUDGETS = [25, 50, 75, 100, 150, 200, 300, 400, 500];

export const INITIAL_BUDGET = BUDGETS[1]; // 50

export const BUDGETS_TO_LOW_RANGE: { [key in (typeof BUDGETS)[number]]: number } = {
  25: 19,
  50: 35,
  75: 60,
  100: 80,
  150: 125,
  200: 170,
  300: 250,
  400: 325,
  500: 400,
};
