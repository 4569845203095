import React from 'react';
import set from 'lodash/set';
import keys from 'lodash/keys';
import cloneDeep from 'lodash/cloneDeep';

/**
 * @description Apply a few refs to a single element
 *
 * @source https://www.davedrinks.coffee/how-do-i-use-two-react-refs/
 *
 * @example <div ref={mergeRefs(ref1, ref2)} />
 */
export const mergeRefs = <T>(
  ...refs: React.Ref<T>[]
): null | React.Ref<T> | ((inst: T) => void) => {
  const filteredRefs = refs.filter(Boolean);

  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 1) return filteredRefs[0];

  return (inst: T) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const ref of filteredRefs) {
      if (typeof ref === 'function') {
        ref(inst);
      } else if (ref) {
        set(ref, 'current', inst);
      }
    }
  };
};

/**
 * @description Get the first key of an object by its truth value
 *
 * @param obj - the object with keys to be searched by value
 */
export const getKeyByValue = <T extends Record<string, unknown>>(obj: T): keyof T | undefined =>
  keys(obj).filter((k) => obj[k])[0];

// a workaround for the ESLint rule "react/no-array-index-key"
export const getObjectsWithIdsFromIndex = (
  numOfItems: number,
  prefix = 'item-'
): { id: string }[] =>
  [...new Array(numOfItems)].map((item, index) => ({
    id: `${prefix}${index}`,
  }));

/**
 * @description Generates pseudo-random numbers based on the seed. Helper for deterministicShuffle
 *
 * @param seed (number} - seed for the random number generator
 */
function lcg(seed: number): number {
  const a = 1664525;
  const c = 1013904223;
  const m = 2 ** 32;

  return (seed * a + c) % m;
}

/**
 * @description Shuffles an array deterministically
 *
 * @param array {T extends Array<unknown>} - array to be shuffled
 * @param seed {number} - seed for the random number generator
 */
export function deterministicShuffle<T extends Array<unknown>>(array: T, seed: number): T {
  let seedCopy = seed;
  const arr = cloneDeep(array);

  for (let i = arr.length - 1; i > 0; i -= 1) {
    seedCopy = lcg(seedCopy);
    const j = seed % (i + 1);
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }

  return arr;
}

/**
 * @description Parses a JWT token
 * @param token {string} - JWT token
 * @returns {any} - parsed token
 */
export const parseJwt = <T extends object>(token: string): T | null => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );

    return JSON.parse(jsonPayload);
  } catch (error) {
    return null;
  }
};
