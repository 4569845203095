import React, { memo } from 'react';

export const FancyBackgroundGlow = memo(() => (
  <>
    <div className="fancy-background-glow" />

    {/* Styles */}

    <style jsx>{`
      .fancy-background-glow {
        @apply absolute inset-0 opacity-50 blur-xl rounded-3xl;
        background: linear-gradient(
          225deg,
          #f8b7ee 0%,
          #f193c7 3.375%,
          #cb7ac3 18.5%,
          #6b96dc 32.25%,
          #3ca6dd 41.15%,
          #56bfe4 50%,
          // double gradient size for animating position
          #f193c7 58.275%,
          #cb7ac3 73.5%,
          #6b96dc 87.25%,
          #3ca6dd 96.15%,
          #56bfe4 100%
        );
        @apply bg-[length:400%_400%];
        @apply animate-[bg-gradient_20s_ease-in-out_infinite];

        @keyframes bg-gradient {
          0% {
            @apply bg-[0%_50%];
          }
          50% {
            @apply bg-[100%_50%];
          }
          100% {
            @apply bg-[0%_50%];
          }
        }
      }
    `}</style>
  </>
));
