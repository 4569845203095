import { Collection } from '@/types/entities/collection';

export const GENERATED_COLLECTION: Collection = {
  id: 'generated-collection',
  handle: 'generated-collection',
  image: {
    id: 'generated-collection-image',
    src: 'https://cdn.shopify.com/s/files/1/0636/9001/4951/files/wizard-snappy.f0b1e736.webp?v=1682344962',
  },
  title: 'AI Generated Collection',
  descriptionHtml: 'AI Generated Collection',
  alcoholic: false,
};
