import React from 'react';
import clsx from 'clsx';
import { useDeviceType } from '@/hooks/useDeviceType';
import { Icon } from '@/components/ui/Icons/Icon';
import { User } from '@/types/user';
import { useUser } from '@/hooks/useUser';
import { Drawer } from '@/components/ui/Drawer';
import { UserBarMenu } from './UserBarMenu';
import { UserBarMobileMenu } from './UserBarMobileMenu';
import { Avatar } from './Avatar';

export const USER_BAR_TEST_ID = 'user-bar';

interface UserBarProps {
  isOpen: boolean;
  userInfo: User;
  onVisibilityChange(isOpen: boolean): void;
  displayName?: boolean;
}

export const UserBar: React.FC<UserBarProps> = ({
  isOpen,
  userInfo,
  onVisibilityChange,
  displayName,
}) => {
  const { isSmallDevice } = useDeviceType();
  const { menuRef, handleClick, handleSignOutClick } = useUser({
    onVisibilityChange,
    isOpen,
  });

  return (
    <button
      ref={menuRef}
      type="button"
      className="user-bar"
      data-testid={USER_BAR_TEST_ID}
      onClick={handleClick}
    >
      <div className="user-bar__content">
        <div className="user-bar__avatar">
          <Avatar size={40} userInfo={userInfo} />
        </div>
        <div className="user-bar__title">
          {displayName && (
            <span className={clsx('user-bar__title-item', 'user-name')}>
              {userInfo.firstname} {userInfo.lastname}
            </span>
          )}
          <span
            className={clsx('user-bar__title-item', 'icon', {
              flipped: isOpen,
            })}
          >
            <Icon name="ChevronTriangleIcon" size={8} direction="down" />
          </span>
        </div>
      </div>

      {isSmallDevice ? (
        <Drawer
          classNameDrawer="navbar-drawer"
          indexOfBackdrop={2}
          classNameBackDrop="navbar-backdrop"
          classNameContainer="user-bar-container"
          withBackdrop
          isContainerOpenAbsolute
          position="top"
          height="max-content"
          withCloseIcon={false}
          isOpen={isOpen}
          onClose={() => onVisibilityChange(false)}
        >
          <UserBarMobileMenu userInfo={userInfo} onSignOutClick={handleSignOutClick} />
        </Drawer>
      ) : (
        <UserBarMenu active={isOpen} onSignOutClick={handleSignOutClick} />
      )}

      {/* Styles */}
      <style jsx>
        {`
          .user-bar {
            @apply flex items-center lg:relative;
            @apply active:opacity-100;

            :global(.user-bar-container) {
              @apply absolute top-full right-0 w-[100vw] overflow-hidden h-[calc(100vh-var(--navbar-height))];
            }

            :global(.navbar-drawer),
            :global(.navbar-backdrop) {
              @apply absolute;
              @apply z-[11] #{!important};
            }

            &__avatar {
              @apply mr-2;
            }
            &__content {
              @apply flex items-center;
            }

            &__title {
              @apply flex items-center mr-1 sm:mr-2;
              @apply text-primary-800 text-base font-medium;

              &-item {
                &.user-name {
                  @apply mr-2;
                }
                &.icon {
                  @apply ease-in duration-100;
                  &.flipped {
                    @apply rotate-180;
                  }
                }
              }
            }
          }
        `}
      </style>
    </button>
  );
};
