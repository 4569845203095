import React, { useCallback, useRef } from 'react';
import { Colors } from '@/constants/Colors';
import { Icon } from '@/components/ui/Icons/Icon';
import { usePressEnterHandler } from '@/hooks/usePressEnterHandler';
import { CSSTransition } from 'react-transition-group';

interface UserBarMenuProps {
  onSignOutClick: () => void;
  active: boolean;
}

export const UserBarMenu: React.FC<UserBarMenuProps> = ({ onSignOutClick, active }) => {
  const handlePressEnter = usePressEnterHandler(onSignOutClick);
  const menuNodeRef = useRef<HTMLDivElement>(null);

  const handleTransitionExiting = useCallback(() => {
    if (menuNodeRef.current) {
      menuNodeRef.current.style.pointerEvents = 'none';
    }
  }, []);

  return (
    <CSSTransition
      appear
      in={active}
      timeout={150}
      unmountOnExit
      style={{ '--duration': '0.15s', '--distance': '10px' }}
      classNames={`slide-${active ? 'down' : 'up'}`}
      nodeRef={menuNodeRef}
      onExiting={handleTransitionExiting}
    >
      <div ref={menuNodeRef} className="user-bar-menu">
        <div className="user-bar-menu__content">
          <div
            role="button"
            tabIndex={0}
            aria-pressed="false"
            className="user-bar-menu__logout-button"
            onClick={onSignOutClick}
            onKeyDown={handlePressEnter}
          >
            <span className="user-bar-menu__logout-button-text">Log Out</span>
            <span className="user-bar-menu__logout-button-icon">
              <Icon name="SignOutIcon" size={24} color={Colors.system.error} />
            </span>
          </div>
        </div>

        {/* Style */}

        <style jsx>
          {`
            .user-bar-menu {
              --menu-width: 171px;

              @apply absolute top-full right-0 z-9 mt-4;
              @apply w-[var(--menu-width)];

              &__content {
                @apply flex flex-col relative p-2;
                @apply bg-white cursor-default;
                @apply rounded-xl shadow-card border border-indigo-300;
              }

              &__logout-button {
                @apply flex w-full items-center border-none;
                @apply p-4;

                &-text {
                  @apply flex-1 text-left;
                  @apply text-base leading-small font-dm-sans;
                  @apply text-system-error font-medium;
                }
              }
            }
          `}
        </style>
      </div>
    </CSSTransition>
  );
};
