import { consumerApi } from '@/redux/api/consumer';
import { createSelector } from '@reduxjs/toolkit';
import { Gift, GiftOrderStatus, ReceivedGift } from '@/types/entities/gift';

export const selectGiftsResultFromAPI = consumerApi.endpoints.gifts.select();
export const selectReceivedGiftsResultFromAPI = consumerApi.endpoints.receivedGifts.select();

export const selectSentTabGifts = createSelector([selectGiftsResultFromAPI], (giftsResult) => ({
  ...giftsResult,
  data: transformGiftsForSentTab(giftsResult.data) ?? [],
}));

export const selectReceivedTabGifts = createSelector(
  [selectReceivedGiftsResultFromAPI],
  (giftsResult) => ({
    ...giftsResult,
    data: transformGiftsForReceivedTab(giftsResult.data) ?? [],
  })
);

export const selectScheduledTabGifts = createSelector(
  [selectGiftsResultFromAPI],
  (giftsResult) => ({ ...giftsResult, data: transformGiftsForScheduledTab(giftsResult.data) ?? [] })
);

export const selectGiftById = (id: string | undefined) =>
  createSelector(
    [selectGiftsResultFromAPI, selectReceivedGiftsResultFromAPI],
    (sentGiftsResult, receivedGiftsResult) =>
      [...(sentGiftsResult.data || []), ...(receivedGiftsResult.data || [])].find(
        (gift) => gift.id === id
      )
  );

export const selectTheRecentGift = createSelector(
  [selectGiftsResultFromAPI, selectReceivedGiftsResultFromAPI],
  (sentGiftsResult, receivedGiftsResult): Gift | ReceivedGift | null =>
    [...(sentGiftsResult.data || []), ...(receivedGiftsResult.data || [])].sort((a, b) => {
      if (!a.createdAt || !b.createdAt) return 0;

      const aDate = new Date(a.createdAt).getTime();
      const bDate = new Date(b.createdAt).getTime();

      return bDate - aDate;
    })[0] ?? null
);

// Helpers

export function transformGiftsForSentTab(gifts: Gift[] | undefined): Gift[] | undefined {
  const filterGifts = (gift: Gift) => {
    const isExpired = !!gift.expiredAt && gift.expiredAt - new Date().getTime() <= 0;

    return [
      gift.status === GiftOrderStatus.CLAIMED,
      gift.status === GiftOrderStatus.FAILED,
      gift.status === GiftOrderStatus.CANCELED,
      gift.status === GiftOrderStatus.SENT && !isExpired,
      gift.status === GiftOrderStatus.EXPIRED || isExpired,
    ].some(Boolean);
  };

  return gifts?.filter(filterGifts).sort(sortGifts);
}

export function transformGiftsForReceivedTab(
  gifts: ReceivedGift[] | undefined
): ReceivedGift[] | undefined {
  const filterGifts = (gift: ReceivedGift) => {
    const isExpired = !!gift.expiredAt && gift.expiredAt - new Date().getTime() <= 0;

    return [
      gift.status === GiftOrderStatus.CLAIMED,
      gift.status === GiftOrderStatus.FAILED,
      gift.status === GiftOrderStatus.CANCELED,
      gift.status === GiftOrderStatus.SENT && !isExpired,
      gift.status === GiftOrderStatus.EXPIRED || isExpired,
    ].some(Boolean);
  };

  return gifts?.map(mapReceivedGiftStatuses).filter(filterGifts).sort(sortGifts);
}

/**
 * @description Map received gift statuses and fix the status of the gift if it has a product.
 *
 * @param {ReceivedGift} gift
 * @returns {ReceivedGift}
 */
function mapReceivedGiftStatuses(gift: ReceivedGift): ReceivedGift {
  if (gift.product) {
    return {
      ...gift,
      status: GiftOrderStatus.CLAIMED,
    };
  }
  return gift;
}

export function transformGiftsForScheduledTab(gifts: Gift[] | undefined): Gift[] | undefined {
  const filterGifts = (gift: Gift) => gift.status === GiftOrderStatus.SCHEDULED;

  return gifts?.filter(filterGifts).sort(sortGifts);
}

function sortGifts(giftA: Gift | ReceivedGift, giftB: Gift | ReceivedGift): number {
  // First sort by latest date between createdAt and claimedAt
  // If both are null, sort by createdAt if exists
  // then sort by claimedAt if exists
  // then sort by expiredAt as fallback
  const getSortTimestamp = (gift: Gift | ReceivedGift) => {
    if (gift.createdAt && gift.claimedAt) {
      return Math.max(gift.createdAt, gift.claimedAt);
    } else if (gift.createdAt) {
      return gift.createdAt;
    } else if (gift.claimedAt) {
      return gift.claimedAt;
    } else if (gift.expiredAt) {
      return gift.expiredAt;
    }
    return 0;
  };

  const timestampA = getSortTimestamp(giftA);
  const timestampB = getSortTimestamp(giftB);

  return timestampB - timestampA;
}
