import { createWrapper } from 'next-redux-wrapper';
import type { Middleware } from '@reduxjs/toolkit';
import { configureStore, isRejectedWithValue } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import * as Sentry from '@sentry/nextjs';
import { showErrorMessageToast } from '@/components/ui/Toast';
import { consumerApi, greetingApi, shopifyApi } from '@/redux';
import { appReducer, rootReducer } from '@/redux/store/rootReducer';
import { getErrorData } from '@/utils/errorHandlers/getErrorData';

/**
 * Handle errors from queries and mutations
 */
const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  // ERROR HANDLING
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood,
  // so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    const { title, description } = getErrorData(action);

    Sentry.withScope((scope) => {
      scope.setTransactionName('RTK Error');
      scope.setExtras(action);
      Sentry.captureException(new Error(`${title} - ${description}`));
    });

    // Show error toast
    showErrorMessageToast({ title, description });
  }

  return next(action);
};

export const setupStore = (preloadedState?: Partial<ReturnType<typeof rootReducer>>) => {
  const store = configureStore({
    preloadedState,
    reducer: ((state, action) => {
      const appState = appReducer(state, action);
      const rootState = rootReducer(appState, action);
      return rootState;
    }) as typeof rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      })
        .concat(shopifyApi.middleware)
        .concat(consumerApi.middleware)
        .concat(greetingApi.middleware)
        .concat(rtkQueryErrorLogger),
  });

  // If it's on client side, create a store which will persist
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  store.__persistor = persistStore(store);

  return store;
};

export const wrapper = createWrapper(() => setupStore());

type Store = ReturnType<typeof setupStore>;

export type RootState = ReturnType<Store['getState']>;
export type AppDispatch = Store['dispatch'];
