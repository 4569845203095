import {
  Control,
  useForm,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormHandleSubmit,
} from 'react-hook-form';
import type { AIGenerateCollectionFormValues } from '../../index';

type UseAIForm = (data?: { defaultValues?: AIGenerateCollectionFormValues }) => {
  control: Control<AIGenerateCollectionFormValues>;
  getValues: UseFormGetValues<AIGenerateCollectionFormValues>;
  handleSubmit: UseFormHandleSubmit<AIGenerateCollectionFormValues>;
  clearErrors: UseFormClearErrors<AIGenerateCollectionFormValues>;
};

export const useAIForm: UseAIForm = ({ defaultValues } = {}) => {
  const { control, handleSubmit, clearErrors, getValues } = useForm<AIGenerateCollectionFormValues>(
    {
      mode: 'onTouched',
      defaultValues: {
        prompt: defaultValues?.prompt ?? '',
        tags: defaultValues?.tags ?? [],
      },
      resolver(values) {
        let error = '';

        if (!values.prompt && !values.tags?.length) {
          error = 'Please enter a prompt or tags';
        }

        return {
          values: error ? {} : values,
          errors: error ? { prompt: { type: 'required', message: error } } : {},
        };
      },
    }
  );

  return {
    control,
    getValues,
    handleSubmit,
    clearErrors,
  };
};
