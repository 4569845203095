import React, { createRef, RefObject, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import clsx from 'clsx';

export const SWITCH_TRANSITION_DURATION_MS = 500;

interface BaseSwitchTransitionProps {
  children: React.ReactNode[];
  activeIndex: number;
  tag?: keyof JSX.IntrinsicElements;
  duration?: number;
  className?: string;
  transitionName?: 'switch-fade' | 'switch-slide-left' | 'switch-slide-right';
}

export const BaseSwitchTransition: React.FC<BaseSwitchTransitionProps> = ({
  tag: Tag = 'div',
  children,
  activeIndex,
  className,
  duration = SWITCH_TRANSITION_DURATION_MS,
  transitionName = 'switch-fade',
}) => {
  const nodeRefs = useRef<RefObject<HTMLDivElement>[]>(children.map(() => createRef()));

  return (
    <Tag className={clsx('switch-transition', className)}>
      {children.map((child, i) => (
        <CSSTransition
          key={String(`item-${i}`)}
          in={i === activeIndex}
          unmountOnExit
          timeout={duration}
          style={{ '--duration': `${duration}ms` }}
          nodeRef={nodeRefs.current[i]}
          classNames={transitionName}
        >
          <div ref={nodeRefs.current[i]} className="switch-transition__item">
            {child}
          </div>
        </CSSTransition>
      ))}

      {/* Styles */}

      <style jsx>{`
        .switch-transition {
          @apply relative flex-auto flex flex-col;

          &__item {
            @apply flex-auto flex flex-col;
          }

          /* switch-fade */
          :global([class*='switch-fade-']) {
            --duration: 0.5s;
          }
          :global(.switch-fade-enter) {
            @apply relative z-2 opacity-0;
          }
          :global(.switch-fade-exit) {
            @apply absolute inset-0 z-1 opacity-100;
          }
          :global(.switch-fade-enter-active) {
            @apply relative z-2 opacity-100;
          }
          :global(.switch-fade-exit-active) {
            @apply absolute inset-0 z-1 opacity-0;
          }
          :global(.switch-fade-enter-active),
          :global(.switch-fade-exit-active) {
            @apply transition-opacity duration-[var(--duration)];
          }

          /* switch-slide-left */
          :global([class*='switch-slide-left-']) {
            --duration: 0.5s;
            --distance: 15px;
            --easing: ease-in-out;
          }
          :global(.switch-slide-left-enter) {
            @apply relative z-2 opacity-0 translate-x-[var(--distance)];
          }
          :global(.switch-slide-left-exit) {
            @apply absolute inset-0 z-1 opacity-100 transform-none;
          }
          :global(.switch-slide-left-enter-active) {
            @apply relative z-2 opacity-100 transform-none;
          }
          :global(.switch-slide-left-exit-active) {
            @apply absolute inset-0 z-1 opacity-0 -translate-x-[calc(var(--distance))];
          }
          :global(.switch-slide-left-enter-active),
          :global(.switch-slide-left-exit-active) {
            transition:
              opacity var(--duration) ease-in-out,
              transform var(--duration) var(--easing);
          }

          /* switch-slide-right */
          :global([class*='switch-slide-right-']) {
            --duration: 0.5s;
            --distance: 15px;
            --easing: ease-in-out;
          }
          :global(.switch-slide-right-enter) {
            @apply relative z-2 opacity-0 -translate-x-[calc(var(--distance))];
          }
          :global(.switch-slide-right-exit) {
            @apply absolute inset-0 z-1 opacity-100 transform-none;
          }
          :global(.switch-slide-right-enter-active) {
            @apply relative z-2 opacity-100 transform-none;
          }
          :global(.switch-slide-right-exit-active) {
            @apply absolute inset-0 z-1 opacity-0 translate-x-[var(--distance)];
          }
          :global(.switch-slide-right-enter-active),
          :global(.switch-slide-right-exit-active) {
            transition:
              opacity var(--duration) ease-in-out,
              transform var(--duration) var(--easing);
          }
        }
      `}</style>
    </Tag>
  );
};
