/* eslint indent: 0 */
import * as Sentry from '@sentry/nextjs';
import { showErrorMessageToast } from '@/components/ui/Toast';
import type { CatchAndHandle } from '@/utils/errorHandlers/catchAndHandle';
import { catchAndHandle } from '@/utils/errorHandlers/catchAndHandle';

type CatchAndNotify<F, D> = Omit<CatchAndHandle<F, D>, 'errorHandler'> & {
  errorMessage?: string;
  errorDescription?: string;
};

/**
 * Handles errors on any wrapped function and shows a toast with the error message and description.
 * It adds errorMessage and errorDescription messages when an error occurs in response parsing.
 */
export const catchAndNotify = <
  F extends (...args: Parameters<F>) => ReturnType<F> | Promise<ReturnType<F>>,
>({
  fn,
  errorMessage,
  errorDescription,
  defaultValue,
  shouldThrow,
}: CatchAndNotify<F, ReturnType<F>>) =>
  catchAndHandle({
    fn,
    errorHandler: (error) => _handleError(error, { errorMessage, errorDescription }),
    defaultValue,
    shouldThrow,
  });

// Helpers

function _handleError(
  error: Error,
  { errorMessage, errorDescription }: { errorMessage?: string; errorDescription?: string } = {}
) {
  // ERROR HANDLING
  // Show toast
  showErrorMessageToast({
    title: errorMessage ?? 'Oops...',
    description: errorDescription ?? (error.message || 'Seems something went wrong'),
  });
  // Log to Sentry
  Sentry.withScope((scope) => {
    scope.setTransactionName('catchAndNotify');
    Sentry.captureException(error);
  });
}
