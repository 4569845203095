export enum Gender {
  HIM = 'him',
  HER = 'her',
}

export enum Age {
  NEWBORN = 'newborn',
  YOUNG_TODDLER = 'young toddler',
  TODDLER = 'toddler',
  CHILD = 'child',
  TWEEN = 'tween',
  TEEN = 'teen',
}
