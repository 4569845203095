import React, { useRef } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

export const SLIDE_SWITCH_TRANSITION_DURATION_MS = 300;

interface SlideSwitchTransitionProps {
  children: React.ReactNode;
  activeKey: number | string | boolean | null | undefined;
  appear?: boolean;
  duration?: number;
  direction?: 'up' | 'down';
  tag?: 'div' | 'li' | 'span';
  style?: React.CSSProperties;
  className?: string;
}

export const SlideSwitchTransition: React.FC<SlideSwitchTransitionProps> = ({
  tag: Tag = 'div',
  children,
  activeKey,
  style,
  className,
  appear = true,
  direction = 'up',
  duration = SLIDE_SWITCH_TRANSITION_DURATION_MS,
}) => {
  const nodeRef = useRef<HTMLDivElement & HTMLLIElement>(null);

  return (
    <>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={String(activeKey)}
          appear={appear}
          classNames="switch-slide"
          style={{
            ...style,
            '--duration': `${duration}ms`,
            '--delay': `${duration / 3}ms`,
            '--distance': direction === 'up' ? '20px' : '-20px',
          }}
          nodeRef={nodeRef}
          timeout={duration}
          unmountOnExit
          addEndListener={(done) => setTimeout(done, duration)}
        >
          <Tag ref={nodeRef} className={className}>
            {children}
          </Tag>
        </CSSTransition>
      </SwitchTransition>

      {/* Styles */}

      <style jsx global>{`
        /* slide-up */
        [class*='switch-slide-'] {
          --duration: ${SLIDE_SWITCH_TRANSITION_DURATION_MS}ms;
          --delay: ${SLIDE_SWITCH_TRANSITION_DURATION_MS / 3}ms;
          --distance: 20px;
        }
        .switch-slide-appear,
        .switch-slide-enter {
          opacity: 0;
          transform: translateY(var(--distance));
        }
        .switch-slide-enter-active,
        .switch-slide-exit {
          opacity: 1;
          transform: none;
        }
        .switch-slide-exit-active {
          opacity: 0;
          transform: translateY(calc(var(--distance) * -1));
        }
        .switch-slide-appear-active,
        .switch-slide-enter-active {
          transition:
            opacity calc(var(--duration) - var(--delay)) linear,
            transform calc(var(--duration) - var(--delay)) cubic-bezier(0.2, 1.4, 0.5, 0.98);
        }
        .switch-slide-exit-active {
          transition:
            opacity calc(var(--duration) - var(--delay)) linear var(--delay),
            transform calc(var(--duration) - var(--delay)) ease-in var(--delay);
        }
      `}</style>
    </>
  );
};
