import React, { forwardRef, HTMLAttributeAnchorTarget, useCallback, useMemo } from 'react';
import Link, { LinkProps } from 'next/link';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { UrlObject } from 'node:url';
import { ParsedUrlQueryInput } from 'node:querystring';
import isObject from 'lodash/isObject';
import every from 'lodash/every';
import keys from 'lodash/keys';

export interface BaseLinkProps extends LinkProps {
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  tabIndex?: number;
  target?: HTMLAttributeAnchorTarget;
  testId?: string;
  title?: string;
  style?: React.CSSProperties;
  ariaLabel?: string;
  strictActiveCheck?: boolean; // if true, the link will be active only if query params are equal
  onMouseLeave?(e: React.MouseEvent<HTMLAnchorElement>): void;
  onTouchEnd?(e: React.TouchEvent<HTMLAnchorElement>): void;
  onFocus?(e: React.FocusEvent<HTMLAnchorElement>): void;
  onBlur?(e: React.FocusEvent<HTMLAnchorElement>): void;
}

export const BaseLink: React.FC<BaseLinkProps> = forwardRef<HTMLAnchorElement, BaseLinkProps>(
  (
    {
      children,
      className,
      disabled,
      onClick,
      onMouseEnter,
      onMouseLeave,
      onTouchStart,
      onTouchEnd,
      onFocus,
      onBlur,
      tabIndex,
      target,
      testId,
      title,
      style,
      ariaLabel,
      strictActiveCheck,
      ...props
    },
    ref
  ) => {
    const router = useRouter();

    const linkPath = isObject(props.href) ? String(props.href.pathname) : getHrefPath(props.href);
    const linkQuery = getQueryFromHref(props.href);

    const currPath = router.pathname;
    const currQuery = parsePrimitivesInQuery(router.query);

    const href = useMemo(() => buildHrefByQuery(linkPath, linkQuery), [linkPath, linkQuery]);
    const currentHref = useMemo(() => buildHrefByQuery(currPath, currQuery), [currPath, currQuery]);

    const isPathActive = currentHref === href;
    const active = strictActiveCheck
      ? isPathActive && checkQueryEquality(linkQuery, currQuery)
      : isPathActive;

    const handleCLick = useCallback(
      (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (disabled) {
          e.preventDefault();
          return;
        }
        onClick?.(e);
      },
      [disabled, onClick]
    );

    return (
      <Link
        {...props}
        ref={ref}
        role="link"
        rel="noreferrer"
        className={clsx('base-link', className, { active, disabled })}
        data-testid={testId}
        aria-label={ariaLabel}
        tabIndex={disabled ? -1 : tabIndex}
        target={target}
        title={title}
        style={style}
        onClick={handleCLick}
        onFocus={onFocus}
        onBlur={onBlur}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
      >
        {children}

        <style jsx>{`
          :global(a.base-link) {
            --disabled-opacity: 0.5;

            &.disabled {
              @apply opacity-[var(--disabled-opacity)] cursor-not-allowed;
            }
          }
        `}</style>
      </Link>
    );
  }
);

// Helpers

function buildHrefByQuery(pathname: string, query?: ParsedUrlQueryInput | string): string {
  if (!query) return pathname;

  // Get query object from object or query string
  /* eslint-disable indent */
  const queryObj = isObject(query)
    ? query
    : query
        .replace(/^\?/, '')
        .split('&')
        .reduce((acc, param) => {
          const [key, value] = param.split('=');
          acc[key] = value;
          return acc;
        }, {} as ParsedUrlQueryInput);
  /* eslint-enable indent */

  // Replace pathname dynamic params with query values
  const href = keys(queryObj).reduce(
    (acc, key) => acc.replace(`[${key}]`, String(queryObj[key])),
    pathname
  );

  return href;
}

function parseQueryFromHref(href: string): ParsedUrlQueryInput {
  const query = href.split('?')[1] ?? '';
  if (!query) return {};

  const queryObj = query.split('&').reduce((acc, param) => {
    const [key, value] = param.split('=');
    acc[key] = value;
    return acc;
  }, {} as ParsedUrlQueryInput);

  return queryObj;
}

function getQueryFromHref(href: UrlObject | string): ParsedUrlQueryInput | undefined {
  const parseQuery = (query: UrlObject['query']) =>
    typeof query === 'string' ? parseQueryFromHref(`?${query}`) : query ?? undefined;

  return isObject(href) ? parseQuery(href.query) : parseQueryFromHref(href);
}

function parsePrimitivesInQuery(query: ParsedUrlQueryInput | undefined): ParsedUrlQueryInput {
  if (!query) return {};

  const parsePrimitive = (val: unknown): unknown => {
    if (Array.isArray(val)) return val.map(parsePrimitive);

    if (val === 'true') return true;
    if (val === 'false') return false;
    if (val === 'null') return null;
    if (val === 'undefined') return undefined;
    if (Number.isFinite(Number(val))) return Number(val);

    return val;
  };

  return keys(query).reduce((acc, key) => {
    acc[key] = parsePrimitive(query[key]) as never;
    return acc;
  }, {} as ParsedUrlQueryInput);
}

function getHrefPath(href: string): string {
  return href?.split('?')[0] ?? href;
}

function checkQueryEquality(
  linkQuery: ParsedUrlQueryInput | undefined,
  currQuery: ParsedUrlQueryInput | undefined
): boolean {
  return every(linkQuery, (val, key) => currQuery?.[key] === val);
}
