import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';

const DEFAULT_SPEED = 100;
const DEFAULT_TAP_DELAY_MS = 200;
const DEFAULT_AUTOSCROLL_RESUME_MS = 300;
const DEFAULT_SCROLL_DURATION_MS = 500;

type UseHorizontalDraggableListConfig = (config?: {
  autoScroll?: boolean;
  // pixels per second
  speed?: number;
  isAutoScrolling?: boolean;
  // debounce time to resume auto-scrolling after user interaction (scrolling, dragging)
  autoScrollResumeMs?: number;
  // subscribe/unsubscribe from events will be more optimized after the animations.
  enableAfterMs?: number;
}) => {
  dragging: boolean;
  hasReachedEnd: boolean;
  hasReachedStart: boolean;
  scrolling: boolean;
  scrollBy: (px: number) => void;
  listRef: RefObject<HTMLUListElement & HTMLDivElement>;
};

export const useHorizontalDraggableList: UseHorizontalDraggableListConfig = (config) => {
  const shouldAutoScroll = config?.autoScroll ?? false;
  const speed = config?.speed ?? DEFAULT_SPEED;
  const enableAfterMs = config?.enableAfterMs ?? 0;
  const autoScrollResumeMs = config?.autoScrollResumeMs ?? DEFAULT_AUTOSCROLL_RESUME_MS;
  const isAutoScrolling = config?.isAutoScrolling ?? false;

  const listRef = useRef<HTMLUListElement & HTMLDivElement>(null);
  const prevPageX = useRef(0);
  const prevScrollLeft = useRef(0);
  const animationFrameRef = useRef(0);
  const animationLastFrameTime = useRef(0);
  const animationDebounceTimeout = useRef(0);
  const scrollDebounceTimeout = useRef(0);
  const currentScrollX = useRef(0);
  const scrollByTimeoutRef = useRef(0);
  const enableAfterTimeoutRef = useRef(0);

  const [dragging, setDragging] = useState(false);
  const [scrolling, setScrolling] = useState(false);

  const [hasReachedStart, setHasReachedStart] = useState(false);
  const [hasReachedEnd, setHasReachedEnd] = useState(false);

  // Methods

  const setHasReachedStartAndEnd = useCallback(() => {
    const isStart = listRef.current ? listRef.current.scrollLeft === 0 : true;
    const isEnd = listRef.current
      ? listRef.current.scrollLeft === listRef.current.scrollWidth - listRef.current.offsetWidth
      : false;

    setHasReachedStart(isStart);
    setHasReachedEnd(isEnd);
  }, []);

  const scrollList = (options: {
    left?: number;
    top?: number;
    behavior?: 'auto' | 'smooth';
  }): void => {
    listRef.current?.scrollTo(options);
  };

  const abortSlidingLeftAnimation = useCallback(() => {
    window.cancelAnimationFrame(animationFrameRef.current);
    window.clearTimeout(animationDebounceTimeout.current);
    animationFrameRef.current = 0;
    animationLastFrameTime.current = 0;
  }, []);

  const scrollBy = (px: number) => {
    const currentScrollLeft = listRef.current?.scrollLeft ?? 0;
    abortSlidingLeftAnimation();
    scrollList({ left: currentScrollLeft + px, behavior: 'smooth' });

    if (shouldAutoScroll && isAutoScrolling) {
      clearTimeout(scrollByTimeoutRef.current);
      scrollByTimeoutRef.current = 0;
      scrollByTimeoutRef.current = window.setTimeout(
        () => startSlidingLeftAnimation(),
        DEFAULT_SCROLL_DURATION_MS
      );
    }
  };

  const animateSlidingLeft = () => {
    animationFrameRef.current = requestAnimationFrame((timestamp: number) => {
      if (!listRef.current) return;

      const timeElapsed = timestamp - (animationLastFrameTime.current || timestamp);
      const deltaX = (timeElapsed / 1000) * speed;
      const left = currentScrollX.current + deltaX;

      scrollList({ left: Math.round(left) });

      // Save the current scroll position with fractional pixels to make "speed" work correctly.
      currentScrollX.current = left;

      // update last frame timestamp for subsequent delta calculations
      animationLastFrameTime.current = timestamp;

      animateSlidingLeft();
    });
  };

  const startSlidingLeftAnimation = () => {
    currentScrollX.current = listRef.current?.scrollLeft ?? 0;
    abortSlidingLeftAnimation();
    animationDebounceTimeout.current = window.setTimeout(animateSlidingLeft, autoScrollResumeMs);
  };

  // Dragging

  const setDebounceStartDragging = debounce((isDrag: boolean) => {
    setDragging(isDrag);
  }, DEFAULT_TAP_DELAY_MS);

  // Effects

  useEffect(() => {
    if (shouldAutoScroll && isAutoScrolling) {
      startSlidingLeftAnimation();
    }
  }, [isAutoScrolling, shouldAutoScroll]);

  useEffect(() => {
    setHasReachedStartAndEnd();

    const handleMouseDown = (e: MouseEvent) => {
      if (!listRef.current) return;
      abortSlidingLeftAnimation();
      prevScrollLeft.current = listRef.current.scrollLeft;
      prevPageX.current = e.pageX;
      setDebounceStartDragging(true);
    };

    const handleMouseUp = () => {
      prevPageX.current = 0;
      if (shouldAutoScroll) {
        startSlidingLeftAnimation();
      }
      setDebounceStartDragging(false);
    };

    const handleMouseMove = (e: MouseEvent) => {
      if (!prevPageX.current) return;
      const scrollX = prevScrollLeft.current + prevPageX.current - e.pageX;
      scrollList({ left: scrollX });
    };

    const handleScroll = () => {
      currentScrollX.current = listRef.current?.scrollLeft ?? 0;
      setHasReachedStartAndEnd();
      setScrolling(true);
      clearTimeout(scrollDebounceTimeout.current);
      scrollDebounceTimeout.current = 0;
      scrollDebounceTimeout.current = window.setTimeout(() => {
        setScrolling(false);
      }, 100);
    };

    enableAfterTimeoutRef.current = window.setTimeout(() => {
      listRef.current?.addEventListener('mousedown', handleMouseDown);
      listRef.current?.addEventListener('mousemove', handleMouseMove);
      listRef.current?.addEventListener('mouseup', handleMouseUp);
      listRef.current?.addEventListener('scroll', handleScroll);
    }, enableAfterMs);

    return () => {
      clearTimeout(scrollByTimeoutRef.current);
      clearTimeout(scrollDebounceTimeout.current);
      clearTimeout(enableAfterTimeoutRef.current);
      clearTimeout(animationDebounceTimeout.current);
      cancelAnimationFrame(animationFrameRef.current);

      listRef.current?.removeEventListener('mousedown', handleMouseDown);
      listRef.current?.removeEventListener('mousemove', handleMouseMove);
      listRef.current?.removeEventListener('mouseup', handleMouseUp);
      listRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, [listRef.current]);

  return {
    dragging,
    hasReachedEnd,
    hasReachedStart,
    listRef,
    scrolling,
    scrollBy,
  };
};
