import React, { useRef } from 'react';
import posthog from 'posthog-js';
import { useSignOut } from '@/hooks/useSignOut';
import { useOnClickOutside } from '@/hooks/useOnClickOutside';
import { useDeviceType } from '@/hooks/useDeviceType';

// Types

interface useUserProps {
  onVisibilityChange: (value: boolean) => void;
  isOpen: boolean;
}

interface UseUser {
  menuRef: React.RefObject<HTMLButtonElement>;
  handleClick: () => void;
  handleSignOutClick: () => void;
}

export const useUser = ({ onVisibilityChange, isOpen }: useUserProps): UseUser => {
  const { isDesktop } = useDeviceType();

  const menuRef = useRef<HTMLButtonElement>(null);
  const signUserOut = useSignOut();

  const handleClick = () => {
    onVisibilityChange(!isOpen);
  };

  const handleClickOutside = () => {
    if (isDesktop) {
      onVisibilityChange(false);
    }
  };

  const handleSignOutClick = async () => {
    posthog.reset(true);
    await signUserOut();
  };

  useOnClickOutside(menuRef, handleClickOutside);

  return {
    handleClick,
    handleSignOutClick,
    menuRef,
  };
};
