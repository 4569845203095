import { useRouter } from 'next/router';
import React, { memo } from 'react';
import { useAppSelector } from '@/hooks/useStore';
import { selectReceivedTabGifts } from '@/redux/api/selectors/gifts';
import { MainBar } from './MainBar';
import { BannerB2B } from './BannerB2B';

export const NavBar = memo(() => {
  const router = useRouter();
  const { isLoading } = useAppSelector(selectReceivedTabGifts);
  const isHome = router.pathname === '/' || router.pathname === '/home';
  const isTYN = router.pathname === '/my-gifts' && router.query.token && isLoading;
  if (isTYN) return null;

  return (
    <nav className="navbar">
      {isHome && (
        <div className="navbar__banner-container">
          <div className="navbar__banner-inner">
            <BannerB2B />
          </div>
        </div>
      )}

      <div className="navbar__container">
        <MainBar />
      </div>

      {/*  Styles  */}

      <style jsx>{`
        .navbar {
          --navbar-height-desktop: 84px;
          --navbar-height-mobile: 56px;
          --navbar-max-width: 1440px;
          --navbar-banner-height: ${isHome ? 40 : 0}px;

          --navbar-height: calc(var(--navbar-height-mobile) + var(--navbar-banner-height));
          @screen lg {
            --navbar-height: calc(var(--navbar-height-desktop) + var(--navbar-banner-height));
          }

          @apply relative flex-none flex flex-col bg-white h-[var(--navbar-height)];
          @apply border-b border-b-white;
          @apply animate-[border-appear_1s_linear_forwards];

          @keyframes border-appear {
            from {
              @apply border-b border-b-white;
            }
            to {
              @apply border-b border-b-indigo-300;
            }
          }

          &__banner-container {
            @apply flex-none flex items-stretch max-w-full bg-purple-200;
          }

          &__banner-inner {
            @apply flex-none flex h-[var(--navbar-banner-height)] w-[var(--navbar-max-width)] max-w-full;
            @apply mx-auto px-6 lg:px-14;
          }

          &__container {
            @apply flex-auto flex flex-col max-w-full;
            @apply w-[var(--navbar-max-width)] mx-auto lg:px-14;
          }
        }
      `}</style>
    </nav>
  );
});
