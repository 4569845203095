/* eslint no-param-reassign: 0 */

// Slice

import { createSlice, PayloadAction, Reducer } from '@reduxjs/toolkit';
import assign from 'lodash/assign';
import type { RootState } from '@/redux/store';
import { INITIAL_BUDGET } from '@/constants/Budgets';
import { ALL_TAG } from '@/components/homepage-v1/components/PersonasChips';
import { UserFlags } from '@/types/user';
import { Age, Gender } from '@/types/shopify/shopify-tags';
import { ProductsPriceRange } from '@/types/entities/gift-order';
import { getBudgetMin } from '@/utils/collectionHelpers';
import { FEATURED_COLLECTIONS, GROUPED_COLLECTIONS } from '@/constants/NavigationBar';

// Types

type ProductsPriceRangeWithOptionalMin = Pick<Partial<ProductsPriceRange>, 'min'> &
  Omit<ProductsPriceRange, 'min'>;

interface UserFlagsState {
  flags: UserFlags;
  loading: boolean;
}

export interface ActiveState {
  activeCollectionId: string;
  activeBudgetRange: ProductsPriceRange;
  activePersona: string;
  activeAITags: string[];
  activeProductIds: string[];
  activeAge: Age | null;
  activeGiftId: string | null;
  activeGender: Gender | null;
  userFlags: UserFlagsState;
}

const initialState: ActiveState = {
  activeCollectionId: '',
  activePersona: ALL_TAG,
  activeAge: null,
  activeGender: null,
  activeBudgetRange: { max: INITIAL_BUDGET, min: getBudgetMin(INITIAL_BUDGET) },
  activeAITags: [],
  activeProductIds: [],
  activeGiftId: null,
  userFlags: {
    loading: true,
    flags: {
      navigationBarContent: {
        featuredCollections: FEATURED_COLLECTIONS,
        groupedCollections: GROUPED_COLLECTIONS,
      },
    },
  },
};

const activeState = createSlice({
  name: 'activeState',
  initialState,
  reducers: {
    setActiveCollectionId(state, action: PayloadAction<string | null>) {
      state.activeCollectionId = action.payload ?? '';
    },

    setActiveBudgetRange(state, action: PayloadAction<ProductsPriceRangeWithOptionalMin>) {
      const { min: optionalMin, max } = action.payload;
      const min = optionalMin ?? getBudgetMin(max);

      state.activeBudgetRange = { min, max };
    },

    setActivePersona(state, action: PayloadAction<string>) {
      state.activePersona = action.payload;
    },

    setActiveAITags(state, action: PayloadAction<string[]>) {
      state.activeAITags = action.payload;
    },

    setActiveProductIds(state, action: PayloadAction<string[]>) {
      state.activeProductIds = action.payload;
    },

    setActiveAge(state, action: PayloadAction<Age | null>) {
      state.activeAge = action.payload;
    },

    setActiveGender(state, action: PayloadAction<Gender | null>) {
      state.activeGender = action.payload;
    },

    setActiveGiftId(state, action: PayloadAction<string | null>) {
      state.activeGiftId = action.payload;
    },

    setUserFlags(state, action: PayloadAction<UserFlagsState>) {
      const { flags: previousFlags, loading: previousLoading } = state.userFlags;
      const { flags, loading } = action.payload;

      state.userFlags = {
        flags: assign(previousFlags, flags),
        loading: loading ?? previousLoading,
      };
    },

    setUserFlagsLoading(state, action: PayloadAction<boolean>) {
      state.userFlags.loading = action.payload;
    },
  },
});

// Selectors

export const selectActiveBudgetRange = (state: RootState) => state.activeState.activeBudgetRange;

export const selectActiveAITags = (state: RootState) => state.activeState.activeAITags;
export const selectActiveProductIds = (state: RootState) => state.activeState.activeProductIds;
export const selectActiveAge = (state: RootState) => state.activeState.activeAge;
export const selectActiveGender = (state: RootState) => state.activeState.activeGender;
export const selectActivePersona = (state: RootState) => state.activeState.activePersona;
export const selectActiveGiftId = (state: RootState) => state.activeState.activeGiftId;
export const selectActiveCollectionId = (state: RootState) => state.activeState.activeCollectionId;
export const selectUserFlags = (state: RootState) => state.activeState.userFlags;
export const selectNavigationBarContent = (state: RootState) =>
  state.activeState.userFlags.flags.navigationBarContent;

export const {
  setActiveCollectionId,
  setActiveBudgetRange,
  setActiveAITags,
  setActiveProductIds,
  setActiveAge,
  setActiveGender,
  setActivePersona,
  setUserFlags,
  setActiveGiftId,
  setUserFlagsLoading,
} = activeState.actions;

export default activeState.reducer as Reducer<typeof initialState>;
