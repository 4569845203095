import React, { useEffect } from 'react';
import clsx from 'clsx';
import { TEST_ID } from '@/constants/Tests';
import { lockDocumentBodyScroll } from '@/utils/browser';
import { CloseButton } from '@/components/ui/CloseButton';

export interface DrawerProps {
  children: React.ReactNode;
  onClose(): void;
  height?: string | number;
  indexOfBackdrop?: number;
  isContainerOpenAbsolute?: boolean;
  isOpen?: boolean;
  classNameContainer?: string;
  classNameDrawer?: string;
  classNameBackDrop?: string;
  classNameCloseBtn?: string;
  lockScrollOnOpening?: boolean;
  position?: 'top' | 'bottom' | 'left' | 'right';
  width?: string | number;
  withBackdrop?: boolean;
  withCloseIcon?: boolean;
  testId?: string;
}

export const Drawer: React.FC<DrawerProps> = ({
  isOpen,
  children,
  classNameContainer,
  classNameDrawer,
  classNameBackDrop,
  classNameCloseBtn,
  height = '100%',
  isContainerOpenAbsolute = false,
  indexOfBackdrop = 0,
  lockScrollOnOpening = true,
  onClose,
  position = 'right',
  width = '100%',
  withBackdrop = true,
  withCloseIcon = true,
  testId,
}) => {
  useEffect(() => {
    if (!lockScrollOnOpening) return;

    lockDocumentBodyScroll(!!isOpen);
  }, [isOpen]);

  useEffect(() => () => lockDocumentBodyScroll(false), []);

  return (
    <div
      aria-hidden={isOpen ? 'false' : 'true'}
      data-testid={testId}
      className={clsx('drawer-container', classNameContainer, {
        open: isOpen,
        'is-container-abs-pos': isContainerOpenAbsolute && isOpen,
      })}
    >
      {withBackdrop && (
        <div
          style={{ zIndex: indexOfBackdrop }}
          className={clsx('backdrop', classNameBackDrop)}
          role="button"
          aria-label="clickable background of the drawer"
          onClick={onClose}
        />
      )}
      <div
        className={clsx('drawer', classNameDrawer, position, {
          'is-container-abs-pos': isContainerOpenAbsolute && isOpen,
        })}
        role="dialog"
        style={{ width, height }}
      >
        <div className="overflow-y-auto flex flex-col flex-auto">{children}</div>
        {withCloseIcon && (
          <div className="close-btn-drawer">
            <CloseButton
              className={clsx('close-button-wrapper', classNameCloseBtn)}
              testId={TEST_ID.CLOSE_DRAWER_BUTTON}
              onClick={onClose}
            />
          </div>
        )}
      </div>
      <style jsx>
        {`
          .close-btn-drawer {
            :global(.close-button-wrapper) {
              @apply absolute top-3 right-3 z-[9999] cursor-pointer;
              @apply flex items-center justify-center;
            }
          }

          .drawer-container {
            --transition-speed: 0.3s;
            @apply pointer-events-none;

            &.open.is-container-abs-pos {
              @apply absolute;
            }
          }

          .drawer.right {
            @apply top-0 right-0 translate-x-[100%];
          }

          .drawer {
            @apply flex flex-col bg-white h-full max-h-full overflow-hidden fixed z-[1000] pointer-events-auto;
            @apply duration-[var(--transition-speed)] ease-in-out;
            &.is-container-abs-pos {
              @apply absolute;
            }
          }

          .drawer.left {
            @apply top-0 left-0 -translate-x-full;
          }

          .drawer.right {
            @apply top-0 right-0 translate-x-full;
          }

          .drawer.top {
            @apply top-0 left-0 right-0 w-full -translate-y-full h-full;
          }

          .drawer.bottom {
            @apply bottom-0 left-0 right-0 w-full translate-y-full;
          }

          .drawer-container.open .top,
          .drawer-container.open .bottom {
            @apply translate-y-0;
          }

          .drawer-container.open .right,
          .drawer-container.open .left {
            @apply translate-x-0;
          }

          .drawer-container.open .backdrop {
            @apply visible opacity-80 pointer-events-auto z-[999] bg-primary-500;
          }

          .backdrop {
            @apply invisible opacity-0 bg-primary-500 ease-in;
            @apply transition-all duration-[var(--transition-speed)] ease-in h-full w-full top-0 left-0 fixed;
            @apply z-0 pointer-events-none;
          }
        `}
      </style>
    </div>
  );
};
