import { createApi, retry } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import { HYDRATE } from 'next-redux-wrapper';
import {
  checkoutCreateMutation,
  checkoutDiscountCodeApplyMutation,
  checkoutDiscountCodeRemoveMutation,
  checkoutEmailUpdate,
  checkoutLineItemsReplace,
} from '@/redux/mutations/checkout';
import { categorizedCollectionsQuery } from '@/redux/queries/categorizedCollections';
import { collectionByHandleQuery } from '@/redux/queries/collectionByHandle';
import { collectionByIdQuery } from '@/redux/queries/collectionById';
import { collectionsQuery } from '@/redux/queries/collections';
import { personasQuery } from '@/redux/queries/personas';
import { productThumbnailsQuery } from '@/redux/queries/productThumbnails';
import {
  createDynamicProductsByIdsQuery,
  createDynamicProductsByTagsQuery,
  productsByCollectionHandleQuery,
  productsQuery,
  productVariantsQuery,
} from '@/redux/queries/products';
import {
  CategorizedCollection,
  Collection,
  CollectionWithLandingPage,
} from '@/types/entities/collection';
import { Persona } from '@/types/entities/persona';
import { Product, ProductResponse } from '@/types/entities/product';
import { Age, Gender } from '@/types/shopify/shopify-tags';
import { catchAndNotify, transformErrorResponse } from '@/utils/errorHandlers';
import { catchAndLog } from '@/utils/errorHandlers/catchAndLog';
import { KEEP_UNUSED_RTK_QUERY_DATA_FOR } from '@/constants/Values';
import {
  mapCategorizedCollectionsFromResponse,
  mapCollectionsFromResponse,
  mapCollectionWithLandingPageFromResponse,
  mapGenericCheckoutResponse,
  mapPersonasFromResponse,
  mapProductsByIdsWithMetaFromResponse,
  mapProductsThumbnailsWithMetaFromResponse,
  mapProductsWithMetaByTagsFromResponse,
  mapProductsWithMetaFromResponse,
  mapProductVariantsFromResponse,
  ProductsResponseWithCollectionId,
} from './utils';

// Types
interface CollectionIdWithProducts {
  nodes: ProductsResponseWithCollectionId[];
}

interface Price {
  min: number;
  max: number;
}

// Create API method

export const shopifyApi = createApi({
  reducerPath: 'shopifyApi',
  keepUnusedDataFor: KEEP_UNUSED_RTK_QUERY_DATA_FOR,
  // Retry to error with 3 max retires
  baseQuery: retry(
    graphqlRequestBaseQuery({
      url: `${process.env.NEXT_PUBLIC_SHOPIFY_DOMAIN_JSON}`,
      requestHeaders: {
        'X-Shopify-Storefront-Access-Token': `${process.env.NEXT_PUBLIC_SHOPIFY_STORE_FRONT_ACCESS_TOKEN}`,
      },
    }),
    {
      maxRetries: 3,
    }
  ),
  endpoints: (builder) => ({
    //  Query: Get All Collections
    collections: builder.query<Collection[], void>({
      query: () => ({
        document: collectionsQuery,
      }),
      transformResponse: catchAndNotify({
        fn: (data: { collections: Shopify.CollectionsWithProductsResponse['collections'] }) =>
          mapCollectionsFromResponse(data.collections),
        errorMessage: '"Collections" didn\'t load properly.',
        defaultValue: [],
      }),
    }),
    categorizedCollections: builder.query<CategorizedCollection[], void>({
      query: () => ({
        document: categorizedCollectionsQuery,
      }),
      transformResponse: catchAndNotify({
        fn: (data: { collections: Shopify.CollectionsWithProductsResponse['collections'] }) =>
          mapCategorizedCollectionsFromResponse(data.collections),
        errorMessage: '"Collections" didn\'t load properly.',
        defaultValue: [],
      }),
    }),
    collectionById: builder.query<CollectionWithLandingPage | null, string>({
      query: (id) => ({
        document: collectionByIdQuery,
        variables: {
          id,
        },
      }),
      transformResponse: catchAndNotify({
        fn: (data: Shopify.CollectionByHandleResponse) =>
          mapCollectionWithLandingPageFromResponse(data),
        errorMessage: 'Failed to load collection',
      }),
    }),
    collectionByHandle: builder.query<CollectionWithLandingPage | null, string>({
      query: (handle) => ({
        document: collectionByHandleQuery,
        variables: {
          handle,
        },
      }),
      transformResponse: catchAndNotify({
        fn: (data: Shopify.CollectionByHandleResponse) =>
          mapCollectionWithLandingPageFromResponse(data),
        errorMessage: 'Failed to load collection',
      }),
    }),
    //  Query: Get All Personas
    personas: builder.query<Persona[], void>({
      query: () => ({
        document: personasQuery,
      }),
      transformResponse: catchAndNotify({
        fn: (data: { collections: Shopify.CollectionsWithProductsResponse['collections'] }) =>
          mapPersonasFromResponse(data.collections),
        errorMessage: '"Personas" didn\'t load properly.',
        defaultValue: [],
      }),
    }),
    products: builder.query<Product[], { collectionId: string; price: Price; first?: number }>({
      query: ({ collectionId, price: { min, max }, first }) => ({
        document: productsQuery,
        variables: {
          collectionId,
          min,
          max,
          first,
        },
      }),
      transformResponse: catchAndNotify({
        fn: (data: Shopify.ProductsResponse) => mapProductsWithMetaFromResponse(data),
        errorMessage: '"Products" didn\'t load properly.',
        defaultValue: [],
      }),
    }),
    productsByTags: builder.query<
      Product[],
      { tags: string[]; price: Price; age: Age | null; gender: Gender | null; total: number }
    >({
      query: ({ tags, price: { min, max }, age, gender, total }) => ({
        document: createDynamicProductsByTagsQuery(tags, min, max, age, gender, total),
      }),
      transformResponse: catchAndNotify({
        fn: (data: Shopify.ProductsByTagsResponse) => mapProductsWithMetaByTagsFromResponse(data),
        errorMessage: '"Products" didn\'t load properly.',
        defaultValue: [],
        shouldThrow: false,
      }),
      transformErrorResponse: transformErrorResponse({ shouldThrow: false }),
    }),
    productsByIds: builder.query<
      Product[],
      {
        productIds: string[];
      }
    >({
      keepUnusedDataFor: 60 * 30, // 30 mins
      query: ({ productIds }) => ({
        document: createDynamicProductsByIdsQuery(productIds),
      }),
      transformResponse: catchAndNotify({
        fn: (data) => mapProductsByIdsWithMetaFromResponse(data),
        errorMessage: '"Products" didn\'t load properly.',
        defaultValue: [],
        shouldThrow: false,
      }),
      transformErrorResponse: transformErrorResponse({ shouldThrow: false }),
    }),
    productsByCollectionHandle: builder.query<
      Product[],
      { handle: string; price: Price; first?: number }
    >({
      query: ({ handle, price: { min, max }, first }) => ({
        document: productsByCollectionHandleQuery,
        variables: {
          handle,
          min,
          max,
          first,
        },
      }),
      transformResponse: catchAndNotify({
        fn: (data: Shopify.ProductsResponse) => mapProductsWithMetaFromResponse(data),
        errorMessage: '"Products by collection handle" didn\'t load properly.',
        defaultValue: [],
      }),
    }),
    productThumbnails: builder.query<
      ProductResponse[],
      { collectionIds: string[]; price: Price; first?: number }
    >({
      query: ({ collectionIds, price: { min, max }, first }) => ({
        document: productThumbnailsQuery,
        variables: {
          collectionIds,
          min,
          max,
          first,
        },
      }),
      transformResponse: catchAndNotify({
        fn: (data: CollectionIdWithProducts, meta, arg) => {
          const { price } = arg;
          const { max } = price;

          return mapProductsThumbnailsWithMetaFromResponse(data.nodes, max);
        },
        errorMessage: '"Products" didn\'t load properly.',
        defaultValue: [],
      }),
    }),
    productVariants: builder.query<Shopify.ProductVariant[], { id: string }>({
      query: ({ id }) => ({
        document: productVariantsQuery,
        variables: {
          id,
        },
      }),
      transformResponse: catchAndNotify({
        fn: (data: Shopify.ProductVariantsResponse) => mapProductVariantsFromResponse(data),
        errorMessage: '"Product variants" didn\'t load properly.',
        defaultValue: [],
      }),
    }),
    checkoutCreate: builder.mutation<
      Shopify.CheckoutCreateUpdateResponse,
      {
        email: string;
        lineItems: Shopify.MappedLineItem[];
      }
    >({
      query: ({ email, lineItems }) => ({
        document: checkoutCreateMutation,
        variables: {
          input: {
            email,
            lineItems,
          },
        },
      }),
      transformResponse: catchAndLog({
        fn: (data: Shopify.CheckoutCreateResponse) =>
          mapGenericCheckoutResponse(data.checkoutCreate),
        shouldThrow: true,
      }),
    }),
    checkoutDiscountCodeApply: builder.mutation<
      Shopify.CheckoutCreateUpdateResponse,
      {
        checkoutId: string;
        discountCode: string;
      }
    >({
      query: ({ checkoutId, discountCode }) => ({
        document: checkoutDiscountCodeApplyMutation,
        variables: {
          checkoutId,
          discountCode,
        },
      }),
      transformResponse: catchAndLog({
        fn: (data: Shopify.CheckoutDiscountCodeApplyResponse) =>
          mapGenericCheckoutResponse(data.checkoutDiscountCodeApplyV2),
        shouldThrow: true,
      }),
    }),
    checkoutDiscountCodeRemove: builder.mutation<
      Shopify.CheckoutCreateUpdateResponse,
      { checkoutId: string }
    >({
      query: ({ checkoutId }) => ({
        document: checkoutDiscountCodeRemoveMutation,
        variables: {
          checkoutId,
        },
      }),
      transformResponse: catchAndLog({
        fn: (data: Shopify.CheckoutDiscountCodeRemoveResponse) =>
          mapGenericCheckoutResponse(data.checkoutDiscountCodeRemove),
        shouldThrow: true,
      }),
    }),
    checkoutEmailUpdate: builder.mutation<
      Shopify.CheckoutCreateUpdateResponse,
      {
        checkoutId: string;
        email: string;
      }
    >({
      query: ({ checkoutId, email }) => ({
        document: checkoutEmailUpdate,
        variables: {
          checkoutId,
          email,
        },
      }),
      transformResponse: catchAndLog({
        fn: (data: Shopify.CheckoutEmailUpdateResponse) =>
          mapGenericCheckoutResponse(data.checkoutEmailUpdateV2),
        shouldThrow: true,
      }),
    }),
    checkoutLineItemsReplace: builder.mutation<
      Shopify.CheckoutCreateUpdateResponse,
      {
        checkoutId: string;
        lineItems: Shopify.MappedLineItem[];
      }
    >({
      query: ({ checkoutId, lineItems }) => ({
        document: checkoutLineItemsReplace,
        variables: {
          checkoutId,
          lineItems,
        },
      }),
      transformResponse: catchAndLog({
        fn: (data: Shopify.CheckoutLineItemsReplaceResponse) => {
          const {
            checkoutLineItemsReplace: { checkout, userErrors: checkoutUserErrors },
          } = data;

          return mapGenericCheckoutResponse({
            checkout,
            checkoutUserErrors,
          });
        },
        shouldThrow: true,
      }),
    }),
  }),
  extractRehydrationInfo(action, { reducerPath }) {
    if (
      action.type === HYDRATE &&
      // TS error: action.payload is unknown
      action.payload &&
      typeof action.payload === 'object' &&
      reducerPath in action.payload
    ) {
      return action.payload[reducerPath] as never;
    }
    return undefined;
  },
});

export const {
  useCollectionsQuery,
  useCollectionByHandleQuery,
  usePersonasQuery,
  useProductThumbnailsQuery,
  usePrefetch,
  useLazyProductsByTagsQuery,
  useProductsByTagsQuery,
  useLazyProductsByIdsQuery,
  useProductsByIdsQuery,
  useProductsByCollectionHandleQuery,
  useLazyProductVariantsQuery,
  useCategorizedCollectionsQuery,
  useCheckoutCreateMutation,
  useCheckoutDiscountCodeApplyMutation,
  useCheckoutDiscountCodeRemoveMutation,
  useCheckoutEmailUpdateMutation,
  useCheckoutLineItemsReplaceMutation,
} = shopifyApi;
