export const AUTH_CODE_RESEND_COOLDOWN_MS = 30000;

export const USER_INPUT_DEBOUNCE_MS = 200;

export const PRODUCT_TAGS_DELIMETER = '|';

export const giftBudgetProductId = process.env.NEXT_PUBLIC_SHOPIFY_GIFT_BUDGET_PRODUCT_ID ?? '';

export const FIRST_NAME_LIMIT = 33; // limited by gift api

export const KEEP_UNUSED_RTK_QUERY_DATA_FOR = 60 * 60; // 1 hour

export const TIME_RANGES = [
  { label: '12:00 AM', value: '00:00:00' },
  { label: '12:30 AM', value: '00:30:00' },
  { label: '1:00 AM', value: '01:00:00' },
  { label: '1:30 AM', value: '01:30:00' },
  { label: '2:00 AM', value: '02:00:00' },
  { label: '2:30 AM', value: '02:30:00' },
  { label: '3:00 AM', value: '03:00:00' },
  { label: '3:30 AM', value: '03:30:00' },
  { label: '4:00 AM', value: '04:00:00' },
  { label: '4:30 AM', value: '04:30:00' },
  { label: '5:00 AM', value: '05:00:00' },
  { label: '5:30 AM', value: '05:30:00' },
  { label: '6:00 AM', value: '06:00:00' },
  { label: '6:30 AM', value: '06:30:00' },
  { label: '7:00 AM', value: '07:00:00' },
  { label: '7:30 AM', value: '07:30:00' },
  { label: '8:00 AM', value: '08:00:00' },
  { label: '8:30 AM', value: '08:30:00' },
  { label: '9:00 AM', value: '09:00:00' },
  { label: '9:30 AM', value: '09:30:00' },
  { label: '10:00 AM', value: '10:00:00' },
  { label: '10:30 AM', value: '10:30:00' },
  { label: '11:00 AM', value: '11:00:00' },
  { label: '11:30 AM', value: '11:30:00' },
  { label: '12:00 PM', value: '12:00:00' },
  { label: '12:30 PM', value: '12:30:00' },
  { label: '1:00 PM', value: '13:00:00' },
  { label: '1:30 PM', value: '13:30:00' },
  { label: '2:00 PM', value: '14:00:00' },
  { label: '2:30 PM', value: '14:30:00' },
  { label: '3:00 PM', value: '15:00:00' },
  { label: '3:30 PM', value: '15:30:00' },
  { label: '4:00 PM', value: '16:00:00' },
  { label: '4:30 PM', value: '16:30:00' },
  { label: '5:00 PM', value: '17:00:00' },
  { label: '5:30 PM', value: '17:30:00' },
  { label: '6:00 PM', value: '18:00:00' },
  { label: '6:30 PM', value: '18:30:00' },
  { label: '7:00 PM', value: '19:00:00' },
  { label: '7:30 PM', value: '19:30:00' },
  { label: '8:00 PM', value: '20:00:00' },
  { label: '8:30 PM', value: '20:30:00' },
  { label: '9:00 PM', value: '21:00:00' },
  { label: '9:30 PM', value: '21:30:00' },
  { label: '10:00 PM', value: '22:00:00' },
  { label: '10:30 PM', value: '22:30:00' },
  { label: '11:00 PM', value: '23:00:00' },
  { label: '11:30 PM', value: '23:30:00' },
];
