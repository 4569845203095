export const camelCase = <T extends string>(str: T): T =>
  str.replace(/([-_][a-z])/gi, ($1) => $1.toUpperCase().replace('-', '').replace('_', '')) as T;

export const parseJSON = <T>(str: string): T | undefined => {
  try {
    return JSON.parse(str);
  } catch (error) {
    return undefined;
  }
};

export const formatMoneyValue = (value: number) => value.toFixed(2);
