export enum MyGiftsTabLabels {
  SENT = 'Sent',
  RECEIVED = 'Received',
  SCHEDULED = 'Scheduled',
}

export const tabLabelToPromoEventName = {
  [MyGiftsTabLabels.RECEIVED]: 'consumer_my_activity_received_balloon_promotion_clicked',
  [MyGiftsTabLabels.SENT]: 'consumer_my_activity_sent_balloon_promotion_clicked',
  [MyGiftsTabLabels.SCHEDULED]: 'consumer_my_activity_scheduled_balloon_promotion_clicked',
} as const;

export const tabLabelToOccasionExpandEventName = {
  [MyGiftsTabLabels.RECEIVED]: 'consumer_my_activity_received_occasion_expanded',
  [MyGiftsTabLabels.SENT]: 'consumer_my_activity_sent_occasion_expanded',
  [MyGiftsTabLabels.SCHEDULED]: 'consumer_my_activity_scheduled_occasion_expanded',
} as const;

export const tabLabelToOccasionClickEventName = {
  [MyGiftsTabLabels.RECEIVED]: 'consumer_my_activity_received_occasion_clicked',
  [MyGiftsTabLabels.SENT]: 'consumer_my_activity_sent_occasion_clicked',
  [MyGiftsTabLabels.SCHEDULED]: 'consumer_my_activity_scheduled_occasion_clicked',
} as const;

export const tabLabelToSeeAllCollectionsClickEventName = {
  [MyGiftsTabLabels.RECEIVED]: 'consumer_my_activity_received_see_all_collections_clicked',
  [MyGiftsTabLabels.SENT]: 'consumer_my_activity_sent_see_all_collections_clicked',
  [MyGiftsTabLabels.SCHEDULED]: 'consumer_my_activity_scheduled_see_all_collections_clicked',
} as const;
