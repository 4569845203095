/* eslint-disable @typescript-eslint/no-duplicate-enum-values */

export const SNAPPY_CONSUMER_SENDING_COMPANY = 'Snappy Personal';

export enum URLS {
  B2B = 'https://www.snappy.com/',
  KNOWLEDGE_BASE = 'https://support.snappygifts.com/',
  B2BLogin = 'https://login.snappy.com/login',
}

export enum EMAILS {
  SUPPORT = 'support@snappygifts.com',
}

export enum ERROR_MESSAGES {
  VALIDATION_DISCOUNT_CODE_MESSAGE = 'Enter a valid discount code.',
  VALIDATION_EMAIL_MESSAGE = 'Uh oh! That doesn’t look like a valid email',
  VALIDATION_PHONE_MESSAGE = 'Uh oh! That doesn’t look like a valid phone number.',
  VALIDATION_NAME_MESSAGE = 'Uh oh! That doesn’t look like a valid name.',
  VALIDATION_TEXT_MESSAGE = 'Uh oh! That doesn’t look like a valid text.',
  VALIDATION_REQUIRED_MESSAGE = 'This field is required',
  VALIDATION_BLOCKED_DOMAINS_MESSAGE = 'Blocked email domain',

  // HTTP status messages
  HTTP_BAD_REQUEST_MESSAGE = 'Check that the URL you entered is correct and try again.',
  HTTP_UNAUTHORIZED_MESSAGE = 'Make sure you are logged in and try again.',
  HTTP_FORBIDDEN_MESSAGE = "Forbidden: You don't have access rights.",
  HTTP_NOT_FOUND_MESSAGE = 'The resource you are looking for could not be found.',
  HTTP_METHOD_NOT_ALLOWED_MESSAGE = 'Check that the info you are submitting is correct and try again.',
  HTTP_NOT_ACCEPTABLE_MESSAGE = 'Check that the info you are submitting is correct and try again.',
  HTTP_CONFLICT_MESSAGE = 'Check that the info you are submitting is correct and try again.',
  HTTP_GONE_MESSAGE = 'The page you are looking for could not be found.',
  HTTP_TOO_MANY_REQUESTS_MESSAGE = "The page isn't working. Try waiting a few minutes before trying again.",
  HTTP_INTERNAL_SERVER_ERROR_MESSAGE = 'Something went wrong on our side. (500) Please try again later.',
  HTTP_BAD_GATEWAY_MESSAGE = 'Something went wrong on our side. (502) Please try again later.',
  HTTP_SERVICE_UNAVAILABLE_MESSAGE = 'Something went wrong on our side. (503) Please try again later.',
  HTTP_GATEWAY_TIMEOUT_MESSAGE = 'Something went wrong on our side. (504) Please try again later.',
}

export enum DISCOUNT_CODE_ERROR_MESSAGES {
  CART_DOES_NOT_MEET_DISCOUNT_REQUIREMENTS_NOTICE = 'This discount code is not valid for this Collection',
  CUSTOMER_ALREADY_USED_ONCE_PER_CUSTOMER_DISCOUNT_NOTICE = 'You can only use this discount code once',
  DISCOUNT_ALREADY_APPLIED = 'You can only use this discount code once',
  DISCOUNT_DISABLED = 'This discount code is disabled',
  DISCOUNT_EXPIRED = 'This discount code has expired',
  DISCOUNT_LIMIT_REACHED = 'This discount code has reached its usage limit',
  DISCOUNT_NOT_FOUND = 'This coupon code cannot be used.',
  DISCOUNT_RESET_FAILURE = 'The discount code could not be removed.',
}

export const BLOCKED_EMAIL_DOMAINS = ['amazon', 'salesforce', 'uber', 'microsoft', 'walmart'];
