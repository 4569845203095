import React, { useState } from 'react';
import { selectActiveBudgetRange } from '@/redux';
import { useAppSelector } from '@/hooks/useStore';
import { ListItem } from '@/components/ui/NavBar/ListItem';
import { Collapse } from '@/components/ui/Collapse';
import { BaseLinkProps } from '@/components/ui/BaseLink';
import { GroupedCollection } from '@/types/user';

interface CollectionGroupProps {
  group: GroupedCollection[];
  onCollectionClick(collection: GroupedCollection): void;
}

export const CollectionGroup: React.FC<CollectionGroupProps> = ({ group, onCollectionClick }) => {
  const { max: budget } = useAppSelector(selectActiveBudgetRange);

  const [selectedCollection, setSelectedCollection] = useState('');

  const isSelected = (title?: string) => selectedCollection === title;

  const getHref = (collection: GroupedCollection): BaseLinkProps['href'] | undefined => {
    if (collection.href) {
      return collection.href;
    }
    if (collection.handle) {
      return {
        pathname: `/collections/${collection.handle}`,
        query: { budget },
      };
    }
    return undefined;
  };

  const onClick = (collection: GroupedCollection) => {
    setSelectedCollection((title) => (title === collection.title ? '' : collection.title));
    onCollectionClick(collection);
  };

  return (
    <ul className="collection-group__list">
      {group.map((collection) => (
        <li key={collection.title} className="collection-group__list-item">
          <ListItem
            strictActiveCheck
            title={collection.title}
            href={getHref(collection)}
            rightIcon={collection.collections ? 'ChevronIcon' : undefined}
            rightIconDir={isSelected(collection.title) ? 'down' : 'right'}
            onClick={() => onClick(collection)}
          />

          {collection.collections && (
            <Collapse in={isSelected(collection.title)} unmountOnExit>
              <CollectionGroup
                group={collection.collections}
                onCollectionClick={onCollectionClick}
              />
            </Collapse>
          )}
        </li>
      ))}

      {/*  Styles  */}

      <style jsx>{`
        .collection-group {
          &__list {
            @apply flex flex-col gap-1;

            .collection-group__list {
              @apply pl-4;
            }
          }

          &__list-item {
            @apply flex-none;
          }
        }
      `}</style>
    </ul>
  );
};
