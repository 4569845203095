import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';
import times from 'lodash/times';
import { mergeRefs } from '@/utils/misc';
import { useElementSize } from '@/hooks/useElementSize';

const INTENSITY_FACTOR = 100;
const MS_PER_FRAME = 1000 / 60; // 60fps

interface ParallaxStarsProps {
  intensity?: number;
  sizes?: number[];
  colors?: string[];
  speed?: number;
  direction?: 'up' | 'down';
  isFadeOut?: boolean;
  isWaveMotion?: boolean;
  isSnowflake?: boolean;
  glowBlur?: number;
  className?: string;
}

export const ParallaxStars = memo<ParallaxStarsProps>(
  ({
    intensity = 1,
    sizes = [1, 2, 3],
    colors = ['#000000'],
    speed = 1,
    direction = 'up',
    className,
    isFadeOut = false,
    isWaveMotion = false,
    isSnowflake = false,
    glowBlur = 0,
  }) => {
    const lastTimestamp = useRef(0);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const { ref, size } = useElementSize<HTMLCanvasElement>();

    // Make deps primitive to avoid re-initialization of the stars when the props DON'T change.
    const sizesString = sizes.join('');
    const colorsString = colors.join('');

    // Get properties helpers
    const getStarIndex = useCallback(() => Math.floor(Math.random() * sizes.length), [sizesString]);
    const getStarSpeed = useCallback(
      (index: number) => speed / (Math.max(...sizes) / sizes[index]),
      [sizesString, speed]
    );
    const getStarY = useCallback(() => {
      const yShift = direction === 'up' ? 1 : -1;
      return yShift + Math.random();
    }, [direction]);

    // Create stars
    const stars = useMemo(
      () =>
        times(INTENSITY_FACTOR * intensity, () => {
          const starIndex = getStarIndex();

          return {
            seed: Math.random(),
            x: Math.random(),
            y: getStarY(),
            size: sizes[starIndex],
            color: colors[starIndex] || colors[0],
            speed: getStarSpeed(starIndex),
          };
        }),
      [sizesString, getStarIndex, getStarSpeed, getStarY, colorsString, intensity]
    );

    useEffect(() => {
      const context = canvasRef.current?.getContext('2d');
      if (!context || !size.w || !size.h) return;

      const goUp = direction === 'up';
      const goDown = direction === 'down';

      context.canvas.width = size.w;
      context.canvas.height = size.h;

      let animationFrameId: number;

      const draw: Parameters<typeof requestAnimationFrame>[0] = async (timeStamp) => {
        // Calculate the time difference from the last frame to make the constant speed.
        const elapsed = timeStamp - (lastTimestamp.current || timeStamp);
        lastTimestamp.current = timeStamp;

        // Clear the canvas
        context.clearRect(0, 0, size.w, size.h);

        // eslint-disable-next-line no-restricted-syntax
        for (const star of stars) {
          // change opacity based on star y position when "isFadeOut" is enabled.
          const fadeFactor = goUp ? Math.min(1, star.y * 2) : Math.min(1, (1 - star.y) * 2);

          // Get the RGB color from the hex color to support opacity.
          const rgb = isFadeOut && hexToRgb(star.color);
          const rgbColor = rgb ? `rgba(${rgb.r},${rgb.g},${rgb.b},${fadeFactor})` : star.color;

          // Get coords and properties
          const x = star.x * size.w;
          const y = star.y * size.h;
          const starSize = isFadeOut ? Math.max(0, star.size * fadeFactor) : star.size;

          // Calculate the same speed for different device performance base on fps.
          const starSpeed = ((goUp ? -1 : 1) * star.speed) / size.h;
          const normalizedSpeed = starSpeed * (elapsed / MS_PER_FRAME || 1);
          const color = isFadeOut ? rgbColor : star.color;

          // draw a shape
          if (isSnowflake) {
            drawSnowflake(context, x, y, starSize, color, star.seed, glowBlur);
          } else {
            drawStar(context, x, y, starSize, color, glowBlur);
          }

          // move the shape
          star.y += normalizedSpeed;

          if (isWaveMotion) {
            star.x += getWaveMotion(lastTimestamp.current, star.seed, star.size, size.w);
          }

          // reset the star position when it goes out of the screen
          const isStarAboveScreen = goUp && star.y < -star.size / size.h;
          const isStarBelowScreen = goDown && star.y > 1 + star.size / size.h;

          if (isStarAboveScreen || isStarBelowScreen) {
            const newStarIndex = getStarIndex();

            star.y = getStarY();
            star.x = Math.random();
            star.size = sizes[newStarIndex];
            star.speed = getStarSpeed(newStarIndex);
          }
        }
      };

      const animate: Parameters<typeof requestAnimationFrame>[0] = (timeStamp) => {
        draw(timeStamp);
        animationFrameId = requestAnimationFrame(animate);
      };

      requestAnimationFrame(animate);

      // eslint-disable-next-line consistent-return
      return () => cancelAnimationFrame(animationFrameId);
    }, [
      size,
      stars,
      direction,
      isFadeOut,
      isWaveMotion,
      isSnowflake,
      glowBlur,
      intensity,
      getStarIndex,
      getStarSpeed,
    ]);

    return (
      <canvas
        ref={mergeRefs(ref, canvasRef)}
        className={clsx('absolute inset-0 w-full h-full pointer-events-none', className)}
      />
    );
  }
);

// Helpers

export function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

function drawSnowflake(
  context: CanvasRenderingContext2D,
  x: number,
  y: number,
  size: number,
  color: string,
  seed: number,
  glowBlur = 0
) {
  context.beginPath();

  context.strokeStyle = color;
  context.lineWidth = 1; // Line thickness
  if (glowBlur) {
    context.shadowBlur = glowBlur;
    context.shadowColor = color;
  }

  // Number of arms
  const arms = Math.floor(seed * 3) + 6;

  for (let i = 0; i < arms; i += 1) {
    // Angle for each arm
    const angle = (Math.PI / arms) * 2 * i;

    // Starting and ending points for each arm
    context.moveTo(x, y);
    context.lineTo(x + Math.cos(angle) * size, y + Math.sin(angle) * size);
  }

  context.stroke(); // Apply the lines to the canvas
}

function drawStar(
  context: CanvasRenderingContext2D,
  x: number,
  y: number,
  size: number,
  color: string,
  glowBlur = 0
) {
  context.beginPath();
  context.fillStyle = color;
  if (glowBlur) {
    context.shadowBlur = glowBlur;
    context.shadowColor = color;
  }
  context.arc(x, y, size, 0, Math.PI * 2);
  context.fill();
}

function smoothNoise(time: number, scale: number) {
  return Math.sin(time / scale);
}

function getWaveMotion(
  normalizedTime: number,
  shapeSeed: number,
  shapeSize: number,
  containerWidth: number
) {
  // Constants for scaling factors
  const sizeFactor = 10000;
  const seedFactor = 1000;
  const scaleDivisor = 5000;
  const randomScale = 100;

  // Calculate frequency and random shift
  const frequencyFactor = sizeFactor / shapeSize;
  const noiseScale = randomScale + shapeSeed * seedFactor;
  const randomShift = smoothNoise(normalizedTime, noiseScale) / containerWidth;

  // Breaking down waveMotion calculation
  const cosValue = Math.cos(normalizedTime / (frequencyFactor * shapeSeed));
  const waveAmplitude = scaleDivisor + (shapeSeed * seedFactor) / containerWidth;
  const waveMotion = cosValue / waveAmplitude;

  return waveMotion + randomShift;
}
