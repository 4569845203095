import React from 'react';
import { Colors } from '@/constants/Colors';
import { User } from '@/types/user';
import { Icon } from '@/components/ui/Icons/Icon';
import { MobileMenuItem } from './MobileMenuItem';

interface UserBarMobileMenuProps {
  onSignOutClick: () => void;
  userInfo: User;
}

export const UserBarMobileMenu: React.FC<UserBarMobileMenuProps> = ({
  userInfo,
  onSignOutClick,
}) => (
  <div className="user-bar-mobile-menu">
    <div className="user-bar-mobile-menu__user-info">
      <h3 className="user-bar-mobile-menu__user-info-title">
        {userInfo.firstname} {userInfo.lastname}
      </h3>
      <span className="user-bar-mobile-menu__user-info-email">{userInfo.email}</span>
    </div>
    <div className="user-bar-mobile-menu__content">
      <MobileMenuItem onClick={onSignOutClick} hasBorder={false}>
        <div className="user-bar-mobile-menu__logout-button">
          <span className="user-bar-mobile-menu__logout-button-text">Log Out</span>
          <span className="user-bar-mobile-menu__logout-button-icon">
            <Icon name="SignOutIcon" size={24} color={Colors.system.error} />
          </span>
        </div>
      </MobileMenuItem>
    </div>
    {/* Styles */}

    <style jsx>
      {`
        .user-bar-mobile-menu {
          @apply flex flex-col;

          &__user-info {
            @apply p-8 bg-purple-100 min-h-[130px] text-center;

            &-title {
              @apply text-2xl text-purple-600 leading-normal font-medium;
            }
          }

          &__content {
            @apply text-left px-6;
          }

          &__logout-button {
            @apply flex w-full items-center border-none;

            &-text {
              @apply flex-1 text-left;
              @apply text-xl leading-normal font-dm-sans;
              @apply text-system-error font-medium;
            }
          }
        }
      `}
    </style>
  </div>
);
