import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Flipped, Flipper } from 'react-flip-toolkit';
import { CSSTransition } from 'react-transition-group';
import clsx from 'clsx';
import { FancyBackgroundGlow } from '@/components/ui/FancyBackgroundGlow';
import { usePrevious } from '@/hooks/usePrevious';
import { BaseSwitchTransition } from '@/components/ui/BaseSwitchTransition';
import { useOnClickOutside } from '@/hooks/useOnClickOutside';
import { useCollectionGeneration } from '../../hooks/useCollectionGeneration';
import { AIForm, AIGenerateCollectionFormRef, AIGenerateCollectionFormValues } from '../AIForm';
import { AIFormTitleLabel } from '../AIFormTitleLabel';
import { AIFormErrorState } from '../AIFormErrorState';
import { AIFormLoadingState } from '../AIFormLoadingState';

export const AI_GENERATE_COLLECTION_FORM_TEST_ID = 'ai-generate-collection-form';

interface AIGenerateCollectionFormProps {
  shadowType?: 'none' | 'gray' | 'fancy';
  onTryAgainClick?(values: AIGenerateCollectionFormValues, budget: number): void;
  onSubmitClick?(values: AIGenerateCollectionFormValues, budget: number): void;
  onSubmitSuccess?(): void;
}

export const AIGenerateCollectionForm = memo<AIGenerateCollectionFormProps>(
  ({ shadowType = 'none', onTryAgainClick, onSubmitClick, onSubmitSuccess }) => {
    const { isFetching, isFetchingTags, error, submittedValues, budget, resetError, onSubmit } =
      useCollectionGeneration({ onSubmitSuccess });

    const aiFormRef = useRef<AIGenerateCollectionFormRef>(null);
    const mainContainerRef = useRef<HTMLDivElement>(null);
    const formContainerRef = useRef<HTMLDivElement>(null);
    const errorContainerRef = useRef<HTMLDivElement>(null);
    const loadingContainerRef = useRef<HTMLDivElement>(null);

    // We need to keep track of the previous error to prevent flickering between transitions
    const prevError = usePrevious(error);
    const errorKey = error ?? prevError ?? null;

    const [isMounted, setIsMounted] = useState(false);
    const [isFormFocused, setIsFormFocused] = useState(false);

    const handleSubmit = useCallback(
      (values: AIGenerateCollectionFormValues) => {
        onSubmit(values);
        onSubmitClick?.(values, budget);
      },
      [onSubmit, onSubmitClick, budget]
    );

    const handleResetError = useCallback(() => {
      resetError();
      if (submittedValues) onTryAgainClick?.(submittedValues, budget);
      setTimeout(() => aiFormRef.current?.focusInput()); // select input after animation is done
    }, [submittedValues, budget, onTryAgainClick, resetError]);

    useOnClickOutside(mainContainerRef, () => {
      if (isFetching || error) return;
      setIsFormFocused(false);
    });

    useEffect(() => {
      setIsMounted(true);
    }, []);

    return (
      <section
        ref={mainContainerRef}
        className="ai-generate-collection-form"
        data-testid={AI_GENERATE_COLLECTION_FORM_TEST_ID}
      >
        <div className={clsx('ai-generate-collection-form__title-label', { active: !isFetching })}>
          <AIFormTitleLabel />
        </div>

        <Flipper
          flipKey={isFetching}
          spring="stiff"
          className="flex-auto flex justify-center w-full"
        >
          {isFetching ? (
            <Flipped flipId="content">
              <section className="ai-generate-collection-form__loading-container">
                {shadowType === 'fancy' && <FancyBackgroundGlow />}

                <div
                  className={clsx(
                    'ai-generate-collection-form__inner',
                    `shadow-type-${shadowType}`,
                    { 'is-expanded': isFormFocused }
                  )}
                >
                  <Flipped inverseFlipId="content">
                    <div className="ai-generate-collection-form__loading-state">
                      <CSSTransition
                        appear={isMounted}
                        in={!error}
                        timeout={500}
                        style={{ '--duration': '0.5s', '--max-scale': 1.025 }}
                        nodeRef={loadingContainerRef}
                        unmountOnExit
                        classNames="zoom-out"
                      >
                        <div
                          ref={loadingContainerRef}
                          className="ai-generate-collection-form__loading-state"
                        >
                          <AIFormLoadingState loading={isFetchingTags} />
                        </div>
                      </CSSTransition>
                    </div>
                  </Flipped>
                </div>
              </section>
            </Flipped>
          ) : (
            <Flipped flipId="content">
              <section className="ai-generate-collection-form__container">
                {shadowType === 'fancy' && <FancyBackgroundGlow />}

                <div
                  className={clsx(
                    'ai-generate-collection-form__inner',
                    `shadow-type-${shadowType}`,
                    { 'is-expanded': isFormFocused }
                  )}
                >
                  <Flipped inverseFlipId="content">
                    <div className="relative flex w-full">
                      <BaseSwitchTransition activeIndex={error ? 1 : 0} className="max-w-full">
                        {[
                          <CSSTransition
                            key={0}
                            appear={isMounted}
                            in={!error}
                            timeout={500}
                            style={{ '--duration': '0.5s', '--max-scale': 1.025 }}
                            nodeRef={formContainerRef}
                            unmountOnExit
                            classNames="zoom-out"
                          >
                            <div
                              ref={formContainerRef}
                              className="ai-generate-collection-form__left-form"
                            >
                              <AIForm
                                ref={aiFormRef}
                                isLoading={isFetching}
                                value={submittedValues}
                                isExpanded={isFormFocused}
                                onFocus={() => setIsFormFocused(true)}
                                onSubmit={handleSubmit}
                              />
                            </div>
                          </CSSTransition>,

                          <CSSTransition
                            key={1}
                            appear
                            in={!!error}
                            timeout={500}
                            style={{ '--duration': '0.5s', '--max-scale': 0.975 }}
                            nodeRef={errorContainerRef}
                            unmountOnExit
                            classNames="zoom-in"
                          >
                            <div
                              ref={errorContainerRef}
                              className="ai-generate-collection-form__error-title"
                            >
                              <AIFormErrorState errorKey={errorKey} onTryAgain={handleResetError} />
                            </div>
                          </CSSTransition>,
                        ]}
                      </BaseSwitchTransition>
                    </div>
                  </Flipped>
                </div>
              </section>
            </Flipped>
          )}
        </Flipper>

        {/* Styles */}

        <style jsx>{`
          .ai-generate-collection-form {
            @apply flex-auto flex flex-col items-center w-full;

            &__title-label {
              @apply flex justify-start w-full pb-2 transition-all duration-300 ease-out-spring;
              @apply md:translate-y-full md:opacity-0;

              &.active {
                @apply translate-y-0 opacity-100 delay-200;
              }
            }

            &__loading-container {
              @apply relative flex-none flex w-[780px] max-w-full;
            }

            &__container {
              @apply relative flex-none flex w-full;
            }

            &__loading-state {
              @apply flex items-center justify-center w-full;
            }

            &__error-title {
              @apply flex-auto flex items-center p-4 md:pl-10;
            }

            &__loading-state,
            &__error-title {
              @apply h-[200px] md:h-[210px];
            }

            &__inner {
              @apply relative flex-auto w-full flex gap-x-8;
              @apply rounded-[50px] bg-white border border-solid border-primary-300;
              @apply transition-all duration-300;

              &.shadow-type-gray {
                @apply shadow-[0_30px_40px_0_rgba(1,39,84,0.07)];
              }

              &.is-expanded {
                @apply border-primary-600 rounded-2xl;
              }
            }
          }
        `}</style>
      </section>
    );
  }
);
