import { Colors } from '@/constants/Colors';
import { IconDirection, IconVariation } from './types';

export function getStylesForDirection(direction?: IconDirection): { [key: string]: string } {
  const stylesForDirections = {
    up: {
      transform: 'rotate(90deg)',
    },
    right: {
      transform: 'rotate(180deg)',
    },
    down: {
      transform: 'rotate(270deg)',
    },
    left: {},
  };
  return direction ? stylesForDirections[direction] : {};
}

interface IconVariationColorOverrides {
  darkColor?: string;
  defaultColor?: string;
  lightColor?: string;
  primaryColor?: string;
}

export function getFillColorForVariation(
  variation: IconVariation,
  config: IconVariationColorOverrides = {}
): string {
  const { darkColor, defaultColor, lightColor, primaryColor } = config;
  const colorsForVariations = {
    dark: darkColor ?? Colors.textDark,
    default: defaultColor ?? Colors.primary[700],
    light: lightColor ?? Colors.white,
    primary: primaryColor ?? Colors.bgPrimary,
  };
  return colorsForVariations[variation];
}
