import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { getKeyByValue } from '@/utils/misc';
import socialSnappyImg from 'public/assets/images/social-snappy.webp';

const APP_BASE_URL = `${process.env.NEXT_PUBLIC_APP_BASE_URL}`;

type RouteNames =
  | '/sign-in'
  | '/sign-up'
  | '/home'
  | '/checkout-step-1'
  | '/checkout'
  | '/checkout-success'
  | '/my-gifts'
  | '/cookies'
  | '/data-processing'
  | '/privacy-notice-california'
  | '/privacy-policy'
  | '/terms-general'
  | '/terms-sender';

type PageMetaType = {
  [key in RouteNames]?: {
    title: string;
    description: string;
  };
} & {
  default: {
    title: string;
    description: string;
  };
};

// Config

const pageMeta: PageMetaType = {
  default: {
    title: 'Send the Gift of Choice to Your Loved Ones | Snappy',
    description:
      'A personal gifting experience that connects you with your loved ones. Send a gift collection with a personal touch. Happiness and smiles await!',
  },
  '/home': {
    title: 'Send a Snappy and let them pick the perfect gift.',
    description:
      'Send unique, same-day gifts to friends and family. The best gift ideas for all the hard-to-shop-for people in your life. Free shipping. Better than gift cards!',
  },
  '/sign-in': {
    title: 'Sign In | Snappy',
    description: 'Sign in to your Snappy account.',
  },
  '/sign-up': {
    title: 'Sign Up | Snappy',
    description: 'Create a new Snappy account.',
  },
  '/checkout-step-1': {
    title: 'Send a Gift Collection | Snappy',
    description:
      'Personalize your greeting and add your recipient information to send your Snappy Gift in seconds.',
  },
  '/checkout': {
    title: 'Checkout | Snappy',
    description: 'Here it comes! Complete checkout to send your Snappy gift.',
  },
  '/checkout-success': {
    title: 'Your gift has been sent! | Snappy',
    description: 'Woohoo! Your gift has been sent successfully.',
  },
  '/my-gifts': {
    title: 'My Activity | Snappy',
    description: 'View your gift history and read your thank you notes.',
  },
  '/cookies': {
    title: 'Cookie Policy | Snappy',
    description: 'Learn about our cookie policy.',
  },
  '/privacy-notice-california': {
    title: 'California Privacy Notice | Snappy',
    description: 'Learn about our California privacy notice.',
  },
  '/privacy-policy': {
    title: 'Privacy Policy | Snappy',
    description: 'Learn about our privacy policy.',
  },
  '/terms-general': {
    title: 'General Terms | Snappy',
    description: 'Learn about our terms of service.',
  },
  '/terms-sender': {
    title: 'Snappy Terms of Service - Personal | Snappy',
    description: 'Learn about our terms of service for senders.',
  },
};

// Types

export interface PageMetaProps {
  title?: string;
  image?: string;
  description?: string;
  children?: React.ReactNode;
}

// Component

export const PageMeta: React.FC<PageMetaProps> = ({ children, title, description, image }) => {
  const router = useRouter();
  const routeName = router.route as RouteNames;
  const pageName = routeName?.replace(/^\//, '');
  const isGPTPreselectedProduct =
    Boolean(router.query.productId) && pageName === 'generated-collection';
  const gptPreselectedProductRenderGuard = !(!title && isGPTPreselectedProduct);

  const extraRoutes: Partial<{ [key in RouteNames]: boolean }> = {
    '/checkout-step-1': routeName === '/checkout' && router.query.step === '1',
  };

  const extraRoute = getKeyByValue(extraRoutes);
  const metaForRoute = [extraRoute ? pageMeta[extraRoute] : null, pageMeta[routeName]].filter(
    Boolean
  )[0];
  const screenMeta = metaForRoute ?? pageMeta.default;

  const canonicalHref = APP_BASE_URL + routeName.replace(/\/\[.+/, ''); // remove dynamic route params

  return (
    <Head>
      <title>{title || screenMeta.title}</title>
      <meta name="viewport" content="initial-scale=1.0, width=device-width" key={pageName} />
      <meta name="description" content={description || screenMeta.description} />

      {/* General */}
      <link rel="canonical" href={canonicalHref} />
      <meta
        name="keywords"
        content="Snappy, Gift, Choices, Ideas, Friends, Family, Shop, Best, Free, Unique"
      />

      {/* Open graph */}
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="en_US" />
      {!isGPTPreselectedProduct && <meta property="og:url" content={canonicalHref} />}
      {gptPreselectedProductRenderGuard && (
        <meta property="og:image" content={image || socialSnappyImg.src} />
      )}
      {gptPreselectedProductRenderGuard && (
        <meta
          property="og:title"
          content={title || 'Send a Snappy and let them pick the perfect gift.'}
        />
      )}
      <meta
        property="og:description"
        content="Send unique, same-day gifts to friends and family. The best gift ideas for all the hard-to-shop-for people in your life. Free shipping, Better than gift cards!"
      />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      {gptPreselectedProductRenderGuard && (
        <meta name="twitter:image" content={image || socialSnappyImg.src} />
      )}
      <meta
        name="twitter:title"
        content={title || 'Send a Snappy and let them pick the perfect gift.'}
      />
      <meta
        name="twitter:description"
        content="Send unique, same-day gifts to friends and family. The best gift ideas for all the hard-to-shop-for people in your life. Free shipping, Better than gift cards!"
      />

      {/* Facebook */}
      <meta name="facebook-domain-verification" content="dbiem2hxhsk9zaq6no0sbsn51wbu5x" />

      {children}
    </Head>
  );
};
